import http from './http';
import { ICircular, ICircularForm } from '../models/circular_model';

export const getCircularesService = async (filtro?: {
  codigo?: string;
  asunto?: string;
  contenido?: string;
}) => {
  try {
    let filters = [];
    if (filtro?.codigo) {
      filters.push(`codigo=${filtro.codigo}`);
    }
    if (filtro?.asunto) {
      filters.push(`asunto=${filtro.asunto}`);
    }
    if (filtro?.contenido) {
      filters.push(`contenido=${filtro.contenido}`);
    }
    const items = await http.get(
      `/circular_admin/${filters.length ? '?' + filters.join('&') : ''}`,
    );
    return items as { rows: ICircular[]; count: number };
  } catch (error) {
    console.error('[get_circulares]  Error -> ', error);
    return { rows: [], count: 0 };
  }
};

export const createCircularService = async (data: ICircularForm) => {
  try {
    return await http.post(`/circular/`, data);
  } catch (error) {
    console.error('Ocurrio un error al guardar circular :: ', error);
    return null;
  }
};

export const editCircularService = async (data: ICircularForm, id: string) => {
  try {
    return await http.put(`/circular/${id}`, data);
  } catch (error) {
    console.error('Ocurrio un error al editar circular :: ', error);
    return null;
  }
};

export const deleteCircularService = async (id: string) => {
  try {
    return await http.delete(`/circular/${id}`);
  } catch (error) {
    console.error('Error al eliminar circular :: ', error);
    return null;
  }
};

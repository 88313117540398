import { AUTH_TYPES, Action } from "../types";
// import { Materia } from "../../models/materia_model";

export type State = {
  usuario: any;
};

const initialState: State = {
  usuario: {},
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case AUTH_TYPES.LOGIN:
      return {
        ...state,
        usuario: payload,
      };
    case AUTH_TYPES.LOGOUT:
      return {
        ...state,
        usuario: {}
      }
    default:
      return state;
  }
};

export default reducer;

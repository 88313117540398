import { Dialog, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import { Translate } from "react-redux-i18n";
import FormSkills from "./components/FormSkills";

type FormCourseProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  handleSaveSkills: (data: any) => void;
}

const ModalSkills: React.FC<FormCourseProps> = ({
  isModalOpen,
  handleClose,
  handleSaveSkills
}) => {
  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Grid container justify="space-between">
          <Typography gutterBottom style={{  }}>
            <Translate value="Crear competencias" />
          </Typography>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <FormSkills 
        handleClose={handleClose} 
        handleSave={handleSaveSkills}
        />
    </Dialog>
  );

}

export default ModalSkills;
import { Box, Button, createStyles, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Translate } from "react-redux-i18n";
import ActivityRow from "./components/ActivityRow";
import MorningActivitiesIcon from '../../../../assets/img/icons/morning_activities.svg';
import OtherActivitiesIcon from '../../../../assets/img/icons/other_activities.svg';
import React from "react";
import { ActivitiesData } from "models/libro_de_curso_grid_activities_model";

let defaultValues = {
  actividades:[]
  // actividades: [
  //   {
  //     name: "useFieldArray1",
  //     nestedArray: [{ field1: "field1", field2: "field2" }]
  //   },
  //   {
  //     name: "useFieldArray2",
  //     nestedArray: [{ field1: "field1", field2: "field2" }]
  //   }
  // ]
};

const Activities:React.FC<any> = ({availableSkills, setActivitiesData2, gridType, activities}) => {
  const classes = useStyles();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue, 
    watch
  } = useForm<ActivitiesData>({
    shouldUnregister: false,
    defaultValues: {
      actividades: []
    }
  });

  const onSubmit = (data:any) => console.log("data", data);
  
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      
      
      //console.log('en cada cambio')
      //console.log(value)
      setActivitiesData2(value, gridType);
    
    });
    
    return () => subscription.unsubscribe();
  }, [watch]);



  

  return (
    <Paper
      elevation={3}
      className={classes.paperAutogestionados}
    >
      <Box display="flex" justifyContent="center" alignItems="center" className={gridType=== 'CIRCULO_MANANA' ? classes.morningActivityContainer : classes.otherActivityContainer}>
        {
          gridType === 'CIRCULO_MANANA' ?
            <Button disabled={true} startIcon={<img style={{ marginTop: '3px', marginBottom: '3px' }} src={MorningActivitiesIcon} alt="morning activities header icon" />}>
            </Button> :
            <Button disabled={true} startIcon={<img style={{ marginTop: '0px', marginBottom: '3px' }} src={OtherActivitiesIcon} alt="morning activities header icon" />}>
            </Button>
        }
        <Typography variant="h6">
          <strong>
            <Translate value={gridType === 'CIRCULO_MANANA' ? 'kinder.actividadesMananaTitle' : 'kinder.actividadesOtrosTitle'}/>
          </strong>
        </Typography>
      </Box>
      <Grid item container justify="center" alignItems="center" className={gridType=== 'CIRCULO_MANANA' ? classes.morningActivityBottom : classes.otherActivityBottom}>
        <Grid item xs={4}>
          <Box style={{textAlign: 'center'}}>
            <Typography variant="h6">
              <Translate value="kinder.actividades"/>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box style={{textAlign: 'center'}}>
            <Typography variant="h6">
              <Translate value="kinder.competencias"/>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ActivityRow
          {
            ...{ control, register, defaultValues, getValues, setValue }
          }
          availableSkills={availableSkills}
          gridType={gridType}
          activities={activities}
        />

        {/* <button type="button" onClick={() => reset(defaultValues)}>
          Reset
        </button>

        <input type="submit" /> */}
      </form>
    </Paper>
    
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperAutogestionados: {
      //borderRadius: "7% 7% 7% 7%",
      cursor: "pointer",
      padding: theme.spacing(0),
      "&:hover": {
        //transform: "scale(1.03)",
        transition: "transform .3s",
      },
      width: '100%'
    },
    morningActivityContainer: {
      color: '#FFFFFF',
      backgroundColor: '#F0968A',
      borderBottom: '1px solid #FFFFFF'
    },
    otherActivityContainer: {
      color: '#FFFFFF',
      backgroundColor: '#EBAB88',
      borderBottom: '1px solid #FFFFFF'
    },
    morningActivityBottom: {
      color: '#FFFFFF',
      backgroundColor: '#F0968A', 
      borderRadius:'0% 0% 7% 7%',
      borderBottom: '1px solid #FFFFFF'
    },
    otherActivityBottom: {
      color: '#FFFFFF',
      backgroundColor: '#EBAB88', 
      borderRadius:'0% 0% 7% 7%',
      borderBottom: '1px solid #FFFFFF'
    },
  })
);

export default Activities;
import { Notificacion } from "models/notificacion_model";
import http from "./http";

const buildQueryFilters = (filters: any): string[] => {
  return Object.keys(filters)
    .map((key) => {
      const filterValue = filters[key];
      return Array.isArray(filterValue)
        ? filterValue.map((val) => `${key}=${encodeURIComponent(val)}`).join('&')
        : `${key}=${encodeURIComponent(filterValue)}`;
    });
};

function buildQueryParamsText(filters: any = {}): string {
  const params = filters ? buildQueryFilters(filters) : [];
  const queryParamsText = params.length > 0 ? `?${params.join('&')}` : '';
  return queryParamsText;
}

export const getNotificaciones = async (tipoReporte?: string, filtros?: any, query?: any) => {
  try {
    const params = {
      consulta: JSON.stringify(filtros || {}),
      ...query,
    };
    const consulta = buildQueryParamsText(params);
    return http.get(`/app/notificaciones/${tipoReporte}${consulta}`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return null;
  }
};

export const createNotificacion = async (data: Notificacion) => {
  try {
    await http.post(`/app/notificaciones/`, data);
    return true
  } catch (error) {
    console.error("[create] Error :: ", error);
    return false
  }
};

export const obtenerNiveles = async (filtros?: any) => {
  try {
    const params = {
      consulta: JSON.stringify(filtros || {}),
    };
    const consulta = buildQueryParamsText(params);
    return http.get(`/app/notificaciones/niveles${consulta}`);
  } catch (error) {
    console.error("[buscar] Error :: ", error);
    return null;
  }
};

export const obtenerCursos = async (filtros?: any) => {
  try {
    const params = {
      consulta: JSON.stringify(filtros || {}),
    };
    const consulta = buildQueryParamsText(params);
    return http.get(`/app/notificaciones/cursos${consulta}`);
  } catch (error) {
    console.error("[buscar] Error :: ", error);
    return null;
  }
};

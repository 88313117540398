import {
  Typography,
  Grid,
  Paper,
  makeStyles,
  createStyles,
  Theme,
  Tooltip
} from "@material-ui/core";
import { Translate } from "react-redux-i18n";

const SelectedEvent = (props: any) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid container item xs={2} sm={3} justify="flex-end" style={{ marginBottom: '0px', paddingBottom: '0px' }}>
          <Typography variant="h4" className={`${props.event.isHoliday ? classes.holiday : classes.eventDay}`}>
            {props.event.start.format('D')}
          </Typography>
        </Grid>
        <Grid item xs={9} sm={8} container>
          {!props.event.isHoliday ?
            <Tooltip arrow title={
              <Typography>
                <Translate value="kinder.tema" />: {props.event.title ? props.event.title : ''}
              </Typography>}
            >
              <Grid item xs container direction="row" spacing={0}>
                <Grid item className={classes.topicContainer} xs={12} >
                  <Typography variant="body2" noWrap={true}>
                    <Translate value="kinder.tema" />: {props.event.title ? props.event.title : ''}
                  </Typography>
                </Grid>
                {props.event && props.event.colorTitle ?
                  <Grid item className={classes.circleOfColorsContainer} xs={12} >
                    <Typography variant="body2" noWrap={true}>
                      {props.event.colorTitle ? props.event.colorTitle : ''}
                    </Typography>
                  </Grid> : null
                }
              </Grid>
            </Tooltip>
            : null
          }
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: 'transparent',
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
      maxHeight: 130,
      height: 130,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    eventDay: {
      color: '#FAE268'
    },
    holiday: {
      color: '#323232'
    },
    topicContainer: {
      color: '#F0968A',
      backgroundColor: '#FFFFFF',
      border: '1px solid #F0968A',
      borderRadius: '7% 7% 7% 7%',
      maxWidth: '150px',
      textAlign: 'left',
      justifyContent: 'center',
      paddingLeft: '3px',
      marginTop: '0px',
      height: 'fit-content',
      // paddingBottom: '3px',
      // paddingTop: '3px',
    },
    circleOfColorsContainer: {
      color: '#D3BFF1',
      backgroundColor: '#FFFFFF',
      border: '1px solid #D3BFF1',
      borderRadius: '7% 7% 7% 7%',
      maxWidth: '150px',
      width: '100px',
      textAlign: 'left',
      justifyContent: 'center',
      paddingLeft: '3px',
      marginTop: '2px',
      height: 'fit-content',

      // paddingBottom: '3px',
      // paddingTop: '3px',
    },
    '@media (max-width: 1541px)': {
      holiday: {
        position: 'relative',
        //left: '60px'
      },
      eventDay: {
        position: 'relative',
        //left: '60px'
      }
    },
    '@media (max-width: 1290px)': {
      topicContainer: {
        maxWidth: '105px'
      },
      circleOfColorsContainer: {
        maxWidth: '105px'
      }
    },
    '@media (max-width: 1280px)': {
      eventDay: {
        fontSize: '1.9rem',
        position: 'relative',
        //left: '40px'
      },
      holiday: {
        fontSize: '1.9rem',
        position: 'relative',
        //left: '40px'
      },
      topicContainer: {
        marginTop: '0px',
        maxWidth: '115px',
        minWidth: '115px',
        width: '115px',
        paddingBottom: '3px !important',
        paddingTop: '0px !important'
      },
      circleOfColorsContainer: {
        maxWidth: '115px',
        minWidth: '115px',
        width: '115px',
        marginTop: '2px',
        paddingBottom: '3px !important',
        paddingTop: '0px !important'
      }
    },
  })
);

export default SelectedEvent;
import React, { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, LinearProgress } from '@material-ui/core';
import { useIsMounted } from 'hooks/useIsMounted';
import { obtenerKardexListadoEstudiantes } from 'repositories/reporte_repository';
import Virtualize, { SelectOption } from './Virtualize';

type EstudianteOption = {
    id: string;
    nombre: string;
    codigoCurso: string;
};

type ActionSearchProps = {
  onSearchClick: (estudianteId: string) => Promise<void>
  value?: string | null
  codigoCurso?: string
  isKinder?: boolean | null
}

export const Buscador = (props: ActionSearchProps): ReactElement => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState<boolean>(false);
    const isMounted = useIsMounted();

    const [estudianteOptions, setEstudianteOptions] = React.useState<SelectOption[]>([]);
    const [itemSelected, setItemSelected] = React.useState<string | null | undefined>(typeof props.value === 'undefined' ? undefined : null);

    const cargarOpciones = async () => {        
        const estudiantesResponse: any = await obtenerKardexListadoEstudiantes(
          props.isKinder ? 'LISTADO_ESTUDIANTES_INSCRITOS' : 'KARDEX_LISTADO_ESTUDIANTES',
          null,
          null,
          { codigoCurso: props.codigoCurso },
        )
        const listado = estudiantesResponse.info as EstudianteOption[];
        const estudianteOptions: SelectOption[] = (listado || []).map((item) => ({
            value: item.id || '',
            label: `${item.nombre} - ${item.codigoCurso}`,
        }));
        setEstudianteOptions(estudianteOptions);
    };
    const cargarOpcionesCallback = React.useCallback(cargarOpciones, [props.codigoCurso]);

    React.useEffect(() => {
        cargarOpcionesCallback();
    }, [cargarOpcionesCallback]);

    React.useEffect(() => {
        if (typeof props.value !== 'undefined') {
            const itemSelected = estudianteOptions.find(opt => opt.value === props.value);
            setItemSelected(itemSelected ? itemSelected.label : null);
        }
    }, [estudianteOptions, props.value]);

    const handleOnChange = async (value: any) => {
        if (loading) return;
        setLoading(true);
        await props.onSearchClick(value);
        if (!isMounted()) return;
        setLoading(false);
    };

    const handleChange = (value: SelectOption | null) => {
        const newValue = value?.value || '';
        handleOnChange(newValue);
    };

    return (
        <Box className={props.isKinder ? classes.kinderRoot : classes.root}>
            <Box width="100%" position="relative">
                <LinearProgress className={classes.loader} color="secondary" variant="indeterminate" hidden={!loading} />
                <Virtualize options={estudianteOptions} onChange={handleChange} loading={loading} value={itemSelected} />
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '350px',
    },
    kinderRoot: {
        margin: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '350px',
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        height: '4px',
        background: '#e0e0e0',
        zIndex: 9,
    },
}));

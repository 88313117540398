import http from "./http";
// import { Permiso } from "../models/permiso_model";

export const createPermiso = async (data: any) => {
  try {
    return await http.post(`/kinder/permiso/`, data);
  } catch (error) {
    console.error("[create] Error :: ", error);
    return error;
  }
};
import React, { ReactElement } from 'react';

import { Box, Typography, TextField } from '@material-ui/core';
import { FilterOption } from 'models/reporte_model';
import { Autocomplete } from '@material-ui/lab';

const SIN_OPCIONES_TEXT = ' >>> Sin opciones <<<';

type Props = {
    name: string;
    label: string;
    value?: string;
    options: FilterOption[];
    onChange: (name: string, value: string | number) => void;
    disabled?: boolean;
};

export const SelectFilter = ({ name, label, value, options, onChange, disabled }: Props): ReactElement => {
    const _val = typeof value !== 'undefined' ? value : null;
    const VALUE = options.find((opt) => opt.value === _val) || null;

    return (
        <Autocomplete
            renderOption={(option) => (
                <Box>
                    <Typography variant="body2"><strong>{option.label}</strong></Typography>
                    {option.caption && <Typography variant="caption">{option.caption}</Typography>}
                </Box>
            )}
            options={options}
            getOptionLabel={(option: FilterOption) => {
                return option.caption ? `${option.label} - ${option.caption}` : `${option.label}`;
            }}
            getOptionSelected={(option, value) => value.value === option.value}
            value={VALUE || null}
            noOptionsText={SIN_OPCIONES_TEXT}
            onChange={(event: React.ChangeEvent<unknown>, value: FilterOption | null) => onChange(name, value?.value || '')}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    label={label}
                />
            )}
        />
    );
};

import { Redirect, Route } from "react-router"

// type PublicRouteType = {
//   isAuthenticated: boolean,
//   Componente: React.FC
// }

const PublicRoute: React.FC<any> = ({
  isAuthenticated,
  Componente,
  ...rest
}) => {
  // return (
  //   <Route
  //     {...rest}
  //     component={ (props: any) => (
  //       isAuthenticated ? <Redirect to="/" /> : <Component {...props} />
  //     )}
  //   />
  // )
  return (
    <Route
      { ...rest }
      component={ ( props: any ) => (
        ( isAuthenticated )
          ? <Redirect to="/" />
          : <Componente { ...props } />
      )}
    />
  )
}

export default PublicRoute

import http from "./http";
import { LibroDeCursoTemaForm } from "../models/libro_de_curso_tema_form_model";

export const createTopic = async (data: LibroDeCursoTemaForm) => {
  try {
    return await http.post(`/kinder/tema`, data);
  } catch (error) {
    console.error("[create] Error :: ", error);
    return error;
  }
};
import { Redirect, Route } from "react-router"

// type PrivateRouteType = {
//   isAuthenticated: boolean,
//   Componente: React.FC
// }

const PrivateRoute: React.FC<any> = ({
  isAuthenticated,
  Componente,
  ...rest
}) => {
  // return (
  //   <Route
  //     {...rest}
  //     component={ (props: any) => (
  //       isAuthenticated ? <Component {...props} /> : <Redirect to="/" /> 
  //     )}
  //   />
  // )
  return (
    <Route
      { ...rest }
      component={ ( props: any ) => (
        ( isAuthenticated )
          ? <Componente { ...props } />
          : <Redirect to="/auth" />
      )}
    />
  )
}

export default PrivateRoute

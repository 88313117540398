import http from "./http";
//import { LibroDeCurso } from '../models/libro_de_curso_model';

export const getSkills = async () => {
  try {

    const items = await http.get(`/kinder/competencia`);
    
    return items;
  } catch (error) {
    console.error("[get_skills]  Error -> ", error);
    return error;
  }
};

export const saveKinderSkills = async ( data:any ) => {
  try {

    const res = await http.post(`/kinder/competencia`, data);
    
    return res;
  } catch (error) {
    console.error("[save_kinder_skills]  Error -> ", error);
    return error;
  }
};
export type Action = {
  type: string;
  payload: any;
};

export const AUTH_TYPES = {
  LOGIN: '[AUTH] Login',
  LOGOUT: '[AUTH] Logout'
}


export const PROFESOR_TYPES = {
  LOAD_PROFESORS: '[Profesor] Load profesors',
  ADD_PROFESOR: '[Profesor] Add new profesor',
  EDIT_PROFESOR: '[Profesor] Edit profesor',
  DELETE_PROFESOR: '[Profesor] Delete profesor',
}

export const USER_TYPES = {
  LOAD_USERS: '[USER] Load users',
  ADD_USER: '[USER] Create user',
  EDIT_USER: '[USER] Edit user',
  DELETE_USER: '[USER] Delete user',
}

export const CURSO_TYPES = {
  CARGAR_CURSOS: '[CURSO] Cargar cursos',
  AGREGAR_CURSO: '[CURSO] Crear curso',
  EDITAR_CURSO: '[CURSO] Editar curso',
  ELIMINAR_CURSO: '[CURSO] Eliminar curso',
  CHANGE_CURSO: '[CURSO] Setear curso',
  SET_STEP_FORM: '[CURSO] Set step form',
  SET_FORM_CURSO: '[CURSO] Set form curso',
}

export const MATERIA_TYPES = {
  CARGAR_MATERIAS: '[MATERIA] Cargar materias',
  AGREGAR_MATERIA: '[MATERIA] Crear materia',
  EDITAR_MATERIA: '[MATERIA] Editar materia',
  ELIMINAR_MATERIA: '[MATERIA] Eliminar materia',
  SET_MATERIA: '[MATERIA] Setear materia'
}

export const EVENTO_TYPES = {
  CARGAR_EVENTOS: '[EVENTO] Cargar eventos',
  AGREGAR_EVENTO: '[EVENTO] Crear evento',
  EDITAR_EVENTO: '[EVENTO] Editar evento',
  ELIMINAR_EVENTO: '[EVENTO] Eliminar evento',
  SET_EVENTO: '[EVENTO] Setear evento'
}

export const PERMISO_TYPES = {
  CARGAR_PERMISOS: '[PERMISO] Cargar eventos',
  AGREGAR_PERMISO: '[PERMISO] Crear evento',
  EDITAR_PERMISO: '[PERMISO] Editar evento',
  ELIMINAR_PERMISO: '[PERMISO] Eliminar evento',
  SET_PERMISO: '[PERMISO] Setear evento',
  ENVIAR_CORREO: '[PERMISO] Enviar correo',
}

export const REPORTE_TYPES = {
  CARGAR_REPORTE: '[REPORTE] Cargar reporte',
}

export enum ESTUDIANTE_TYPES {
  CARGAR_ESTUDIANTES = '[ESTUDIANTE] Cargar estudiantes',
  AGREGAR_ESTUDIANTE = '[ESTUDIANTE] Crear estudiante',
  EDITAR_ESTUDIANTE = '[ESTUDIANTE] Editar estudiante',
  ELIMINAR_ESTUDIANTE = '[ESTUDIANTE] Eliminar estudiante',
  SET_ESTUDIANTE = '[ESTUDIANTE] Setear estudiante',
  SET_FORM_ESTUDIANTE = '[ESTUDIANTE] Set form estudiante',
}

export const COURSE_BOOK_TYPES = {
  GET_COURSE_BOOK: '[COURSE_BOOK] Get course book',
  SET_COURSE_BOOK: '[COURSE_BOOK] Set course book',
  SET_COURSE_BOOK_TOPIC_FORM: '[COURSE_BOOK] Set course book topic form',
  SET_CIRCLE_OF_COLORS_FORM: '[COURSE_BOOK] Set circle of colors form'
};

export const KINDER_ASSISTANCE_TYPES = {
  GET_KINDER_ASSISTANCE: '[KINDER_ASSISTANCE] Get kinder assistance'
};

export const SKILLS_TYPES = {
  GET_KINDER_SKILLS: '[SKILLS] Get kinder skills'
}

export const CIRCULAR_TYPES = {
  LOAD_CIRCULARES: '[CIRCULAR] Load circulares',
  ADD_CIRCULAR: '[CIRCULAR] Create circular',
  EDIT_CIRCULAR: '[CIRCULAR] Edit circular',
  DELETE_CIRCULAR: '[CIRCULAR] Delete circular', 
}

import http from "./http";
import { LibroDeCursoCirculoDeColoresForm } from "models/libro_de_curso_circulo_de_colores_form_model";

export const saveCircleOfColors = async (data: LibroDeCursoCirculoDeColoresForm) => {
  try {
    return await http.post(`/kinder/circulo`, data);
  } catch (error) {
    console.error("[create] Error :: ", error);
    return error;
  }
};
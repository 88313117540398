import {
  Typography,
  Box,
  Grid,
  Button
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';

const CalendarCustomToolbar = ({setBookWeek}:any) =>({date, onNavigate, myEvents, handleChange, label}:any) => {
  // const classes = useStyles();
  
  let navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
  }

  const currentLocale = localStorage.getItem('locale');

  if(currentLocale)
    moment.locale(currentLocale);

  const monthDate = moment(date).format('MMMM');
  const headerTitle= `${monthDate[0].toUpperCase() + monthDate.slice(1)}, ${moment(date).year()}`;

  const handlePrevious = () => {
    setBookWeek("DECREMENT");
    onNavigate(navigate.PREVIOUS);
  }

  const handleNext = () => {
    setBookWeek("INCREMENT");
    onNavigate(navigate.NEXT);
  }
  
  
  return(
    // <Box border={3} className={classes.headerContainer}>
    <Box border={3} style={{ borderColor: '#EBAB88', borderTopLeftRadius: 15, borderTopRightRadius: 15, backgroundColor: '#EBAB88', color: '#ffffff'}}>
      <Grid container>
        <Grid item xs={1} container justify="flex-start">
          <Button 
            variant="text" 
            startIcon={<ArrowBackIosIcon style={{ color: '#ffffff'}}/>}
            // onClick={() => onNavigate(navigate.PREVIOUS)}
            onClick={handlePrevious}
            >       
          </Button>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6" align="center">
              {headerTitle}
          </Typography>
        </Grid>
        <Grid item xs={1} container justify="flex-end">
          <Button
            variant="text" 
            startIcon={<ArrowForwardIosIcon style={{ color: '#ffffff'}}/>}
            onClick={handleNext}>
          </Button>
        </Grid>    
      </Grid>
    </Box>
  )
}

export default CalendarCustomToolbar;
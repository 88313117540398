import React, { useEffect } from "react";
import { useHistory } from "react-router";
import {
  Button,
  IconButton,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  useTheme,
  Tooltip,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from "@material-ui/core";
import {
  FilterList as FilterListIcon,
  Refresh as RefreshIcon,
  Search
} from "@material-ui/icons";
import ModalCourse from "./components/ModalCourse";
import { useDispatch } from "react-redux";
import { RootState, useStateSelector } from "../../store/reducers";
import { agregarCurso, cargarCursos, changeCurso, editarCurso, eliminarCurso, setFormCurso, setStepForm } from "../../store/actions/curso_actions";
import { Curso } from "../../models/curso_model";
import message from "../../plugins/utils/message";
import { Translate, I18n } from "react-redux-i18n";
import moment from "moment";
interface Column {
  id: "actions" | "codigoCurso" | "paralelo" | "turno" | "asesor" | "fechaInicio" | "fechaFin" | "jefeCurso";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "actions", label: I18n.t("tabla.acciones") },
  { id: "codigoCurso", label: "Curso" },
  { id: "paralelo", label: "Paralelo" },
  { id: "turno", label: "Turno" },
  { id: "jefeCurso", label: "Jefe de curso" },
  { id: "fechaInicio", label: "Fecha de inicio" },
  { id: "fechaFin", label: "Fecha Fin" },
];

const gestiones: number[] = []
for (let g = 2022; g <= moment().year(); g++) {
  gestiones.push(g)
}

const Course: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { cursos, curso, stepForm, formCurso } = useStateSelector((state: RootState) => state.cursoState);

  useEffect(() => {
    dispatch( cargarCursos() );
  }, [dispatch])

  const handleRefreshCursos = () => dispatch( cargarCursos() );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [modalFormCourse, setModalFormCourse ] = React.useState<boolean>(false);

  const handleClickOpen = () => {
    setModalFormCourse(true);
    dispatch( setStepForm(1) );
  };
  const handleClose = () => {
    setModalFormCourse(false);
    dispatch( setStepForm(1) );
    dispatch( changeCurso(null) );
    dispatch( setFormCurso(null) );
  };

  const handleBack = () => {
    dispatch( setStepForm(stepForm - 1));
  }

  const handleSaveStepOne = () => {
    dispatch( setStepForm(stepForm + 1) );
  }

  const handleSaveStepTwo = (data: any) => {
    console.log('EStado del formulario de curso :: ', formCurso);

    const datosGuardar = {
      ...formCurso,
      ...data,
    }
    datosGuardar.fechaInicio = moment(datosGuardar.fechaInicio).format('DD/MM/YYYY')
    datosGuardar.fechaFin = moment(datosGuardar.fechaFin).format('DD/MM/YYYY')

    if (curso && curso.id) {
      console.log('editando curso', curso.id);
      dispatch( editarCurso(datosGuardar, curso.id) );
    } else {
      console.log('creado curso')
      dispatch( agregarCurso(datosGuardar) );
    }

    message.success(`${curso?.id ? 'Edición' : 'Creación'} del curso exitosa.`)

    setTimeout(() => {
      handleRefreshCursos()
    }, 1000)

    handleClose();
  }

  const handleDeleteCurso = async (curso: Curso) => {
    const deleted = await message.confirm(`¿Desea eliminar el curso ${curso.codigoCurso}? --- ADVERTENCIA ---: Esta acción es irreversible, una vez completada la operación todos los datos relacionados con el curso serán eliminados y no podrán recuperarse.`);
    if (deleted) {
      message.success('Se está eliminando el curso, por favor espere un momento...');
      dispatch( eliminarCurso(String(curso.id)) );
      setTimeout(() => {
        dispatch( cargarCursos() );
      }, 2000);
    }
  }

  const handleEditCurso = (_curso: Curso) => {
    console.log('Datos del curso a editar', _curso)
    dispatch( changeCurso(_curso) );
    handleClickOpen();
  }

  const handleViewCurso = (_curso: Curso) => {
    dispatch( changeCurso(_curso) );
    history.push(`/courses/${_curso.id}`);
  }

  // Filters
  const [filter, setFilter] = React.useState(false);
  const [cursoFilter, setCursoFilter] = React.useState('');
  const [gestionFilter, setGestionFilter] = React.useState('');
  const [turnoFilter, setTurnoFilter] = React.useState('');

  const handleFilter = () => {
    setFilter(!filter);
  }

  const handleCursoFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCursoFilter(event.target.value)
  }

  const handleGestionFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGestionFilter(event.target.value as string)
  }

  const handleEmailFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTurnoFilter(event.target.value as string)
  }

  const handleSubmitFilter = () => {
    dispatch( cargarCursos(cursoFilter, turnoFilter, gestionFilter) )
    setPage(0)
  }

  const handleFilterCancel = () => {
    setFilter(false)
    setCursoFilter('')
    setTurnoFilter('')
    setGestionFilter('')
    handleRefreshCursos();
  }

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Box p={3} display='flex'>
          <Button variant='contained' color='primary' onClick={handleClickOpen}>
            <Translate value="crearCurso" />
          </Button>
          <Button variant='contained' style={{ marginLeft: 10 }} onClick={handleFilter}>
            <FilterListIcon />
          </Button>
          <Button variant='contained' onClick={handleRefreshCursos} style={{ marginLeft: 10 }}>
            <RefreshIcon />
          </Button>
        </Box>
        {filter && (
          <div className={classes.containerFilter}>
            <label><Translate value="tabla.buscarPor" />: </label><br />
            <TextField
              label="Curso"
              value={cursoFilter}
              variant="filled"
              size="small"
              onChange={handleCursoFilter} />
            <FormControl variant="filled" size="small" style={{ width: 120 }}>
              <InputLabel id="gestion"><Translate value="tabla.gestion" /></InputLabel>
              <Select
                labelId="gestion"
                id="gestion"
                value={gestionFilter}
                onChange={handleGestionFilter}
              >
                <MenuItem value="">
                  <em><Translate value="tabla.todos" /></em>
                </MenuItem>
                {gestiones.map((g, index) => {
                  return <MenuItem key={index} value={`${g}`}>{g}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl variant="filled" size="small" style={{ width: 120 }}>
              <InputLabel id="turno">Turno</InputLabel>
              <Select
                labelId="turno"
                id="turno"
                value={turnoFilter}
                onChange={handleEmailFilter}
              >
                <MenuItem value="">
                  <em><Translate value="tabla.todos" /></em>
                </MenuItem>
                <MenuItem value="TARDE">TARDE</MenuItem>
                <MenuItem value="MAÑANA">MAÑANA</MenuItem>
                <MenuItem value="NOCHE">NOCHE</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleSubmitFilter}>
              <Search /> <Translate value="tabla.buscar" />
            </Button>
            {(turnoFilter.length > 0 || cursoFilter.length > 0) && (
              <Button variant="contained" onClick={handleFilterCancel}>
                <Translate value="form.cancelar" />
              </Button>
            )}
          </div>
        )}
        {(cursos.length === 0 || cursos === undefined || cursos === null) && (
          <Typography variant='subtitle1' align='center'>
            <Translate value="sinRegistroCursos" />
          </Typography>
        )}
        { cursos.length > 0 && (
          <React.Fragment>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cursos
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: Curso) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell>
                            <Box display='flex'>
                              <Tooltip title="Editar curso">
                                <IconButton size='small' onClick={() => handleEditCurso(row)}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.9898 18.9533C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533H19.9898ZM16.0299 3.69906L17.5049 4.87078C18.1097 5.34377 18.513 5.96726 18.6509 6.62299C18.8101 7.3443 18.6403 8.0527 18.1628 8.66544L9.3764 20.0279C8.97316 20.5439 8.37891 20.8341 7.74222 20.8449L4.24039 20.8879C4.04939 20.8879 3.89021 20.7589 3.84777 20.5761L3.0519 17.1255C2.91395 16.4912 3.0519 15.8355 3.45514 15.3303L9.68413 7.26797C9.79025 7.13898 9.98126 7.11855 10.1086 7.21422L12.7297 9.29967C12.8994 9.43942 13.1329 9.51467 13.377 9.48242C13.8969 9.41792 14.2471 8.94493 14.1941 8.43969C14.1622 8.1817 14.0349 7.96671 13.8651 7.80546C13.812 7.76246 11.3183 5.76301 11.3183 5.76301C11.1591 5.63401 11.1273 5.39752 11.2546 5.23735L12.2415 3.95706C13.1541 2.78534 14.7459 2.67784 16.0299 3.69906Z" fill={theme.palette.warning.main}/>
                                  </svg>
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Eliminar curso">
                                <IconButton size='small' onClick={() => row.id ? handleDeleteCurso(row) : null}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.9391 8.69713C19.1384 8.69713 19.3193 8.78413 19.4623 8.93113C19.5955 9.08813 19.6626 9.28313 19.6432 9.48913C19.6432 9.55712 19.1102 16.2971 18.8058 19.134C18.6152 20.875 17.4929 21.932 15.8094 21.961C14.5149 21.99 13.2496 22 12.0038 22C10.6811 22 9.38763 21.99 8.13206 21.961C6.50498 21.922 5.38168 20.846 5.20079 19.134C4.88763 16.2871 4.36439 9.55712 4.35467 9.48913C4.34494 9.28313 4.41108 9.08813 4.54529 8.93113C4.67756 8.78413 4.86818 8.69713 5.06852 8.69713H18.9391ZM14.0647 2C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647Z" fill={theme.palette.error.main}/>
                                  </svg>
                                </IconButton>
                              </Tooltip>
                              {
                                row.nivel !== 'KINDER' ? 
                                  <Tooltip title="Ver materias del curso">
                                    <IconButton size="small" onClick={() => handleViewCurso(row)}>
                                      <svg width="24" height="24" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.0807 0H21.0458C20.3721 0 20.0353 0 19.79 0.155672C19.6657 0.234547 19.5604 0.339887 19.4815 0.464173C19.3258 0.709473 19.3258 1.04632 19.3258 1.72V9.32378C19.3258 9.97885 18.6635 10.3027 18.1503 9.48027L17.7893 8.90255C17.2474 8.03566 16.9765 7.60221 16.6103 7.49555C16.4277 7.44236 16.2337 7.44236 16.0511 7.49555C15.6849 7.60221 15.414 8.03566 14.8721 8.90255L14.5111 9.48027C13.9943 10.2991 13.3356 9.97885 13.3356 9.32378V1.72C13.3356 1.04632 13.3356 0.709473 13.1799 0.464173C13.101 0.339887 12.9957 0.234547 12.8714 0.155672C12.6261 0 12.2893 0 11.6156 0H4.09915C1.86637 0.00600478 0.0595703 1.81772 0.0595703 4.05049V27.9604C0.0655751 30.1889 1.87065 31.994 4.09915 32H22.0844C22.5762 31.9992 23.0476 31.8028 23.3945 31.4541C23.7432 31.1072 23.9396 30.6358 23.9404 30.144V1.85966C23.9384 0.83339 23.107 0.00200159 22.0807 0ZM22.4265 25.7951C22.4265 26.1696 22.4265 26.3568 22.3769 26.5083C22.2779 26.8108 22.0409 27.0481 21.7386 27.1475C21.5871 27.1972 21.3999 27.1975 21.0253 27.1979L4.87795 27.218C4.49546 27.2444 4.19113 27.5488 4.16475 27.9312C4.13554 28.3543 4.45489 28.7209 4.87795 28.7501H21.0144C21.3954 28.7501 21.5859 28.7501 21.7397 28.8013C22.0395 28.9011 22.2745 29.1367 22.3735 29.4368C22.4243 29.5907 22.4238 29.7812 22.4228 30.1622C22.423 30.2463 22.3903 30.3273 22.3318 30.3878C22.2713 30.4463 22.1904 30.479 22.1062 30.4788H4.09915C2.71732 30.4788 1.59716 29.3586 1.59716 27.9768C1.59716 26.595 2.71732 25.4748 4.09915 25.4748H22.1062C22.2823 25.4768 22.4245 25.619 22.4265 25.7951Z" fill="#323232"/>
                                      </svg>
                                    </IconButton>
                                  </Tooltip> : null
                              }
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography>{row.codigoCurso} - {row.gestion}</Typography>
                            <Typography variant="caption" style={{ color: 'grey' }}>{row.nivel} - {row.grado}</Typography>
                          </TableCell>
                          <TableCell>
                            {row.paralelo}
                          </TableCell>
                          <TableCell align='left'>
                            {row.turno}
                          </TableCell>
                          <TableCell align='left'>
                            {row.jefeCurso?.nombre}
                          </TableCell>
                          <TableCell align='left'>
                            {row.fechaInicioFormato}
                          </TableCell>
                          <TableCell align='left'>
                            {row.fechaFinFormato}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 50, 100, 250]}
              component="div"
              count={cursos.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </React.Fragment>
        )}
      </Paper>
      { modalFormCourse && (
        <ModalCourse
          handleBack={handleBack}
          handleClose={handleClose}
          handleSaveStepOne={handleSaveStepOne}
          handleSaveStepTwo={handleSaveStepTwo}
          isModalOpen={modalFormCourse}
          step={stepForm}
          curso={curso}
        />
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 440,
    // maxHeight: 'calc(100vh - 350px)',
  },
  containerFilter: {
    padding: '0 15px 15px',
    '& .MuiFormControl-root, & .MuiButtonBase-root': {
      marginRight: 5
    },
    '& .MuiButtonBase-root': {
      marginTop: 6
    }
  },
  btnAction: {
    margin: '0 3px',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

export default Course;

import { SkillsType } from "models/competencias_model";
import { SKILLS_TYPES, Action } from "store/types";

export type State = {
  skills: SkillsType | null;
};

const initialState: State = {
  skills: null,
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case SKILLS_TYPES.GET_KINDER_SKILLS:
      return {
        ...state,
        skills: payload,
      };
    default:
      return state;
  }
};

export default reducer;
import {
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import CalendarEventWrapper from "./CalendarEventWrapper";
import CalendarEvent from "./CalendarEvent";
import CalendarCustomToolbar from "./CalendarCustomToolBar";
import './styles.css';

localStorage.getItem('locale') === 'de' ? moment.updateLocale('de',{
  week: {
    dow: 1,
    doy: 7
  }}) : 
  moment.updateLocale('es', { 
    week:{
      dow: 1,
      doy: 7
    }
  });


const localizer = momentLocalizer(moment);

type KinderCalendarProps = {
  libroDeCurso?: any;
  setKinderCalendarDate: (selectedDate:Date) => void;
  setBookWeek: (val:string) => void;
};
  
// const customDayPropGetter = (date: any) => {
//     if (date.getDate() === 7 || date.getDate() === 15)
//       return {
//         style: {
//           border: 'solid 3px ' + (date.getDate() === 7 ? '#faa' : '#afa'),
//         },
//       }
//     else return {}
// }
    
// const customSlotPropGetter = (date:any) => {
//     if (date.getDate() === 7 || date.getDate() === 15)
//       return {
//         style: {
//             backgroundColor: 'pink'
//         }
//       }
//     else return {}
// }

const KinderCalendar: React.FC<KinderCalendarProps> = ({ libroDeCurso, setKinderCalendarDate, setBookWeek }) => {
  const classes = useStyles();
  const [myEvents, setEvents] = useState([]); 
  const [selectedDate, setSelectedDate] = useState(null); 

  const handleSetNewEvents = (newEvents:[]) => {
    setEvents(newEvents);
  }

  const handleSetSelectedDate = (date:any) => {
    setSelectedDate(date);
  }

  useEffect(() => {
    let i = 0;

    if(libroDeCurso && libroDeCurso.dias) {
        
      let newEvents = libroDeCurso.dias.map((item:any) => ({
        id: i++,
        title: item.tema,
        allDay: true,
        isHoliday: !item.esDiaHabil,
        start: moment(item.fecha),
        end: moment(item.fecha),
        colorTitle: item.temaCirculo
      }));

      handleSetNewEvents(newEvents);
    }
     
  }, [libroDeCurso]);
  
  // const handleSelectSlot = useCallback(
  //   ({ start, end }) => {
  //     const title = window.prompt('New Event name')
  //     if (title) {
  //       setEvents((prev : any) => [...prev, { id:Math.floor(Math.random() * 10000), title: title, allDay:true, start, end }])
  //     }
  //   },
  //   [setEvents]
  // )
  
  const handleSelectEvent = useCallback(
    (event) => {
      setKinderCalendarDate(event.start);
      //console.log('event es');
      // console.log(event.start.format());
      //window.alert(event.title)
      handleSetSelectedDate(event.start)
    }, [setKinderCalendarDate, handleSetSelectedDate]);

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(isSelected && {
        style: {
          
          
        },
        className: 'kinder-selected-date'
      }),
      ...(!isSelected && {
        style: {
          backgroundColor: 'transparent',
          padding:0,
          margin:0
        },
      })
    }),
    []
  )

  const { components, defaultDate } = useMemo( () => ({
    components: {
      event: CalendarEvent({ selectedDate }),
      eventWrapper: CalendarEventWrapper,
      toolbar: CalendarCustomToolbar({setBookWeek})
    },
    defaultDate: moment() as any
  }), [classes, selectedDate]);
  
  return (
  <div className="height600">
          <Calendar
            components={components as any}
            //dayPropGetter={customDayPropGetter}
            defaultDate={defaultDate}
            events={myEvents}
            localizer={localizer}
            //slotPropGetter={customSlotPropGetter}
            onSelectEvent={handleSelectEvent}
            //onSelectSlot={handleSelectSlot}
            selectable
            defaultView="work_week"
            views= {{
              work_week: true
            }}
            eventPropGetter={eventPropGetter}
            //scrollToTime={scrollToTime as any}
            
            //views={views}
          />
        </div>
      
  )
  };
  
  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      eventDay: {
        color: '#EBAB88'
      },
      holiday: {
        color: '#696969'
      },
      headerContainer: {
        borderColor: '#EBAB88',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        backgroundColor: '#EBAB88',
        color: '#ffffff'
      },
      rbcDisplayNone:{
        display: 'none'
      }
  })
  );
  
  export default KinderCalendar;
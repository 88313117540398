import http from "./http";

const buildQueryFilters = (filters: any): string[] => {
  return Object.keys(filters)
    .map((key) => {
      const filterValue = filters[key];
      return Array.isArray(filterValue)
        ? filterValue.map((val) => `${key}=${encodeURIComponent(val)}`).join('&')
        : `${key}=${encodeURIComponent(filterValue)}`;
    });
};

function buildQueryParamsText(filters: any = {}): string {  
  const params = filters ? buildQueryFilters(filters) : [];
  const queryParamsText = params.length > 0 ? `?${params.join('&')}` : '';  
  return queryParamsText;
}

export const getLibroClase = async (profesorId: string, fecha: string) => {
  try {
    const params = {
      fecha,
    };
    const consulta = buildQueryParamsText(params);
    return http.get(`/v2/materia/profesor/${profesorId}${consulta}`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return null;
  }
}

export const saveLibroClase = async (claseId: string, avances: string, tarea: boolean) => {
  try {
    const data = {
      avances,
      tarea,
    };
    return http.put(`/clase/${claseId}/registrarAvance`, data);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return null;
  }
};

export const buscarTarea = async (claseId: string): Promise<any> => {
  try {
    return http.get(`/clase/tarea?claseId=${claseId}`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return null;
  }
};

export const crearTarea = async (data: any) => {
  try {
    return http.post(`/clase/tarea`, data);
  } catch (error) {
    console.error("[post] Error :: ", error);
    return null;
  }
};

export const modificarTarea = async (tareaId: string, data: any) => {
  try {
    return http.put(`/clase/tarea/${tareaId}`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return null;
  }
};

export const buscarAnotaciones = async (claseId: string): Promise<any> => {
  try {
    return http.get(`/clase/${claseId}/anotacion`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return [];
  }
};

export const guardarAnotaciones = async (claseId: string, data: any) => {
  try {
    return http.post(`/clase/${claseId}/anotacion`, data);
  } catch (error) {
    console.error("[post] Error :: ", error);
    return null;
  }
};

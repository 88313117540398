import { Box, DialogContent, Button, DialogActions } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux';
import { Translate } from "react-redux-i18n";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { ActivitiesData } from 'models/libro_de_curso_categories_model';
import AreaRow from './components/AreaRow';
import { getKinderSkills, saveSkills } from 'store/actions/competencias_actions';
import { RootState, useStateSelector } from 'store/reducers';
import message from 'plugins/utils/message';

let defaultValues = {
  areas:[]
};

type FormNewTopicProps = {
  handleClose: () => void;
  handleSave: (data: any) => void;
}

const FormSkills: React.FC<FormNewTopicProps> = ({
  handleClose,
  handleSave
}) => {
  
  const dispatch = useDispatch();
  const { skills } = useStateSelector((state: RootState) => state.skillsState);
  const [kinderSkills, setKinderSkills] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
  } = useForm<ActivitiesData>({
    shouldUnregister: false,
    defaultValues: {
      areas: []
    }
  });

  useEffect(() => {

    dispatch( getKinderSkills() );

  }, [dispatch]);

  useEffect(() => {
    
    if(skills) {
      handleSetKinderSkills(skills)
    }

  }, [skills]);

  const handleSetKinderSkills = (data:any) => {
    setKinderSkills(data);
  }
  
  const onSubmit = (data:any) => {
    console.log("data", data);

    let formData:any = [];

    let stopSave:boolean = false;

    for(let row in data.areas) {
      for(let skill in data.areas[row].competencias) {

        if ( data.areas[row].nombre.trim().length < 1 ) {
          stopSave = true;
          message.warning('El nombre no puede estar vacio');
          break;
        }

        if ( data.areas[row].competencias.length < 1 ) {
          stopSave = true;
          message.warning('Se debe tener al menos una competencia');
          break;
        }

        let obj:any = {
          id: data.areas[row].competencias[skill].skillId,
          nombre: data.areas[row].competencias[skill].val,
          categoria: data.areas[row].nombre
        }
        formData = [...formData, obj]
      }
    }

    if(stopSave)
      return;

    dispatch( saveSkills(formData) );

    message.success('Guardado correctamente')

    //console.log('post data')
    //console.log(formData)

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Box my={3}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AreaRow
              {
                ...{ control, register, defaultValues, getValues, setValue }
              }
              kinderSkills={kinderSkills}
              activities={[]}
            />
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonGroup fullWidth={true} variant="text" aria-label="text primary button group">
          <Button onClick={handleClose}>
            <Translate value="kinder.cancelar" />
          </Button>
          <Button type="submit">
            <Translate value="kinder.guardar" />
          </Button>
        </ButtonGroup>
      </DialogActions>
    </form>
  )
}

export default FormSkills;
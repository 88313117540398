import http from "./http";

export const obtenerParametricas = async (grupo: string) => {
  try {
    return http.get(`/parametricas?grupo=${grupo}`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return [];
  }
};

import { Dialog, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import { Translate } from "react-redux-i18n";
import FormNewTopic from "../FormNewTopic";

type FormCourseProps = {
  formType: string | null;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSaveNewTopic: (data: any) => void;
  handleSaveNewCircleOfColors: (data: any) => void;
  topicValue: string;
  topicStart: string;
  topicEnd: string;
  colorValue: string;
  colorStart: string;
  colorEnd: string;
};

const ModalTopic: React.FC<FormCourseProps> = ({
  formType,
  isModalOpen,
  handleClose,
  handleSaveNewTopic,
  handleSaveNewCircleOfColors,
  topicValue,
  topicStart,
  topicEnd,
  colorValue,
  colorStart,
  colorEnd
}) => {
  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Grid container justify="space-between">
          <Typography gutterBottom style={{  }}>
            {formType === 'topic' ? <Translate value="kinder.crearModalNuevoTema" /> : <Translate value="kinder.crearModalNuevoCirculo" />}
          </Typography>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <FormNewTopic
        formType={formType} 
        handleClose={handleClose} 
        handleSave={handleSaveNewTopic}
        handleSaveCircleOfColors={handleSaveNewCircleOfColors}
        topicValue={topicValue}
        topicStart={topicStart}
        topicEnd={topicEnd}
        colorValue={colorValue}
        colorStart={colorStart}
        colorEnd={colorEnd}
        />
    </Dialog>
  );

}

export default ModalTopic;
import { Divider, FormControl, IconButton, TextField, useTheme } from "@material-ui/core";
import {Delete as DeleteIcon, AddCircle as AddIcon} from '@material-ui/icons';
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Translate } from "react-redux-i18n";

const SkillsRow: React.FC<any> = ({ nestIndex, control, register, availableSkills }) => {

  const theme = useTheme();

  const { fields, remove, append } = useFieldArray({
    control,
    name: `areas[${nestIndex}].competencias`
  });

  return (
    <React.Fragment>
      {fields.map((item:any, k) => {
        
        return (
          <React.Fragment key={item.id}>
            <Controller
              name={`areas[${nestIndex}].competencias[${k}].val`}
              control={control}
              defaultValue={item.val}
              //defaultValue={item.id}
              render={({ field }) => {

                return(
                <FormControl variant="filled">
                  <TextField
                    type='text'
                    required
                    inputProps={{step: 1, min: 0, max: 6}}
                    {...field}
                    //className={classes.formControl}
                    style={{margin: theme.spacing(1), minWidth: "100px",}}
                    variant='filled'
                    label={<Translate value="Competencia"/>}
                  />
                </FormControl>
              )}}
            />
            <IconButton size='small' onClick={() => remove(k)} style={{ color: theme.palette.common.black }}>
              <DeleteIcon />
            </IconButton>
          </React.Fragment>
        );
      })}
 
      <IconButton size='small' onClick={() => append({val:'', skillId: null})} style={{ color: theme.palette.success.main }}>
        <AddIcon fontSize='large' />
      </IconButton>
      <Divider />

    </React.Fragment>
  );
};

export default SkillsRow;
import {
  Switch,
  createStyles,
  withStyles,
  Theme,
} from '@material-ui/core';

export const CSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 1,
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.common.black}`,
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.success.main,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.success.light,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      borderStyle: 'solid',
      borderColor: theme.palette.common.black,
      borderWidth: 1,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.error.light,
    },
    checked: {},
  }),
)(Switch);
import http from "./http";
import mobileHttp from './http-mobile';

export const getEstudiantes = async (cursoFilter?: string, turnoFilter?: string, nombreFilter?: string) => {
  try {
  let filters = []
    if (cursoFilter) {
      filters.push(`curso=${cursoFilter}`)
    }
    if (turnoFilter) {
      filters.push(`turno=${turnoFilter}`)
    }
    if (nombreFilter) {
      filters.push(`nombre=${nombreFilter}`)
    }
    return await http.get(`/estudiante/${filters.length ? ('?' + filters.join('&')) : ''}`);
  } catch (error) {
    return error;
  }
};

export const getEstudiantesConInscripciones = async (cursoFilter?: string, turnoFilter?: string, nombreFilter?: string, gestionFilter?: string) => {
  try {
  let filters = []
    if (cursoFilter) {
      filters.push(`curso=${cursoFilter}`)
    }
    if (turnoFilter) {
      filters.push(`turno=${turnoFilter}`)
    }
    if (nombreFilter) {
      filters.push(`nombre=${nombreFilter}`)
    }
    if (gestionFilter) {
      filters.push(`gestion=${gestionFilter}`)
    }
    return await http.get(`/inscripcion/${filters.length ? ('?' + filters.join('&')) : ''}`);
  } catch (error) {
    return error;
  }
};

export const getEstudianteById = async (id: string) => {
  try {
    return await http.get(`/estudiante/${id}`);
  } catch (error) {
    console.error('[get_by_id] error :: ', error);
    return error;
  }
}

function getFormData (data: any, file?: any) {
  delete data?.hijo?.imagen;
  const dataArray = new FormData();
  for (const key in data) {
    if (key === 'hijo') {
      for (const subkey in data[key]) {
        dataArray.append(`hijo_${subkey}`, data[key][subkey]);
      }
    } else if (key === 'padre') {
      for (const subkey in data[key]) {
        dataArray.append(`padre_${subkey}`, data[key][subkey]);
      }
    } else if (key === 'padre2') {
      for (const subkey in data[key]) {
        dataArray.append(`padre2_${subkey}`, data[key][subkey]);
      }
    } else {
      dataArray.append(key, data[key]);
    }
  }
  dataArray.append("file", file[0] || file);

  return dataArray;
}

export const createEstudiante = async (data: any, file?: any) => {
  try {
    if (file) {
      return await http.post('/estudiante', getFormData(data, file), {
        "Content-Type": "multipart/form-data"
      });
    }
    return await http.post('/estudiante', data);
  } catch (error) {
    console.error("[create] Error :: ", error);
    return error;
  }
};

export const editEstudiante = async (data: any, id: string, file?: any) => {
  try {
    if (file) {
      return await http.put(`/estudiante/${id}`, getFormData(data, file), {
        "Content-Type": "multipart/form-data"
      });
    }
    return await http.put(`/estudiante/${id}`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const updateEstudiante= async (data: any, id: string) => {
  try {
    return await http.put(`/estudiante/update/${id}`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const deleteEstudiante = async (estudianteId: string, cursoId: string) => {
  try {
    return await http.delete(`/estudiante/${estudianteId}/curso/${cursoId}`);
  } catch (error) {
    console.error("[delete] Error :: ", error);
    return error;
  }
};

export const changeCursoEstudiante = async (estudianteId: string, cursoId: string, nuevoCurso: string) => {
  try {
    return await http.put(`/estudiante/${estudianteId}/curso/${cursoId}/cambiarCurso`, { nuevoCurso });
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const getEstudiantesParaAsistencia = async (cursoId: string, claseId: string): Promise<any> => {
  try {
    return http.get(`/curso/${cursoId}/estudiante?clase=${claseId}`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return [];
  }
};

export const guardarAsistencia = async (data: any) => {
  try {
    return await http.post(`/asistencia`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const getEstudiantesParaAnotacionesSinTarea = async (cursoId: string, claseId: string): Promise<any> => {
  try {
    return mobileHttp.get(`/curso/${cursoId}/clase/${claseId}/anotacion/Sin%20Tarea`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return [];
  }
};

export const guardarAnotacionesSinTarea = async (cursoId: string, claseId: string, data: any) => {
  try {
    const categoria = 'Sin Tarea';
    return await mobileHttp.post(`/curso/${cursoId}/clase/${claseId}/anotacion/${categoria}`, data);
  } catch (error) {
    console.error("[post] Error :: ", error);
    return error;
  }
};

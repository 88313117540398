import React, { forwardRef, ReactElement, useEffect, useImperativeHandle } from "react";
import {
  Button,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  FormControl,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Refresh as RefreshIcon,
} from "@material-ui/icons";
import { ReporteColumn, ReporteItem, ReporteModel, ReporteRow } from "models/reporte_model";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { parse as dateParse } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { I18n, Translate } from "react-redux-i18n";
import moment from 'moment';
import { getReporte } from "repositories/reporte_repository";
import { useIsMounted } from "hooks/useIsMounted";

const FORMAT = "dd/MM/yyyy";
const DEFAULT_ROWS_PER_PAGE = 50;
const DEFAULT_DESDE = moment().format('DD/MM/YYYY');
const DEFAULT_HASTA = moment().format('DD/MM/YYYY');

export type AnotacionesReporteSimpleRefProps = {
  refresh: () => void;
};

type Props = {
  estudiante?: string | null;
};

const AnotacionesReporteSimple = ({ estudiante }: Props, ref: React.Ref<AnotacionesReporteSimpleRefProps>): ReactElement => {
  const tipoReporte = 'KARDEX_ANOTACIONES';
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [loading, setLoading] = React.useState<boolean>(false);
  const isMounted = useIsMounted();

  const [stateFilters, setStateFilters] = React.useState<any>({});

  const [desde, setDesde] = React.useState<string | null>(DEFAULT_DESDE);
  const [hasta, setHasta] = React.useState<string | null>(DEFAULT_HASTA);

  const [rows, setRows] = React.useState<ReporteRow[]>([]);
  const [columns, setColumns] = React.useState<ReporteColumn[]>([]);
  const [count, setCount] = React.useState<number>(0);

  const cargarReporte = async (filters: any, page: number, rowsPerPage: number) => {
    setLoading(true);
    filters.estudiante = estudiante;
    const query = { _limit: rowsPerPage, _page: page + 1 }
    const fechaDesde = moment(desde, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fechaHasta = moment(hasta, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const result = await getReporte(tipoReporte, fechaDesde, fechaHasta, filters, query) as ReporteModel;
    if (isMounted()) setLoading(false);
    if (result) {
      setRows(result.rows);
      setColumns(result.columns);
      setCount(result.count);
    }
  };

  const cargadoInicial = async () => {
    setStateFilters({});
    setPage(0);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    cargarReporte({}, 0, DEFAULT_ROWS_PER_PAGE);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, [estudiante, desde, hasta]);

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback, estudiante, desde, hasta]);

  const handleRefreshReporte = () => {
    setPage(0);
    cargarReporte(stateFilters, 0, rowsPerPage);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    cargarReporte(stateFilters, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    cargarReporte(stateFilters, 0, newRowsPerPage);
  };

  const textoTraducido: any = {
    'Otros': I18n.t('categoriaAnotacion.otro'),
    'Aplicación': I18n.t('categoriaAnotacion.aplicacion'),
    'Sin Tarea': I18n.t('categoriaAnotacion.sinTarea'),
    'Conducta': I18n.t('categoriaAnotacion.conducta'),
    'Orden': I18n.t('categoriaAnotacion.orden'),
    'Atención': I18n.t('categoriaAnotacion.atencion'),
  }

  const renderText = (value: ReporteItem): ReactElement => {
    if (typeof value !== 'string') return <></>;
    return (
      <>
        {value.split('|').map((line, lineIndex) => {
          const textLabel = textoTraducido[line] || line;
          return <Typography key={lineIndex}>{textLabel}</Typography>;
        })}
      </>
    );
  };

  const refHandler = () => ({
    refresh: () => {
      cargadoInicialCallback()
    },
  });
  useImperativeHandle(ref, refHandler, [cargadoInicialCallback]);

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        {loading && <Box position="absolute" left={0} right={0}><LinearProgress color="secondary" /></Box>}
        <Box p={3} display="flex">
          <Button
            variant="contained"
            onClick={handleRefreshReporte}
            style={{ marginLeft: 10 }}
            startIcon={<RefreshIcon />}
          >
            {isDesktop && <Translate value="tabla.refrescar"/>}
          </Button>
          <Box display="flex">
            <FormControl className={classes.formControl} variant="standard">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  className={classes.input}
                  id="filtro-desde"
                  label={<Translate value="tabla.desde"/>}
                  autoOk
                  format="dd/MM/yyyy"
                  value={desde ? dateParse(desde, FORMAT, new Date()) : null}
                  inputVariant="standard"
                  onChange={(date, value) => {
                    setDesde(value || null);
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  className={classes.input}
                  id="filtro-hasta"
                  label={<Translate value="tabla.hasta"/>}
                  autoOk
                  format="dd/MM/yyyy"
                  value={hasta ? dateParse(hasta, FORMAT, new Date()) : null}
                  onChange={(date, value) => {
                    setHasta(value || null);
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Box>
        </Box>
        {(rows.length === 0 || rows === undefined || rows === null) && (
          <Typography variant="subtitle1" align="center">
            <Translate value="tabla.sinRegistrosReporte" />
          </Typography>
        )}
        {rows.length > 0 && (
          <React.Fragment>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="nro"
                      align="left"
                      style={{
                        minWidth: 60,
                        fontWeight: "bold",
                      }}
                    >
                      <Translate value="tabla.nro" />
                    </TableCell>
                    {columns.filter(c => !c.hidden).map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .map((row: ReporteRow, rowIndex: number) => {
                      let resaltado = false;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowIndex}
                        >
                          <TableCell key="nro" className={resaltado ? classes.resaltado : undefined}>
                            <Typography>
                              {rowIndex + 1 + page * rowsPerPage}
                            </Typography>
                          </TableCell>
                          {columns.filter(c => !c.hidden).map(
                            (column: ReporteColumn, colIndex: number) => {
                              return (
                                <TableCell key={colIndex} className={resaltado ? classes.resaltado : undefined}>
                                  <div>{renderText(row[colIndex])}</div>
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 50, 100, 250]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={<Translate value="tabla.filasPorPagina" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${I18n.t('tabla.de')} ${count !== -1 ? count : `${I18n.t('tabla.masDe')} ${to}`}`}
            />
          </React.Fragment>
        )}
      </Paper>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: 'relative',
    minWidth: '600px',
  },
  container: {
    // maxHeight: 440,
    // maxHeight: 'calc(100vh - 350px)',
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'left',
    height: 0,
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'height .5s, marginTop .5s',
    background: '#eee',
    paddingLeft: '16px',
  },
  filterBoxOpen: {
    overflowX: 'auto',
    height: '64px',
  },
  btnAction: {
    margin: "0 3px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  formControl: {
    width: "160px",
    padding: "0 0 0 20px",
  },
  input: {
    margin: 0,
  },
  filterItem: {
    minWidth: '190px',
    margin: '0 16px',
  },
  resaltado: {
    backgroundColor: '#ffcccc',
  },
});

export default forwardRef(AnotacionesReporteSimple);

import { TextField, Box, DialogContent, Grid, Typography, Button, DialogActions, Theme, makeStyles, createStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Translate } from 'react-redux-i18n';

type FormNewTopicProps = {
  handleClose: () => void;
  handleSaveDelay: (inHours:number, inMinutes:number, outHours:number, outMinutes:number) => void;
  inStudentDelay: number;
  outStudentDelay: number;
}

const FormLateAttendance: React.FC<FormNewTopicProps> = ({
  handleClose,
  handleSaveDelay,
  inStudentDelay,
  outStudentDelay
}) => {
  const classes = useStyles();

  const [time, setTime] = React.useState<string | null>('in');
  const [inHours, setInHours] = React.useState<number>(0);
  const [inMinutes, setInMinutes] = React.useState<number>(0);
  const [outHours, setOutHours] = React.useState<number>(0);
  const [outMinutes, setOutMinutes] = React.useState<number>(0);

  useEffect(() => {
    let tmpHours = Math.trunc(inStudentDelay / 60);
    let tmpMinutes = Math.trunc(inStudentDelay % 60);

    console.log('xxxx   tmpHours')
    console.log(tmpHours)
    console.log(tmpMinutes)

    setInHours(tmpHours);
    setInMinutes(tmpMinutes);

  }, [inStudentDelay]);

  useEffect(() => {
    let tmpHours = Math.trunc(outStudentDelay / 60);
    let tmpMinutes = Math.trunc(outStudentDelay % 60);

    setOutHours(tmpHours);
    setOutMinutes(tmpMinutes);

  }, [outStudentDelay]);

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, val: string | null) => {
    if (val === null)
      return;
    else {   
      setTime(val);
    }
  };

  const handleTimeUpdate = (delay: any | null) => {
    let data = delay.target.value.split(':')
    let hour = Number(data[0]);
    let min = Number(data[1]);

    if(time === 'in') {
      setInHours(hour);
      setInMinutes(min);
    }
    else {
      setOutHours(hour);
      setOutMinutes(min);
    } 
  };

  const saveDelay = () => {
    handleSaveDelay(inHours, inMinutes, outHours, outMinutes);
  }

  const renderTime = (hours: number, minutes: number) => {
    let hourStr = hours < 10 ? `0${hours}` : `${hours}`; 
    let minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`; 

    return `${hourStr}:${minutesStr}`;
  }

  return (
    <form>
      <DialogContent dividers>
        <Box my={3} display="flex">
          <Grid container spacing={6} justify = "center">
            <Grid item xs={12} sm={12} style={{ textAlign:'center' }}>
              <Typography gutterBottom>
                <Translate value="aContinuacionSeleccione" />
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} alignItems="center" justify="center">
              <ToggleButtonGroup
                value={time}
                exclusive
                onChange={ handleToggleChange }
                aria-label="text alignment"
              >
                <ToggleButton value="in" aria-label="left aligned">
                  <Translate value="entrada" />
                </ToggleButton>
                <ToggleButton value="out" aria-label="centered">
                  <Translate value="salida" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={12} style={{ textAlign:'center' }}>
              <Typography gutterBottom style={{  }}>
                <Translate value="seleccioneLaCantidadMinutosRetraso" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} style={{ textAlign:'center'}}>
              <TextField
                id="time"
                label={<Translate value="horaMinuto" />}
                type="time"
                defaultValue="00:00"
                value={time === 'in' ? renderTime(inHours, inMinutes) : renderTime(outHours, outMinutes)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                onChange={handleTimeUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography gutterBottom style={{  }}>
                <Translate value="totalMinutosEntrada" /> {`${inHours} `} <Translate value="horas" /> {`${inMinutes} `} <Translate value="minutos" /> 
              </Typography>
              <Typography gutterBottom style={{  }}>
                <Translate value="totalMinutosSalida" /> {`${outHours} `} <Translate value="horas" /> {`${outMinutes} `} <Translate value="minutos" />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonGroup fullWidth={true} variant="text" aria-label="text primary button group">
          <Button onClick={handleClose}>
            <Translate value="kinder.cancelar" />
          </Button>
          <Button onClick={saveDelay}>
            <Translate value="kinder.guardar" />
          </Button>
        </ButtonGroup>
      </DialogActions>
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
    stepRoot: {
      display: 'flex',
      justifyContent: 'center',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: theme.palette.primary.main
    },
    activeStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%'
    },
    inactiveStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.grey[400],
      borderRadius: '50%'
    },
    label: {
      fontWeight: theme.typography.fontWeightBold as any,
      color: theme.palette.common.black
    },
    input: {
      margin: 0,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 100
    },
  })
);

export default FormLateAttendance;
import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { cambiarContrasenia } from 'store/actions/user';
import { useDispatch } from 'react-redux';

type State = {
    password: string;
    passwordConfirm: string;
    showPassword: boolean;
    showPasswordConfirm: boolean;
};

type DialogProps = {
    openDialog: boolean;
    handleDialogClose: () => void;
};

const FormChangeDialog = ({ openDialog, handleDialogClose }: DialogProps) => {
    const classes = useStyles();
    // const notify = useNotify();
    const [values, setValues] = React.useState<State>({
        password: '',
        passwordConfirm: '',
        showPassword: false,
        showPasswordConfirm: false,
    });
    const [isDirty, setIsDirty] = useState(false);
    const [error,] = useState<any>('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const showPasswordError = isDirty && values.password === '';

    const showPasswordConfirmError =
        isDirty && (values.passwordConfirm === '' || values.password !== values.passwordConfirm);

    const handleInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
        // setIsDirty(true);
    };

    const handleInputChange2 = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
        // setIsDirty(true);
    };

    const handleShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleShowPasswordConfirm = () => {
        setValues({ ...values, showPasswordConfirm: !values.showPasswordConfirm });
    };

    const handleGuardar = () => {
        console.log(' >>> handleGuardar');
        if (isLoading) return;

        if (values.password !== values.passwordConfirm) {
            setIsDirty(true);
            return;
        }

        setIsLoading(true);
        // alert('guardar');
        const onComplete = (success: boolean) => {
            console.log('complete??? success = ', success);
            setIsLoading(false);
            if (!success) {
                return;
            }
            setValues({
                password: '',
                passwordConfirm: '',
                showPassword: false,
                showPasswordConfirm: false,
            });
            setIsDirty(false);
            handleDialogClose();

        }
        dispatch(cambiarContrasenia(values.password, onComplete));
    };

    return (
        <form>
            <Dialog open={openDialog} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Restablecimiento de contraseña</DialogTitle>
                <DialogContent dividers>
                    <Box className={classes.inputbox}>
                        <>
                            <Typography variant="subtitle2">Crear nueva contraseña.</Typography>
                            <FormControl className={classes.margin} variant="outlined" error={showPasswordError}>
                                <InputLabel htmlFor="outlined-adornment-password">
                                    Contraseña *
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleInputChange('password')}
                                    required
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleShowPassword}
                                                className={showPasswordError ? classes.error : ''}
                                                edge="end"
                                            >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={100}
                                />
                                {showPasswordError ? (
                                    <FormHelperText id="component-error-text">El campo es obligatorio *</FormHelperText>
                                ) : (
                                    ''
                                )}
                            </FormControl>
                            <FormControl className={classes.margin} variant="outlined" error={showPasswordConfirmError}>
                                <InputLabel htmlFor="outlined-adornment-password-confirm">
                                    Confirmar Contraseña *
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password-confirm"
                                    type={values.showPasswordConfirm ? 'text' : 'password'}
                                    value={values.passwordConfirm}
                                    onChange={handleInputChange2('passwordConfirm')}
                                    required
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle passwordConfirm visibility"
                                                onClick={handleShowPasswordConfirm}
                                                className={showPasswordConfirmError ? classes.error : ''}
                                                edge="end"
                                            >
                                                {values.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={170}
                                />
                                {showPasswordConfirmError ? (
                                    <FormHelperText id="component-error-text-password">
                                        El campo no coincide y es obligatorio *
                                    </FormHelperText>
                                ) : (
                                    ''
                                )}
                            </FormControl>
                            <Box mt={1}>
                                {error && (
                                    <Typography variant="caption" className={classes.error}>
                                        {error.message}
                                    </Typography>
                                )}
                            </Box>
                        </>
                    </Box>
                </DialogContent>
                <DialogActions className={classes.btnFooter}>
                    <Button variant="contained" color="default" onClick={() => handleDialogClose()}>
                        <Box display="flex" alignItems="center" width="30px">
                            <CloseIcon fontSize="small" className={classes.actionIcon} />
                        </Box>
                        Cerrar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleGuardar()}
                        disabled={showPasswordError || showPasswordConfirmError || values.password === ''}
                    >
                        <Box display="flex" alignItems="center" width="30px">
                            {isLoading && <CircularProgress size={18} color="inherit" />}
                            {!isLoading && <CheckIcon fontSize="small" className={classes.actionIcon} />}
                        </Box>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    btnGuardar: {
        minWidth: theme.spacing(16),
    },
    btnCerrar: {
        minWidth: theme.spacing(16),
    },
    btnFooter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: theme.spacing(2, 2),
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        margin: 'auto',
    },
    inputbox: {
        borderRadius: 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '380px',
    },
    error: {
        color: theme.palette.error.main,
    },
    margin: {
        margin: theme.spacing(2, 0, 0, 0),
    },
    actionIcon: {
        margin: theme.spacing(0, 0.5, -0.5, 0),
    },
}));

export default FormChangeDialog;

import { createMuiTheme } from '@material-ui/core/styles'
import { esES } from '@material-ui/core/locale';

// import PoppinsRegular from '../assets/fonts/Poppins-Regular.ttf';
// const _poppins = {
//   fontFamily: 'Poppins',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 400,
//   src: `
//     local('Poppins'),
//     local('Poppins-Regular'),
//     url(${PoppinsRegular}) format('ttf')
//   `,
//   unicodeRange:
//     'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Poppins, Arial'
  },
  overrides: {
    MuiMenu: {
      paper: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#FAE268',
      }
    },
    MuiMenuItem: {
      root: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: '#FAE268',
      }
    },
    MuiFilledInput: {
      root: {
        // backgroundColor: '#faffacff',
        backgroundColor: '#ffFFFDEF',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#FAE268',
        "&:hover": {
          backgroundColor: '#ffFFFDEF',
          borderBottom: 'none',
        },
        "&.Mui-disabled": {
          backgroundColor: '#FFF7B0',
          // backgroundColor: '#faffacff',
          // backgroundColor: '#ffFFFDEF',
          // color: '#323232ff',
          // fontWeight: 'bold'
        }
      },
      underline: {
        "&:hover": {
          borderBottom: "1px solid transparent",
        },
        "&:before": {
          borderBottom: '1px solid transparent',
        },
        "&:after": {
          borderBottom: '1px solid transparent',
        }
        // borderBottom: 'none'
      }
    },
    // MuiTextField: {
    //   root: {
    //     fontSize: 76
    //   }
    // },
    // MuiInputBase: {
    //   root: {
    //     borderBottom: '0',
    //   }
    // },
    MuiInputLabel: {
      filled: {
        color: '#323232ff',
        // borderBottom: 'none',
        // transform: "translate(12px, 10px) scale(0.75)",
        // "&$marginDense": {
        //   transform: "translate(12px, 7px) scale(0.95)"
        // },
        "&$focused": {
          color: '#323232ff',
        },
      },
      // outline: {
      //   transform: "translate(14px, -6px) scale(0.75)"
      // }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': {
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontDisplay: 'swap',
          fontWeight: 400,
          src: `
            local('Poppins'),
            local('Poppins-Regular'),
            url(${require('../../assets/fonts/Poppins-Regular.ttf')}) format('ttf')
          `,
        },
      }
    }
  },
  palette: {
    primary: {
      main: '#FAE268',
      light: '#FFF7B0',
      "200": '#ffFFFDEF',
    },
    secondary: {
      main: '#CCDCC0',
    },
    success: {
      // main: '#b2d9adff',
      main: '#89c480ff',
      light: '#c5e2bfff',
    },
    warning: {
      main: '#f4ab84ff',
    },
    error: {
      main: '#fe8f86ff',
      light: '#ffc9c2ff',
    },
    common: {
      black: '#323232ff',
      white: '#ffFFFFFF',
    }
  },
}, esES );

export default theme;
import http from "./http";

const buildQueryFilters = (filters: any): string[] => {
  return Object.keys(filters)
    .map((key) => {
      const filterValue = filters[key];
      return Array.isArray(filterValue)
        ? filterValue.map((val) => `${key}=${encodeURIComponent(val)}`).join('&')
        : `${key}=${encodeURIComponent(filterValue)}`;
    });
};

function buildQueryParamsText(filters: any = {}): string {
  console.log("buildQueryParamsText")
  const params = filters ? buildQueryFilters(filters) : [];
  const queryParamsText = params.length > 0 ? `?${params.join('&')}` : '';
  console.log("queryParamsText: ", queryParamsText)
  return queryParamsText;
}

export const getReporte = async (tipoReporte?: string, desde?: string | null, hasta?: string | null, filtros?: any, query?: any) => {
  try {
    const verJefe = filtros ? !!filtros.verJefe : false;
    if (filtros) delete filtros.verJefe;
    const params = {
      desde: desde || '',
      hasta: hasta || '',
      consulta: JSON.stringify(filtros || {}),
      verJefe,
      ...query,
    };
    const consulta = buildQueryParamsText(params);
    return http.get(`/app/reporte/generico/${tipoReporte}${consulta}`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return null;
  }
};

export const downloadReporteExcel = async (tipoReporte?: string, desde?: string | null, hasta?: string | null, filtros?: any, filename?: string) => {
  try {
    const verJefe = filtros ? !!filtros.verJefe : false;
    if (filtros) delete filtros.verJefe;
    const params = {
      desde: desde || '',
      hasta: hasta || '',
      consulta: JSON.stringify(filtros || {}),
      verJefe
    };
    const consulta = buildQueryParamsText(params);
    filename = filename || getFileName("Colegio Alemán", tipoReporte);
    return http.download(`/app/reporte/generico/${tipoReporte}/excel${consulta}`, filename);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return null;
  }
};

export const downloadReportePDF = async (tipoReporte?: string, desde?: string | null, hasta?: string | null, filtros?: any, filename?: string) => {
  try {
    const verJefe = filtros ? !!filtros.verJefe : false;
    if (filtros) delete filtros.verJefe;
    const params = {
      desde: desde || '',
      hasta: hasta || '',
      consulta: JSON.stringify(filtros || {}),
      verJefe
    };
    const consulta = buildQueryParamsText(params);
    filename = filename || getFileName("Colegio Alemán", tipoReporte);
    return http.download(`/app/reporte/generico/${tipoReporte}/pdf${consulta}`, filename);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return null;
  }
};

export const obtenerKardexListadoEstudiantes = async (tipoReporte = 'KARDEX_LISTADO_ESTUDIANTES', desde?: string | null, hasta?: string | null, filtros?: any) => {
  console.log("obtenerKardexListadoEstudiantes");
  try {
    const params = {
      desde: desde || '',
      hasta: hasta || '',
      consulta: JSON.stringify(filtros || {}),
    };
    const consulta = buildQueryParamsText(params);
    return http.get(`/app/reporte/generico/${tipoReporte}${consulta}`);
  } catch (error) {
    console.error("[buscar] Error :: ", error);
    return null;
  }
};

export const obtenerKardexReporteEstudiante = async (tipoReporte = 'KARDEX_REPORTE_ESTUDIANTE', desde?: string | null, hasta?: string | null, filtros?: any) => {
  try {
    const params = {
      desde: desde || '',
      hasta: hasta || '',
      consulta: JSON.stringify(filtros || {}),
    };
    const consulta = buildQueryParamsText(params);
    return http.get(`/app/reporte/generico/${tipoReporte}${consulta}`);
  } catch (error) {
    console.error("[reporte] Error :: ", error);
    return null;
  }
};

function normalizeText(text: string) {
  let result = text.replace(/^\s+|\s+$/g, '').toLowerCase();
  const from = 'ãàáäâèéëêìíïîõòóöôùúüûç·/_,:;';
  const to = 'aaaaaeeeeiiiiooooouuuuc------';
  for (let i = 0; i < from.length; i++) {
      result = result.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  return result
      .replace(/[^a-z0-9ñ -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
}

function getFileName(title: string, subtitle?: string) {
  const primaryTitle = `${normalizeText(title)}_`;
  const secondaryTitle = subtitle ? `${normalizeText(subtitle)}_` : '';
  const date = Date.now();
  const filename = `${primaryTitle}${secondaryTitle}${date}`;
  return filename;
}

import { COURSE_BOOK_TYPES, Action } from "store/types";
import { LibroDeCurso } from "../../models/libro_de_curso_model";
import { LibroDeCursoTemaForm } from "models/libro_de_curso_tema_form_model";
import { LibroDeCursoCirculoDeColoresForm } from "models/libro_de_curso_circulo_de_colores_form_model";

export type State = {
    libroDeCurso: LibroDeCurso | null;
    courseTopicForm: LibroDeCursoTemaForm | null;
    circleOfColorsForm : LibroDeCursoCirculoDeColoresForm | null;

};

const initialState: State = {
    libroDeCurso: null,
    courseTopicForm: null,
    circleOfColorsForm: null
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
    switch (type) {
      case COURSE_BOOK_TYPES.GET_COURSE_BOOK:
        return {
          ...state,
          libroDeCurso: payload,
        };
      case COURSE_BOOK_TYPES.SET_COURSE_BOOK_TOPIC_FORM:
        return {
          ...state,
          courseTopicForm: {
            ...state.courseTopicForm,
            ...payload
          }
        }
      case COURSE_BOOK_TYPES.SET_CIRCLE_OF_COLORS_FORM:
        return {
          ...state,
          circleOfColorsForm: {
            ...state.circleOfColorsForm,
            ...payload
          }
        }
      default:
        return state;
    }
  };
  
  export default reducer;
  
import PropTypes from 'prop-types';
import Event from "./components/Event";
import SelectedEvent from "./components/SelectedEvent";
import '../styles.css';

const CalendarEvent = ({ selectedDate }:any) => (props:any) => {
  
  const renderEvent = () => {
    if(selectedDate && props.event.start.isSame(selectedDate, 'day'))
      return <SelectedEvent event={props.event}/>;
    else
      return <Event event={props.event}/>;
  }

  return renderEvent();
}
  
CalendarEvent.propTypes = {
    event: PropTypes.object
}

export default CalendarEvent;
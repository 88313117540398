import { Box, createStyles, Divider, Grid, IconButton, makeStyles, TextField, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import {Delete as DeleteIcon, AddCircle as AddIcon} from '@material-ui/icons';
import { Activity } from "models/libro_de_curso_grid_activities_model";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Translate } from "react-redux-i18n";
import SkillsRow from "./SkillsRow";

// type FormStepOneProps = {
//   control:any;
//   register:any;
//   setValue: any;
//   getValues:any
// }

const ActivityRow: React.FC<any> = ({ control, register, setValue, getValues, availableSkills, gridType, activities }) => {
  const classes = useStyles();
  const [skills, setSkills] = useState([]); 
  const theme = useTheme();
  const { fields, append, remove } = useFieldArray({ 
    control,
    name: "actividades"
  });

  // useEffect(() => {
  //   console.log('get val')
  //   console.log(getValues());
  // });

  const handleSetSkills = (arr:any) => {
    setSkills(arr);
  }

  useEffect(() => {
    handleSetSkills(availableSkills);
  }, []);

  const loadGrid = () => {

    let formData  = activities.map((el:any) => {

      let activity:Activity = {
        nombre: el.nombre,
        categoria: el.categoria,
        competencias: el.competencias
          .filter((item:any)=>{
            if(item.id)
              return item.id;
          })
          .map((item2:any) => {
            if(item2)
              return {val: item2.id};
          })
      };
      return activity;
    })
    
    return formData;
  }

  useEffect(() => {
    setTimeout(() => {
      if (activities) {
        
        let data = loadGrid();
        

        if(data.length>0){
          setValue('actividades', data);
        }
          

      }
    }, 200);
  }, [activities]);

  return (
    <>
      <Grid container>
        {fields.map((item:any, index) => {
          //console.log('Padre item es')
          //console.log(item)

          return (
            <Grid item container key={item.id}>
              <Tooltip arrow title={
                <Typography>
                  <Translate value={getValues(`actividades[${index}].nombre`) ? getValues(`actividades[${index}].nombre`) : ''}/>
                </Typography>}
              >
                <Grid item xs={4}>
                  <IconButton size='small' onClick={() => remove(index)} style={{ color: theme.palette.common.black }}>
                    <DeleteIcon />
                  </IconButton>
                  <Controller
                    name={`actividades[${index}].nombre`}
                    control={control}
                    defaultValue={item.nombre}
                    render={({ field }) =>
                        <TextField
                          type='textarea'
                          required
                          multiline
                          rows={3}
                          rowsMax={7}
                          inputProps={{step: 1, min: 0, max: 6}}
                          {...field}
                          //className={classes.formControl}
                          style={{margin: theme.spacing(1), width: "85%"}}
                          variant='filled'
                          label={<Translate value="kinder.nombreActividad"/>}
                        />
                    }
                  />
                </Grid>
              </Tooltip>
              <Grid item xs={8}>
                <SkillsRow availableSkills={skills} nestIndex={index} {...{ control, register }} />
              </Grid>
              <Box style={{width: '100%'}}>
                <Divider variant="middle" className={classes.rowDivider}/>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <section>
        <Grid item container xs={12} alignItems="center" justify="center">
          <IconButton size='small' onClick={() => append({
            nombre: '',
            categoria: gridType,
            competencias: [{val: ''}, {val: ''}, {val: ''}]
          })} style={{ color: theme.palette.success.main }}>
            <AddIcon fontSize='large' />
          </IconButton>
        </Grid>

        {/* <button
          type="button"
          onClick={() => {
            setValue("actividades", [
              ...getValues().actividades,
              {
                name: "append",
                competencias: ["", "", ""]
              }
            ]);
          }}
        >
          Append Nested
        </button> */}
      </section>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowDivider: {
      marginTop: 20, 
      backgroundColor: '#202020'
    }
  })
);

export default ActivityRow;
import http from "./http";
//import { LibroDeCurso } from '../models/libro_de_curso_model';

export const getCourseBookByDate = async (week?: number, year?: number) => {
  try {
    let filters = [];

    if (week) {
      filters.push(`semana=${week}`)
    }

    if (year) {
      filters.push(`anio=${year}`)
    }

    const items = await http.get(`/kinder/libro-curso/${filters.length ? ('?' + filters.join('&')) : ''}`);
    
    return items;
  } catch (error) {
    console.error("[get_libro_de_curso]  Error -> ", error);
    return error;
  }
};

export const saveActivities = async (data:any, fecha:any) => {
  try {

    const res = await http.post(`/kinder/actividades/${fecha ? fecha : ''}`, data);
    
    return res;
  } catch (error) {
    console.error("[save_activities_libro_de_curso]  Error -> ", error);
    return error;
  }
};
import http from "./http";

export const getAllData = async (module: string) => {
  try {
    return await http.get(`/${module}/`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return error;
  }
};

export const getByIdData = async (module: string, id: string) => {
  try {
    return await http.get(`/${module}/${id}`);
  } catch (error) {
    console.error('[get_by_id] error :: ', error);
    return error;
  }
}

export const createData = async (module: string, data: any) => {
  try {
    return await http.post(`/${module}`, data);
  } catch (error) {
    console.error("[create] Error :: ", error);
    return error;
  }
};

export const editData = async (module: string, data: any, id: string) => {
  try {
    return await http.put(`/${module}/${id}`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const deleteData = async (module: string, id: string) => {
  try {
    return await http.delete(`/${module}/${id}`);
  } catch (error) {
    console.error("[delete] Error :: ", error);
    return error;
  }
};


const CalendarEventWrapper  = (eventWrapperProps: any) => {
  const style = {
    border: 'none'
  };

  return (
    <div style={style}>
      {eventWrapperProps.children}
    </div>
  );
};

export default CalendarEventWrapper;
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Paper,
  Box,
  Button,
} from "@material-ui/core";
import React, { useRef } from "react";
import MainLayout from "../../components/layout/MainLayout";
import { Translate } from "react-redux-i18n";
import NotificationTable, { NotificacionesRefProps } from "./components/NotificationTable";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import { Notificacion } from "../../models/notificacion_model";
import FormNotificacion from "./components/FormNotificacion";
import message from "../../plugins/utils/message";
import { createNotificacion } from "repositories/notificacion_repository";

type ReportProps = {
  loginStatus?: string;
};

const Notifications: React.FC<ReportProps> = ({ loginStatus }) => {
  const classes = useStyles();

  const notificacionesRef = useRef<NotificacionesRefProps>(null);
  const [modalFormNotificacion, setModalFormNotificacion] = React.useState(false);
  const [notificacion, setNotificacion] = React.useState<Notificacion | null>(null);

  const [reporteActual, setEstadoActual] = React.useState(
    "enviadas"
  );

  const handleGetPermisos = (estado: string) => {
    setEstadoActual(estado);
  };

  const handleClickAddNotification = () => {
    setNotificacion(null);
    setModalFormNotificacion(true);
  }

  const handleClose = () => {
    setNotificacion(null);
    setModalFormNotificacion(false);
  }

  const handleSaveNotificacion = async (data: Partial<Notificacion>) => {
    const datosGuardar: Partial<Notificacion> = {
      ...data,
    };

    message.success('Guardando registro');

    const success = await createNotificacion(datosGuardar as Notificacion);

    notificacionesRef.current?.refresh()

    if (success) {
      handleClose()
    }
  }

  return (
    <MainLayout>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="end"
        justifyContent="center"
      >
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="right">
          <Box ml={1}>
            <Button
              variant="contained"
              onClick={handleClickAddNotification}
              style={{ height: "40px" }}
              startIcon={<AddIcon />}
              color="secondary"
            >
              <Translate value="notificacion" />
            </Button>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={3}>
        <div className={classes.box}>
          <Box>
            <button
              type="button"
              onClick={() => handleGetPermisos("enviadas")}
              className={clsx(classes.boxItem, {
                [classes.boxItemSelected]:
                  reporteActual === "enviadas",
              })}
            >
              <Translate value="notificaciones.enviadas" />
            </button>
          </Box>
        </div>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <React.Fragment>
            <Paper className={classes.root}>
              {reporteActual === "enviadas" && (
                <NotificationTable ref={notificacionesRef} tipoReporte="enviadas" />
              )}
            </Paper>
          </React.Fragment>
        </Grid>
      </Grid>
      {modalFormNotificacion && (
        <FormNotificacion handleClose={handleClose} notificacion={notificacion} handleSave={handleSaveNotificacion} isModalOpen={modalFormNotificacion} />
      )}
    </MainLayout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
    },
    box: {
      background: "#FFFFFF",
      border: "1px solid #FAE268",
      boxSizing: "border-box",
      borderRadius: "6px",
      marginTop: "15px",
      display: 'flex',
      margin: theme.spacing(0, 1, 1, 1),
      borderRight: "1px solid #AAAAAA",
    },
    boxItem: {
      padding: "8px 16px",
      background: "transparent",
      border: "none",
      borderBottom: "1px solid #AAAAAA",
      borderLeft: "1px solid #AAAAAA",
      width: "220px",
      height: '50px',
      textAlign: "center",
      cursor: "pointer"
    },
    boxItemSelected: {
      fontWeight: "bold",
      backgroundColor: "#fff7b080",
    },
    boxTitle: {
      fontSize: "1rem",
      margin: "10px 0",
    },
  })
);

export default Notifications;

import { Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { RootState, useStateSelector } from "store/reducers";
import { Estudiante } from "../../../../models/estudiante_model";
import { setFormEstudiante } from "../../../../store/actions/estudiante_actions";
import { StepCurso } from "./components/StepCurso";
import { StepEstudiante } from "./components/StepEstudiante";
import { StepPadre } from "./components/StepPadre";


type FormCourseProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: any) => void;
  estudiante: Estudiante | null;
}

const ModalEstudiante: React.FC<FormCourseProps> = ({
  isModalOpen,
  handleSave,
  handleClose,
  estudiante,
}) => {

  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const [imgEstudiante, setImgEstudiante] = useState<File | null>(null);
  const { formEstudiante } = useStateSelector((state: RootState) => state.estudianteState);

  function saveFormEstudiante(data: any) {
    const _data = {
      ...formEstudiante,
      codigoRude: data.codigoRude,
      codigoEstudiante: data.codigoEstudiante,
      hijo: { ...data },
    };
    dispatch(setFormEstudiante(_data));
    return _data;
  }

  function saveFormPadre(data: any) {
    const _data = {
      ...formEstudiante,

      padre: Object.keys(data)
        .filter((k) => !k.startsWith("padre2_"))
        .reduce((prev, curr) => {
          prev[curr] = data[curr];
          return prev;
        }, {} as any),

      padre2: Object.keys(data)
        .filter((k) => k.startsWith("padre2_"))
        .reduce((prev, curr) => {
          prev[curr.replace("padre2_", "")] = data[curr];
          return prev;
        }, {} as any),
      familiar: Object.keys(data)
        .filter((k) => k.startsWith("familiar_"))
        .reduce((prev, curr) => {
          prev[curr.replace("familiar_", "")] = data[curr];
          return prev;
        }, {} as any)
    };
    dispatch(setFormEstudiante(_data));
    return _data;
  }

  function saveFormCurso(data: any) {
    const _data = {
      ...formEstudiante,
      cursoId: data?.cursoId,
    };
    dispatch(setFormEstudiante(_data));
    return _data;
  }

  const handleSaveStepEstudiante = (data: any) => {
    setImgEstudiante(data.imagenData);
    saveFormEstudiante(data);
    setStep(step + 1);
  }

  const handleSaveStepPadre = (data: any) => {
    saveFormPadre(data);
    setStep(step + 1);
  }

  const handleSaveStepCurso = (data: any) => {
    const _data = saveFormCurso(data);
    handleSave({ ..._data, imgEstudiante });
  }

  const handleBackPadre = (data: any) => {
    saveFormPadre(data);
    setStep(step - 1);
  }

  const handleBackCurso = (data: any) => {
    saveFormCurso(data);
    setStep(step - 1);
  }

  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      fullWidth
      maxWidth="md"
      disableBackdropClick
    >
      <DialogTitle>
        <Grid container justify="space-between">
          {estudiante ? 'Editar estudiante' : 'Crear nuevo estudiante'}
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      {step === 0 && (
        <StepEstudiante handleClose={handleClose} handleSave={handleSaveStepEstudiante} />
      )}
      {step === 1 && (
        <StepPadre handleClose={handleClose} handleSave={handleSaveStepPadre} handleBack={handleBackPadre} />
      )}
      {step === 2 && (
        <StepCurso handleClose={handleClose} handleSave={handleSaveStepCurso} handleBack={handleBackCurso} />
      )}
    </Dialog>
  );

}

export default ModalEstudiante;
import { Box, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { Translate } from "react-redux-i18n";
import { ReporteEstudiante } from "..";

type Props = {
  reporteEstudiante: ReporteEstudiante | null;
};

export const KinderTimeCounter: React.FC<Props> = ({ reporteEstudiante }) => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.root} mt={1} justifyContent="center" alignItems="center">
      <Paper elevation={3}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ border: '1px solid green', color: 'green', height: '100px', width: '220px' }}>
          <Typography align="center" variant="h6">
            {reporteEstudiante?.datosGenerales.datosKinder.faltasConLicencia}
          </Typography>
          <Typography variant="body2" align="center">
            <Translate value="tabla.faltasConLicencia" />
          </Typography>
        </Box>
      </Paper>
      <Paper elevation={3}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ border: '1px solid orange', color: 'orange', height: '100px', width: '220px' }}>
          <Typography align="center" variant="h6">
            {reporteEstudiante?.datosGenerales.datosKinder.retrasos}
          </Typography>
          <Typography variant="body2" align="center">
            <Translate value="tabla.atrasos" />
          </Typography>
        </Box>
      </Paper>
      <Paper elevation={3}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ border: '1px solid red', color: 'red', height: '100px', width: '220px' }}>
          <Typography align="center" variant="h6">
            {reporteEstudiante?.datosGenerales.datosKinder.faltasSinLicencia}
          </Typography>
          <Typography variant="body2" align="center">
            <Translate value="tabla.faltasSinLicencia" />
          </Typography>
        </Box>
      </Paper>
      <Paper elevation={3}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ border: '1px solid orange', color: 'orange', height: '100px', width: '220px' }}>
          <Typography align="center" variant="h6">
            {`${Math.floor(reporteEstudiante?.datosGenerales.datosKinder.minutosAcumulados / 60)}hr ${reporteEstudiante?.datosGenerales.datosKinder.minutosAcumulados % 60}min`}
          </Typography>
          <Typography variant="body2" align="center">
            <Translate value="tabla.minutosAcumulados" />
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3, 0, 0, 2),
    padding: theme.spacing(0),
    backgroundColor: 'transparent',
    // minWidth: '100%',
    // width: '100%'
  }
}));

import React, { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, LinearProgress } from '@material-ui/core';
import { useIsMounted } from 'hooks/useIsMounted';
import { obtenerKardexListadoEstudiantes } from 'repositories/reporte_repository';
import VirtualizeMultiple, { SelectOption } from './VirtualizeMultiple';

type EstudianteOption = {
    id: string;
    nombre: string;
    codigoCurso: string;
};

type ActionSearchProps = {
    onSearchClick: (estudianteId: string[]) => Promise<void>;
    value?: string[];
    multiple?: boolean;
};

export const BuscadorMultiple = (props: ActionSearchProps): ReactElement => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState<boolean>(false);
    const isMounted = useIsMounted();

    const [estudianteOptions, setEstudianteOptions] = React.useState<SelectOption[]>([]);
    const [itemSelected, setItemSelected] = React.useState<string[] | undefined>(typeof props.value === 'undefined' ? undefined : []);

    const cargarOpciones = async () => {
        const estudiantesResponse: any = await obtenerKardexListadoEstudiantes();
        const listado = estudiantesResponse.info as EstudianteOption[];
        const estudianteOptions: SelectOption[] = (listado || []).map((item) => ({
            value: item.id || '',
            label: `${item.nombre} - ${item.codigoCurso}`,
        }));
        setEstudianteOptions(estudianteOptions);
    };
    const cargarOpcionesCallback = React.useCallback(cargarOpciones, []);

    React.useEffect(() => {
        cargarOpcionesCallback();
    }, [cargarOpcionesCallback]);

    React.useEffect(() => {
        if (typeof props.value !== 'undefined') {
            const itemSelected = estudianteOptions.filter(opt => props.value?.some(val => val === opt.value));
            setItemSelected(itemSelected.map(item => item.label));
        }
    }, [estudianteOptions, props.value]);

    const handleOnChange = async (value: any) => {
        if (loading) return;
        setLoading(true);
        await props.onSearchClick(value);
        if (!isMounted()) return;
        setLoading(false);
    };

    const handleChange = (value: SelectOption[]) => {
        const newValue = value || [];
        handleOnChange(newValue.map(val => val.value));
    };

    return (
        <Box className={classes.root}>
            <Box width="100%" maxWidth="500px" position="relative">
                <LinearProgress className={classes.loader} color="secondary" variant="indeterminate" hidden={!loading} />
                <VirtualizeMultiple options={estudianteOptions} onChange={handleChange} loading={loading} value={itemSelected} />
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '350px',
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        height: '4px',
        background: '#e0e0e0',
        zIndex: 9,
    },
}));

import { EVENTO_TYPES, Action } from "../types";
import { Evento } from "../../models/evento_model";

export type State = {
  eventos: Evento[] | [];
  evento: Evento | null;
};

const initialState: State = {
  eventos: [],
  evento: null,
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case EVENTO_TYPES.CARGAR_EVENTOS:
      return {
        ...state,
        eventos: payload,
      };
    case EVENTO_TYPES.SET_EVENTO:
      return {
        ...state,
        evento: payload
      }
    default:
      return state;
  }
};

export default reducer;

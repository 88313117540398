import http from "./http";
import moment from "moment";
import { AsistenciaKinder } from "models/asistencia_kinder_model";

export const getKinderAssistanceByDate = async (date?:moment.Moment ) => {
  try {
    let filters = [];

    if (date) {
      filters.push(`fecha=${moment(date).format('YYYY-MM-DD')}`)
    }

    const items = await http.get(`/kinder/asistencia${filters.length ? ('?' + filters.join('&')) : ''}`);

    return items;
  } catch (error) {
    console.error("[get_asistencia]  Error -> ", error);
    return error;
  }
};

export const saveAssistance = async (data: AsistenciaKinder, date?:string) => {
  try {

    let filters = [];

    if (date) {
      filters.push(`fecha=${moment(date).format('YYYY-MM-DD')}`)
    }

    return await http.post(`/kinder/asistencia${filters.length ? ('?' + filters.join('&')) : ''}`, data);
  } catch (error) {
    console.error("[create] POST asistencia Error :: ", error);
    return error;
  }
};
import { REPORTE_TYPES, Action } from "../types";
import { ReporteModel } from "../../models/reporte_model";

export type State = ReporteModel;

const initialState: State = {
  rows: [],
  columns: [],
  count: 0,
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case REPORTE_TYPES.CARGAR_REPORTE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default reducer;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  TextField,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { CursoItem, NivelItem, Notificacion } from "models/notificacion_model";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { cargarEstudiantes } from "store/actions/estudiante_actions";
import { Translate, I18n } from "react-redux-i18n";
import { BuscadorNivelesMultiple } from "./BuscadorNivelesMultiple";
import { BuscadorCursosMultiple } from "./BuscadorCursosMultiple";

type FormNotificacionData = {
  id?: string;
  asunto: string;
  nivel: NivelItem[];
  curso: CursoItem[];
  mensaje: string;
};

type FormNotificacionProps = {
  notificacion: Notificacion | null;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: Partial<Notificacion>) => void;
};

const FormNotificacion: React.FC<FormNotificacionProps> = ({
  isModalOpen,
  handleClose,
  handleSave,
}) => {
  const { handleSubmit, control } = useForm<FormNotificacionData>({
    shouldUnregister: false,
  });

  const [niveles, setNiveles] = React.useState<NivelItem[] | null>(null);
  const [cursos, setCursos] = React.useState<CursoItem[] | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cargarEstudiantes());
  }, [dispatch]);

  const classes = useStyles();

  const onSubmit = (data: FormNotificacionData) => {
    handleSave({
      asunto: data.asunto,
      nivel: niveles || undefined,
      curso: cursos || undefined,
      mensaje: data.mensaje,
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Box display={"flex"} flexDirection={"row"} justifyContent={'space-between'}>
          <Box>
            <Translate value="enviarNotificacion" />
          </Box>
          <Box ml={2}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="asunto"
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <TextField
                      variant='filled'
                      fullWidth
                      required
                      label='Escribe aquí el asunto...'
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BuscadorNivelesMultiple
                  onSearchClick={async (nivel) => {
                    setNiveles(nivel)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BuscadorCursosMultiple
                  nivelSeleccionado={niveles}
                  onSearchClick={async (cursosId) => {
                    setCursos(cursosId)
                  }}
                />
              </Grid>
            </Grid>
            <Box m={2} />
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              <Translate value="form.descripcion" />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="mensaje"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      rows={6}
                      multiline
                      label={I18n.t("form.escribaMasInformacion")}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.btnError} fullWidth>
            <Translate value="form.cancelar" />
          </Button>
          <Button type="submit" className={classes.btnSave} fullWidth>
            <Typography variant="body2" noWrap>
              <Translate value="form.enviar" />
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      // margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
  })
);

export default FormNotificacion;

import { LibroDeCurso } from "models/libro_de_curso_model";
import { LibroDeCursoTemaForm } from "models/libro_de_curso_tema_form_model";
import { getCourseBookByDate, saveActivities } from '../../repositories/libro_de_curso_repository';
import { COURSE_BOOK_TYPES } from "store/types";
import { createTopic } from "repositories/libro_de_curso_tema_repository";
import { ActivitiesData } from "models/libro_de_curso_grid_activities_model";
import { LibroDeCursoCirculoDeColoresForm } from "models/libro_de_curso_circulo_de_colores_form_model";
import { saveCircleOfColors } from "repositories/libro_de_curso_circulo_de_colores_repository";

export const cargarLibroDeCurso = (semana?:number, anio?:number ) => {
  return async (dispatch: any) => {
    const libroDeCurso: any = await getCourseBookByDate(semana, anio);
    
    dispatch( setLibroDeCurso(libroDeCurso || {}) );
  }
};
    
export const setLibroDeCurso = (libroDeCurso:LibroDeCurso) => ({
  type: COURSE_BOOK_TYPES.GET_COURSE_BOOK,
  payload: {...libroDeCurso}
});

export const setCourseTopicForm = (value: any) => ({
  type: COURSE_BOOK_TYPES.SET_COURSE_BOOK_TOPIC_FORM,
  payload: {...value}
});

export const addTopic = (data: LibroDeCursoTemaForm) => {
  return async (dispatch: any) => {
    await createTopic(data);
  }
}

export const addActivities = (data: ActivitiesData, fecha:string) => {
  return async (dispatch: any) => {
    await saveActivities(data, fecha);
  }
}

export const setCircleOfColorsForm = (value: any) => ({
  type: COURSE_BOOK_TYPES.SET_CIRCLE_OF_COLORS_FORM,
  payload: {...value}
});

export const addCircleOfColors = (data: LibroDeCursoCirculoDeColoresForm) => {
  return async (dispatch: any) => {
    await saveCircleOfColors(data);
  }
}
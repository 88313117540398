import React, { useRef } from "react";
import { Box, Button, Theme } from "@material-ui/core";
import MainLayout from "../../components/layout/MainLayout";
import { makeStyles } from "@material-ui/styles";
import { Buscador } from "./components/Buscador";
import { obtenerKardexReporteEstudiante } from "repositories/reporte_repository";
import { DatosGenerales } from "./components/DatosGenerales";
import { Asistencia } from "./components/Asistencias";
import { Anotaciones, AnotacionesRefProps } from "./components/Anotaciones";
import AddIcon from "@material-ui/icons/Add";
import FormAnotacion from "./components/FormAnotacion";
import { Anotacion } from "../../models/anotacion_model";
import message from "../../plugins/utils/message";
import { createAnotacion, editAnotacion } from "repositories/anotacion_repository";
import { Translate } from "react-redux-i18n";
import { KinderTimeCounter } from "./components/KinderTimeCounter";
import { KardexDetalle } from "./components/KardexDetalle";

export type ReporteEstudiante = {
  estudianteId: string;
  datosGenerales: any;
  asistencias: any;
  anotaciones: any;
  datosKinder?: any;
};

const Kardex: React.FC = () => {
  const classes = useStyles();
  const anotacionesRef = useRef<AnotacionesRefProps>(null);

  const [reporte, setReporte] = React.useState<ReporteEstudiante | null>(null);
  const [estudiante, setEstudiante] = React.useState<string | null>(null);
  const [modalFormAnotacion, setModalFormAnotacion] = React.useState(false);
  const [anotacion, setAnotacion] = React.useState<Anotacion | null>(null);

  const cambiarEstudiante = async (estudianteId: string) => {
    setEstudiante(estudianteId);
    const reporteEstudiante: any = await obtenerKardexReporteEstudiante(undefined, undefined, undefined, { estudiante: estudianteId });
    setReporte(reporteEstudiante.info as ReporteEstudiante);
  };

  const handleClickAnotacion = () => {
    setAnotacion(null);
    setModalFormAnotacion(true);
  }

  const handleClose = () => {
    setAnotacion(null);
    setModalFormAnotacion(false);
  }

  const handleSaveAnotacion = async (data: Partial<Anotacion>) => {
    if (!estudiante) return;

    const datosGuardar: Partial<Anotacion> = {
      ...data,
      estudianteId: estudiante,
    };

    message.success('Guardando registro');

    let success = false
    if (anotacion?.id) {
      success = await editAnotacion(datosGuardar, anotacion.id);
    } else {
      success = await createAnotacion(datosGuardar as Anotacion);
    }

    anotacionesRef.current?.refresh()

    if (success) {
      handleClose()
    }
  }

  return (
    <MainLayout>
      <Box className={classes.root}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" minHeight={100}>
            <Buscador onSearchClick={cambiarEstudiante} />
            <Box ml={4}>
              {reporte &&
                reporte?.datosGenerales?.curso?.nivel !== 'KINDER' && (
                  <>
                    <Button
                      variant="contained"
                      disabled={!estudiante}
                      onClick={handleClickAnotacion}
                      style={{ height: '40px' }}
                      startIcon={<AddIcon />}
                      color="secondary"
                    >
                      <Translate value="anotacion" />
                    </Button>
                  </>
                )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.tabla}>
            <DatosGenerales reporteEstudiante={reporte} />
          </Box>
          <Box>
            {reporte && reporte?.datosGenerales?.curso?.nivel !== 'KINDER' && (
              <KardexDetalle
                estudiante={estudiante}
                nombre={reporte?.datosGenerales.estudiante.nombre}
              />
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          {reporte && reporte?.datosGenerales?.curso?.nivel === 'KINDER' ? (
            <KinderTimeCounter reporteEstudiante={reporte} />
          ) : null}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.tabla}>
            <Asistencia estudiante={estudiante} />
          </Box>
          <Box>
            {reporte && reporte?.datosGenerales?.curso?.nivel !== 'KINDER' && (
              <Anotaciones ref={anotacionesRef} estudiante={estudiante} />
            )}
          </Box>
        </Box>
      </Box>
      {modalFormAnotacion && (
        <FormAnotacion handleClose={handleClose} anotacion={anotacion} handleSave={handleSaveAnotacion} isModalOpen={modalFormAnotacion} />
      )}
    </MainLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  foto: {
    width: '250px',
    height: '250px',
    minWidth: '250px',
    minHeight: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
    border: 'solid grey 1px',
    borderRadius: '15px',
  },
  datosGenerales: {
    margin: '10px',
  },
  tabla: {
    width: '100%',
    overflow: 'auto',
    padding: '0 16px',
  },
}));

export default Kardex;

import { PERMISO_TYPES, Action } from "../types";
import { Permiso } from "../../models/permiso_model";

export type State = {
  permisos: Permiso[] | [];
  permiso: Permiso | null;
};

const initialState: State = {
  permisos: [],
  permiso: null,
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case PERMISO_TYPES.CARGAR_PERMISOS:
      return {
        ...state,
        permisos: payload,
      };
    case PERMISO_TYPES.SET_PERMISO:
      return {
        ...state,
        permiso: payload
      }
    default:
      return state;
  }
};

export default reducer;

import { Permiso } from "../../models/permiso_model";
import {
  infoPermiso,
  sendEmail,
  createPermiso,
  deletePermiso,
  editPermiso,
  getPermisos,
  aceptarRechazarPermiso,
} from "../../repositories/permiso_repository";
import { PERMISO_TYPES } from "../types";

export const cargarPermisos = (estado?: string, estudianteId?: string) => {
  return async (dispatch: any) => {
    const permisos: any = await getPermisos(estado, estudianteId);
    // console.log('permisos :: ', permisos, permisos.length);

    dispatch(setPermisos(permisos || []));
  }
}

export const agregarPermiso = (data: Permiso) => {
  return async (dispatch: any) => {
    await createPermiso(data);
  }
}

export const editarPermiso = (data: Permiso, permisoId: string) => {
  return async (dispatch: any) => {
    await editPermiso(data, permisoId);
  }
}

export const masInfoPermiso = (data: Permiso, permisoId: string) => {
  return async (dispatch: any) => {
    await infoPermiso(data, permisoId);
  }
}

export const responderPermiso = (data: Permiso, permisoId: string) => {
  return async (dispatch: any) => {
    await aceptarRechazarPermiso(data, permisoId);
  }
}

export const eliminarPermiso = (id: string) => {
  return async (dispatch: any) => {
    await deletePermiso(id);
  }
}

export const enviarCorreo = (id: string) => {
  return async (dispatch: any) => {
    await sendEmail(id);
  }
}

export const changePermiso = (permiso: Permiso | null) => ({
  type: PERMISO_TYPES.SET_PERMISO,
  payload: permiso
})

export const setPermisos = (permisos: Permiso[]) => ({
  type: PERMISO_TYPES.CARGAR_PERMISOS,
  payload: [...permisos]
})
import {
  Paper,
  useTheme,
  makeStyles,
  Box,
  LinearProgress,
  Button,
  FormControl,
  TextField,
  Typography
} from '@material-ui/core';
import {
  FilterList as FilterListIcon,
  Refresh as RefreshIcon
} from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { parse as dateParse } from "date-fns";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useIsMounted } from 'hooks/useIsMounted';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Translate } from 'react-redux-i18n';
import { getAreas, getDoughnutReport } from 'repositories/reporte_kinder_repository';
import { Autocomplete } from '@material-ui/lab';

ChartJS.register(ArcElement, Tooltip, Legend);

const FORMAT = "dd/MM/yyyy";
const DEFAULT_DESDE = moment('26/01/2024', 'DD/MM/YYYY').format('DD/MM/YYYY');
const DEFAULT_HASTA = moment().format('DD/MM/YYYY');
const OPTIONS = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 0,
      top: 5,
      bottom: 20,
      right: 25
    }
  },
  plugins: {
    legend: {
      display: true,
      position: 'right' as any,
      align: 'center' as any,
      labels: {
        font: {
          size: 14
        }
      }
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = '';

          if (context.dataset.label === '') {
            label += context.label + ': ';
          }
          else {
            label = context.dataset.label || '';
          }

          if (label) {
            label += ' ';
          }
          if (context.parsed !== null) {
            label += context.parsed + '%';
          }
          return label;
        }
      }
    }
  }
};
const BORDER_COLORS = [
  'rgba(255,105,97,1)',
  'rgba(240,195,162,1)',
  'rgba(204,204,204,1)',
  'rgba(237,193,182,1)',
  'rgba(200,214,185,1)',
  'rgba(224,187,228,1)',
  'rgba(236,223,219,1)',
  'rgba(198,225,241,1)',
  'rgba(181,197,215,1)',
  'rgba(228,188,212,1)',
  'rgba(244,217,236,1)',
  'rgba(224,205,169,1)',
  'rgba(255,183,178,1)',
  'rgba(202,167,189,1)',
  'rgba(254,235,168,1)',
  'rgba(163,225,220,1)',
  'rgba(201,218,248,1)',
  'rgba(179,206,192,1)'
];
const BACKGROUND_COLORS = [
  'rgba(255,105,97,0.5)',
  'rgba(240,195,162,0.5)',
  'rgba(204,204,204,0.5)',
  'rgba(237,193,182,0.5)',
  'rgba(200,214,185,0.5)',
  'rgba(224,187,228,0.5)',
  'rgba(236,223,219,0.5)',
  'rgba(198,225,241,0.5)',
  'rgba(181,197,215,0.5)',
  'rgba(228,188,212,0.5)',
  'rgba(244,217,236,0.5)',
  'rgba(224,205,169,0.5)',
  'rgba(255,183,178,0.5)',
  'rgba(202,167,189,0.5)',
  'rgba(254,235,168,0.5)',
  'rgba(163,225,220,0.5)',
  'rgba(201,218,248,0.5)',
  'rgba(179,206,192,0.5)'
];

type Props = {
  tipoReporte: string;
};

const DoughnutReport = ({ tipoReporte }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = React.useState<boolean>(false);
  const isMounted = useIsMounted();
  const [user, setUser] = React.useState<any>({});
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [stateFilters, setStateFilters] = React.useState<any>({});
  const [desde, setDesde] = React.useState<string | null>(DEFAULT_DESDE);
  const [hasta, setHasta] = React.useState<string | null>(DEFAULT_HASTA);

  const [donutData, setDonutData] = React.useState<any>(null);
  const [courses, setCourses] = React.useState<any>([]);
  const [course, setCourse] = React.useState<any>(null);
  const [areas, setAreas] = React.useState<any>([]);
  const [area, setArea] = React.useState<any>(null);

  const getReport = async (filters: any) => {
    setLoading(true);
    const fechaDesde = moment(desde, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fechaHasta = moment(hasta, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const result: any = await getDoughnutReport(tipoReporte, fechaDesde, fechaHasta, filters); //as ReporteModel;
    const areasResult: any = await getAreas();

    if (isMounted())
      setLoading(false);
    if (result)
      handleSetCanvasValues(result);
    if (areasResult)
      handleSetAreas(areasResult);
  };

  const handleSetAreas = (data: any) => {
    setAreas(data);
  }

  const handleSetCanvasValues = (result: any) => {
    let obj = {
      labels: result.data.labels,
      datasets: [
        {
          label: tipoReporte === 'COMPETENCIAS_GENERAL' ? 'Area: ' : '',
          data: result.data.percentages,
          backgroundColor: BACKGROUND_COLORS.slice(0, result.data.labels.length),
          borderColor: BORDER_COLORS.slice(0, result.data.labels.length),
          borderWidth: 1,
        },
      ],
    };

    handleSetGraphicsData(obj);
    handleSetCourses(result.cursos);
  }

  const handleSetGraphicsData = (data: any) => {
    setDonutData(data);
  }

  const handleSetCourses = (data: any) => {
    setCourses(data);
  }

  const cargadoInicial = async () => {
    setStateFilters({});
    getReport({});
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, []);

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback]);

  useEffect(() => {
    const userLocal = window.localStorage.getItem('user');
    if (userLocal) {
      setUser(JSON.parse(userLocal))
    }

    if (tipoReporte === 'COMPETENCIAS_POR_AREAS')
      setFilterOpen(true);

  }, []);

  const handleSetStateFilters = (data: any) => {
    setStateFilters(data);
  }

  useEffect(() => {

    if (courses) {
      if (user?.rol === 'PROFESOR') {
        setCourse(courses[0]);
      }
    }

  }, [courses]);

  useEffect(() => {

    if (course) {
      handleSetStateFilters({ ...stateFilters, cursoId: course.value });
    }
    else {
      let obj = Object.assign({}, stateFilters);
      delete obj['cursoId'];
      handleSetStateFilters(obj);
    }

  }, [course]);

  useEffect(() => {
    if (area) {
      handleSetStateFilters({ ...stateFilters, areaId: area.value });
    }
    else {
      let obj = Object.assign({}, stateFilters);
      delete obj['areaId'];
      handleSetStateFilters(obj);
    }

  }, [area]);

  const handleFilter = () => {
    const newFilterOpen = !filterOpen;
    setFilterOpen(newFilterOpen);

    if (!newFilterOpen) {
      setStateFilters({});
      getReport({});
    }
  };

  const handleRefreshReporte = () => {
    getReport(stateFilters);
  };

  const renderEmptyMessage = () => {
    if (tipoReporte === 'COMPETENCIAS_POR_AREAS')
      return (
        <Typography style={{ paddingLeft: '15px' }}>
          <Translate value="kinder.seleccionarAreaBuscar" />
        </Typography>)

    return (
      <Typography style={{ paddingLeft: '15px' }}>
        <Translate value="tabla.sinRegistrosReporte" />
      </Typography>)
  }

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        {loading && <Box position="absolute" left={0} right={0}><LinearProgress color="secondary" /></Box>}
        <Box p={3} display="flex">
          {tipoReporte !== 'COMPETENCIAS_POR_AREAS' ?
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={handleFilter}
            >
              <FilterListIcon />
            </Button> : null
          }
          <Button
            variant="contained"
            onClick={handleRefreshReporte}
            style={{ marginLeft: 10, minWidth: '150px' }}
            startIcon={<RefreshIcon />}
          >
            <Translate value="tabla.buscar" />
          </Button>
          <Box display="flex">
            <FormControl className={classes.formControl} variant="standard">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  className={classes.input}
                  id="filtro-desde"
                  label={<Translate value="tabla.desde" />}
                  autoOk
                  format="dd/MM/yyyy"
                  value={desde ? dateParse(desde, FORMAT, new Date()) : null}
                  inputVariant="standard"
                  onChange={(date, value) => {
                    setDesde(value || null);
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  className={classes.input}
                  id="filtro-hasta"
                  label={<Translate value="tabla.hasta" />}
                  autoOk
                  format="dd/MM/yyyy"
                  value={hasta ? dateParse(hasta, FORMAT, new Date()) : null}
                  onChange={(date, value) => {
                    setHasta(value || null);
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Box>
        </Box>
        {filterOpen && (
          <Box pl={3} display="flex" alignItems='center'>
            <Autocomplete
              style={{ width: '350px' }}
              options={courses}
              getOptionLabel={(option: any) => `${option.label}`}
              id="controlled-course"
              value={course}
              onChange={(event: any, newValue: any | null) => {
                setCourse(newValue);
              }}
              renderInput={(params) => {

                return <TextField {...params} label={<Translate value="kinder.curso" />} inputProps={{
                  ...params.inputProps,
                }} margin="normal" />
              }
              }
              disabled={user?.rol === 'PROFESOR' ? true : false}
            />
            {tipoReporte !== 'COMPETENCIAS_GENERAL' ?
              <Autocomplete
                style={{ marginLeft: '10px', width: '350px' }}
                options={areas}
                getOptionLabel={(option: any) => `${option.label}`}
                id="controlled-areas"
                value={area}
                onChange={(event: any, newValue: any | null) => {
                  setArea(newValue);
                }}
                renderInput={(params) => {

                  return <TextField {...params} label={<Translate value="kinder.area" />} inputProps={{
                    ...params.inputProps,
                  }} margin="normal" />
                }
                }
              /> : null
            }
          </Box>
        )}
        <div>
          {(donutData && donutData.labels?.length > 0) ?
            <Doughnut width={500} height={600} data={donutData} options={OPTIONS} /> :
            renderEmptyMessage()
          }
        </div>
      </Paper>
    </React.Fragment>
  );
}

const useStyles = makeStyles({
  root: {

    // height: '1000 px',
  },
  container: {
    // maxHeight: 440,
    // maxHeight: 'calc(100vh - 350px)',
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'left',
    height: 0,
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'height .5s, marginTop .5s',
    background: '#eee',
    paddingLeft: '16px',
  },
  filterBoxOpen: {
    overflowX: 'auto',
    height: '64px',
  },
  btnAction: {
    margin: "0 3px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  formControl: {
    width: "200px",
    padding: "0 0 0 20px",
  },
  input: {
    margin: 0,
  },
  filterItem: {
    minWidth: '600px',
    margin: '0 16px',
  },
  resaltado: {
    backgroundColor: '#ffcccc',
    borderColor: 'grey',
  },
});


export default DoughnutReport;

import { ReactElement, useCallback, useEffect, useState } from 'react'
import {
  makeStyles,
  Theme,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@material-ui/core'
import { useDebouncedCallback } from 'use-debounce'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw, EditorState, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const DEFAULT_DELAY = 500

type Props = {
  name: string
  label?: string
  value?: string
  onChange?: (newValue: string) => void
  delay?: number
  error?: string
  placeholder?: string
}

export const FormInputEditorHTML = ({
  name,
  label,
  value,
  onChange,
  delay,
  error,
  placeholder,
}: Props): ReactElement => {
  const classes = useStyles()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const DELAY = delay || DEFAULT_DELAY

  const handleChange = (editorState: EditorState) => {
    if (onChange) {
      const content = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      )
      onChange(content)
    }
  }

  const debouncedHandleChange = useDebouncedCallback(
    (editorState: EditorState) => {
      handleChange(editorState)
    },
    DELAY,
  )

  const handleEditorStateChange = useCallback(
    (editorState: EditorState) => {
      setEditorState(editorState)
      debouncedHandleChange.callback(editorState)
    },
    [debouncedHandleChange],
  )

  // Función para convertir imagen a base64
const uploadImageCallBack = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onloadend = () => {
      // reader.result contiene la imagen en base64
      resolve({ data: { link: reader.result } }); 
    };
    
    reader.onerror = (error) => {
      // Manejo de error en la carga
      reject(error); 
    };
    
    // Convierte la imagen a base64
    reader.readAsDataURL(file); 
  });
};

  useEffect(() => {
    if (value && !editorState.getCurrentContent().hasText()) {
      const { contentBlocks, entityMap } = htmlToDraft(value)
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      )
      setEditorState(EditorState.createWithContent(contentState))
    }
  // eslint-disable-next-line
  }, [value])

  return (
    <FormControl className={classes.formControl} error={!!error}>
      <div className={classes.formContainer}>
        {label && (
          <FormLabel component="legend" className={classes.formLabel}>
            {label}
          </FormLabel>
        )}
        <Editor
          placeholder={placeholder || ''}
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
          wrapperClassName="wrapperClass"
          editorClassName="editorClass"
          toolbarClassName="toolbarClass"
          localization={{
            locale: localStorage.getItem('locale') || 'es',
          }}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'emoji',
              'image',
              'history',
            ],
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            list: {
              inDropdown: false,
              options: ['unordered', 'ordered'],
            },
            image: {
              uploadEnabled: true,
              uploadCallback: uploadImageCallBack,
              previewImage: true,
              defaultSize: {
                height: '200',
                width: '200',
              },
              alt: { present: true, mandatory: false },
            },
          }}
        />
      </div>
      <FormHelperText className={classes.helperText}>{error}</FormHelperText>
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    paddingTop: theme.spacing(0),
  },
  formContainer: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    minHeight: theme.spacing(25),
    background: theme.palette.background.paper,
  },
  formLabel: {
    padding: theme.spacing(1, 1, 1, 1.5),
  },
  helperText: {
    paddingLeft: theme.spacing(1.5),
  },
}))

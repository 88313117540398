import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  FormControl,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  FilterList as FilterListIcon,
  Refresh as RefreshIcon,
  Search,
} from '@material-ui/icons';
import GridOnIcon from '@material-ui/icons/GridOn';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import clsx from 'clsx';
import { parse as dateParse } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useIsMounted } from 'hooks/useIsMounted';
import {
  ReporteColumn,
  ReporteItem,
  ReporteModel,
  ReporteRow,
} from 'models/reporte_model';
import moment from 'moment';
import React, { ReactElement, useEffect } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import {
  getReporte,
  downloadReporteExcel,
} from 'repositories/reporte_kinder_repository';
import { SelectFilter } from './filters/SelectFilter';
import { TextFilter } from './filters/TextFilter';

const FORMAT = 'dd/MM/yyyy';
const DEFAULT_ROWS_PER_PAGE = 50;

const DEFAULT_DESDE = moment().format('DD/MM/YYYY');
const DEFAULT_HASTA = moment().format('DD/MM/YYYY');

const DEFAULT_DESDE_3 = moment()
  .startOf('isoweek' as any)
  .format('DD/MM/YYYY');
const DEFAULT_HASTA_3 = moment()
  .endOf('isoweek' as any)
  .subtract(2, 'd')
  .format('DD/MM/YYYY');

type Props = {
  tipoReporte:
    | 'PERMISOS'
    | 'ASISTENCIA'
    | 'LIBRO_CLASES_POR_SESION'
    | 'LIBRO_CURSO_NO_LLENADO'
    | 'REPORTE_LIBRO_DE_CURSO'
    | 'ALERGIAS'
    | 'ACCIDENTES';
  descargarExcel?: boolean;
};

const ReporteKinder = ({
  tipoReporte,
  descargarExcel,
}: Props): ReactElement => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [loading, setLoading] = React.useState<boolean>(false);
  const isMounted = useIsMounted();
  // const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [filterOpen, setFilterOpen] = React.useState(false);
  const [stateFilters, setStateFilters] = React.useState<any>({});

  const [desde, setDesde] = React.useState<string | null>(
    tipoReporte === 'LIBRO_CURSO_NO_LLENADO' ? DEFAULT_DESDE_3 : DEFAULT_DESDE,
  );
  const [hasta, setHasta] = React.useState<string | null>(
    tipoReporte === 'LIBRO_CURSO_NO_LLENADO' ? DEFAULT_HASTA_3 : DEFAULT_HASTA,
  );

  const [rows, setRows] = React.useState<ReporteRow[]>([]);
  const [columns, setColumns] = React.useState<ReporteColumn[]>([]);

  const mostrarFiltrosFecha = tipoReporte !== 'ALERGIAS';

  const cargarReporte = async (
    filters: any,
    page: number,
    rowsPerPage: number,
  ) => {
    setLoading(true);
    const fechaDesde = moment(desde, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fechaHasta = moment(hasta, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const result = (await getReporte(
      tipoReporte,
      fechaDesde,
      fechaHasta,
      filters,
    )) as ReporteModel;
    if (isMounted()) setLoading(false);
    if (result) {
      setRows(result.rows);
      setColumns(result.columns);
    }
  };
  const cargadoInicial = async () => {
    setStateFilters({});
    setPage(0);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    cargarReporte({}, 0, DEFAULT_ROWS_PER_PAGE);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, []);

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback]);

  const handleRefreshReporte = () => {
    cargarReporte(stateFilters, page, rowsPerPage);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = () => {
    const newFilterOpen = !filterOpen;
    setFilterOpen(newFilterOpen);
    if (!newFilterOpen) {
      setStateFilters({});
      setPage(0);
      cargarReporte({}, 0, rowsPerPage);
    }
  };

  const handleChangeFilterSelect = (name: string, value: string | number) => {
    const newFilters = { ...stateFilters, [name]: value };
    Object.keys(newFilters).forEach(
      (key) => newFilters[key] === '' && delete newFilters[key],
    );
    setStateFilters(newFilters);
  };

  const handleSubmitFilter = () => {
    setPage(0);
    cargarReporte(stateFilters, 0, rowsPerPage);
  };

  const handleFilterCancel = () => {
    setStateFilters({});
    setPage(0);
    cargarReporte({}, 0, rowsPerPage);
  };

  const renderText = (value: ReporteItem): ReactElement => {
    if (typeof value !== 'string') {
      return <></>;
    }
    return (
      <>
        {value.split('|').map((line, lineIndex) => (
          <Typography key={lineIndex}>{line}</Typography>
        ))}
      </>
    );
  };

  const handleClickExcel = async () => {
    const fechaDesde = moment(desde, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fechaHasta = moment(hasta, 'DD/MM/YYYY').format('YYYY-MM-DD');

    await downloadReporteExcel(tipoReporte, fechaDesde, fechaHasta, stateFilters);
  };

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        {loading && (
          <Box position="absolute" left={0} right={0}>
            <LinearProgress color="secondary" />
          </Box>
        )}
        <Box p={3} display="flex">
          {columns.some((col) => col.filter) && (
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={handleFilter}
            >
              <FilterListIcon />
            </Button>
          )}
          <Button
            variant="contained"
            onClick={handleRefreshReporte}
            style={{ marginLeft: 10, minWidth: '150px' }}
            startIcon={<RefreshIcon />}
          >
            <Translate value="tabla.refrescar" />
          </Button>
          {mostrarFiltrosFecha && (
            <Box display="flex">
              <FormControl className={classes.formControl} variant="standard">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    variant="inline"
                    className={classes.input}
                    id="filtro-desde"
                    label={<Translate value="tabla.desde" />}
                    autoOk
                    format="dd/MM/yyyy"
                    value={desde ? dateParse(desde, FORMAT, new Date()) : null}
                    inputVariant="standard"
                    onChange={(date, value) => {
                      setDesde(value || null);
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    variant="inline"
                    className={classes.input}
                    id="filtro-hasta"
                    label={<Translate value="tabla.hasta" />}
                    autoOk
                    format="dd/MM/yyyy"
                    value={hasta ? dateParse(hasta, FORMAT, new Date()) : null}
                    onChange={(date, value) => {
                      setHasta(value || null);
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Box>
          )}
          {(typeof descargarExcel === 'undefined' || descargarExcel) && (
            <Box ml={'auto'}>
              <Button
                variant="contained"
                style={{ minWidth: '200px' }}
                color="primary"
                onClick={handleClickExcel}
                startIcon={<GridOnIcon />}
              >
                <Translate value="tabla.generarExcel" />
              </Button>
            </Box>
          )}
        </Box>
        {filterOpen && columns.some((col) => col.filter) && (
          <div
            className={clsx(classes.filterBox, {
              [classes.filterBoxOpen]: filterOpen,
            })}
          >
            <label>
              <Translate value="tabla.buscarPor" />:{' '}
            </label>
            <br />

            {columns.map((column, columnIndex) => {
              if (column.filter && column.filter.type === 'text') {
                return (
                  <Box key={columnIndex} className={classes.filterItem}>
                    <TextFilter
                      name={column.id as string}
                      label={column.label}
                      onChange={handleChangeFilterSelect}
                      value={
                        stateFilters
                          ? (stateFilters[String(column.id)] as string)
                          : ''
                      }
                    />
                  </Box>
                );
              }
              if (column.filter && column.filter.type === 'select') {
                return (
                  <Box key={columnIndex} className={classes.filterItem}>
                    <SelectFilter
                      name={column.id as string}
                      label={column.label}
                      options={column.filter.options || []}
                      onChange={handleChangeFilterSelect}
                      value={
                        stateFilters
                          ? (stateFilters[String(column.id)] as string)
                          : ''
                      }
                    />
                  </Box>
                );
              }
              return <Box key={columnIndex}></Box>;
            })}

            <Box mr={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitFilter}
              >
                <Search /> <Translate value="tabla.buscar" />
              </Button>
            </Box>
            {stateFilters && Object.keys(stateFilters).length > 0 && (
              <Button variant="contained" onClick={handleFilterCancel}>
                <Translate value="form.cancelar" />
              </Button>
            )}
          </div>
        )}
        {(rows.length === 0 || rows === undefined || rows === null) && (
          <Typography variant="subtitle1" align="center">
            <Translate value="tabla.sinRegistrosReporte" />
          </Typography>
        )}
        {rows.length > 0 && (
          <React.Fragment>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="nro"
                      align="left"
                      style={{
                        minWidth: 60,
                        fontWeight: 'bold',
                      }}
                    >
                      <Translate value="tabla.nro" />
                    </TableCell>
                    {columns
                      .filter((c) => !c.hidden)
                      .map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            fontWeight: 'bold',
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: ReporteRow, rowIndex: number) => {
                      let resaltado = false;
                      if (tipoReporte === 'ASISTENCIA') {
                        resaltado = parseInt(String(row[9])) > 0;
                      }
                      if (tipoReporte === 'LIBRO_CLASES_POR_SESION') {
                        if (typeof row[7] === 'string') return <></>;
                        resaltado =
                          !String(row[7].value) ||
                          String(row[7].value).trim().length === 0;
                      }
                      const indexColor = columns.findIndex(
                        (c) => c.id === 'color',
                      );
                      const colorAlterno =
                        tipoReporte === 'REPORTE_LIBRO_DE_CURSO' &&
                        row[indexColor] === 'alternate';
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowIndex}
                          style={
                            colorAlterno ? { backgroundColor: '#FFF7B0' } : {}
                          }
                        >
                          <TableCell
                            key="nro"
                            className={
                              resaltado ? classes.resaltado : undefined
                            }
                          >
                            <Typography>
                              {rowIndex + 1 + page * rowsPerPage}
                            </Typography>
                          </TableCell>
                          {columns
                            .filter((c) => !c.hidden)
                            .map(
                              (
                                column: ReporteColumn,
                                colIndex: number,
                                arr,
                              ) => {
                                return (
                                  <TableCell
                                    key={colIndex}
                                    className={
                                      resaltado ? classes.resaltado : undefined
                                    }
                                  >
                                    <div>{renderText(row[colIndex])}</div>
                                  </TableCell>
                                );
                              },
                            )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 50, 100, 250]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={<Translate value="tabla.filasPorPagina" />}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${I18n.t('tabla.de')} ${
                  count !== -1 ? count : `${I18n.t('tabla.masDe')} ${to}`
                }`
              }
            />
          </React.Fragment>
        )}
      </Paper>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
  },
  container: {
    // maxHeight: 440,
    // maxHeight: 'calc(100vh - 350px)',
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'left',
    height: 0,
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'height .5s, marginTop .5s',
    background: '#eee',
    paddingLeft: '16px',
  },
  filterBoxOpen: {
    overflowX: 'auto',
    height: '64px',
  },
  btnAction: {
    margin: '0 3px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  formControl: {
    width: '200px',
    padding: '0 0 0 20px',
  },
  input: {
    margin: 0,
  },
  filterItem: {
    minWidth: '190px',
    margin: '0 16px',
  },
  resaltado: {
    backgroundColor: '#ffcccc',
    borderColor: 'grey',
  },
});

export default ReporteKinder;

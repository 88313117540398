import { firebaseConfig } from "config/app.config";
import firebase from "firebase/app";
// import 'firebase/messaging';
import "firebase/auth";
import 'firebase/storage';
// import http from "repositories/http";

if (firebase.apps.length === 0) {
  try {
    firebase.initializeApp(firebaseConfig);
  } catch (err) {
    console.error("[firebase] firebase.initializeApp ...", err);
  }
}

// let messaging: firebase.messaging.Messaging | null = null;
// try {
//     messaging = firebase.messaging();
// } catch (err) {
//     console.error('[firebase] firebase.messaging ...', err);
// }

// export const registrarDispositivoParaNotificacionesPush = async (): Promise<boolean> => {
//   if (hasDeviceBeenRegistered()) {
//       return true;
//   }

//   // Intenta habilitar las notificaciones
//   if (!notificationPermissionGranted()) {
//       await preguntarPorPermisoNotificaciones();
//       if (!notificationPermissionGranted()) return false;
//   }

//   if (!messaging) return false;
//   const token = await messaging.getToken({ vapidKey: PUBLIC_VAPID_KEY }).catch((err) => {
//       console.error('messaging.getToken...', err);
//   });
//   if (!token) return false;

//   return sendTokenToServer(token);
// };

// export const removerDispositivoParaNotificacionesPush = async (): Promise<boolean> => {
//   const token = getDeviceRegisteredFromLocalStorage();
//   if (!token) return true;

//   if (!messaging) return false;
//   await messaging.deleteToken().catch(() => ({}));
//   return removeTokenFromServer(token);
// };

// const preguntarPorPermisoNotificaciones = async (): Promise<void> => {
//   // REQUEST PERMISSION
//   let newPermission = 'denied';
//   if (window.Notification && Notification.permission !== 'denied') {
//       const permission = await Notification.requestPermission();
//       newPermission = String(permission);
//   }
//   setNotificationPermissionToLocalStorage(newPermission);

//   // Si la respuesta es negativa, elimina cualquier registro existente
//   if (!notificationPermissionGranted()) {
//       const tokenToRemove = getDeviceRegisteredFromLocalStorage();
//       if (tokenToRemove) await removeTokenFromServer(tokenToRemove);
//   }
// };

export const notificationPermissionGranted = (): boolean => {
  return `${localStorage.getItem('notificationPermission')}` === 'granted';
};

// const setNotificationPermissionToLocalStorage = (permission: string): void => {
//   localStorage.setItem('notificationPermission', permission);
// };

const removeNotificationPermissionFromLocalStorage = (): void => {
  localStorage.removeItem('notificationPermission');
};

export const hasDeviceBeenRegistered = (): boolean => {
  return localStorage.getItem('deviceRegistered') !== null;
};

// const setDeviceRegisteredToLocalStorage = (token: string): void => {
//   localStorage.setItem('deviceRegistered', token);
// };

const removeDeviceRegisterFromLocalStorage = (): void => {
  localStorage.removeItem('deviceRegistered');
};

// const getDeviceRegisteredFromLocalStorage = (): string | null => {
//   return localStorage.getItem('deviceRegistered');
// };

// const sendTokenToServer = async (currentToken: string): Promise<boolean> => {
//   console.log('sendTokenToServer....');
//   if (hasDeviceBeenRegistered()) return true;
//   if (!isAuthenticated()) return false;
//   return http
//     .post("/notificaciones/registroDispositivo", { token: currentToken })
//     .then((result) => {
//       setDeviceRegisteredToLocalStorage(currentToken);
//       return true;
//     })
//     .catch((err) => {
//       return false;
//     });
// };

// const removeTokenFromServer = async (currentToken: string): Promise<boolean> => {
//   if (!isAuthenticated()) return false;
//   const data = { token: currentToken };
//   return http
//     .delete("/notificaciones/registroDispositivo", data)
//     .then((result) => {
//       removeDeviceRegisterFromLocalStorage();
//       return true;
//     })
//     .catch((err) => {
//       return false;
//     });
// };

// export const enviarNotificacionPushPrueba = async (): Promise<boolean> => {
//   if (!isAuthenticated()) return false;
//   const data = { token: getDeviceRegisteredFromLocalStorage() };
//   return BaseService.request('post', '/notificaciones/registro_dispositivo/mensaje', data).then((result) => {
//       if (!result.success) return false;
//       return true;
//   });
// };

// export const registerLogin = (sessionInfo: SessionInfo) => {
//   setSessionInfoToLocalStorage(sessionInfo);
//   setTimeout(() => initBackgroundTasks(), 3000); // Cargado inicial luego de 3 segundos
// };

export const registerLogout = () => {
  cleanLocalStorage();
};

// export const getSessionInfoFromLocalStorage = (): SessionInfo | null => {
//   const item = localStorage.getItem('auth');
//   if (item) return JSON.parse(item);
//   return null;
// };

// const setSessionInfoToLocalStorage = (sessionInfo: SessionInfo): void => {
//   localStorage.setItem('auth', JSON.stringify(sessionInfo));
// };

// const removeSessionInfoFromLocalStorage = (): void => {
//   localStorage.removeItem('auth');
// };

export const isAuthenticated = (): boolean => {
  const item = localStorage.getItem('user');
  const estaAutenticado = item !== null;
  return estaAutenticado;
};

const cleanLocalStorage = () => {
  removeNotificationPermissionFromLocalStorage();
  removeDeviceRegisterFromLocalStorage();
};

// Remueve los registros que se generan en segundo plano
removeNotificationPermissionFromLocalStorage();
removeDeviceRegisterFromLocalStorage();

// Inicia procesos en segundo plano
async function initBackgroundTasks() {
  // TODO
  // registrarDispositivoParaNotificacionesPush();
}

if (isAuthenticated()) {
  setTimeout(() => initBackgroundTasks(), 3000); // Cargado inicial luego de 3 segundos
  // setInterval(() => initBackgroundTasks(), 300000); // Cada 5 minutos
}

export const firebaseAuth = firebase.auth();
export const firebaseStorage = firebase.storage();

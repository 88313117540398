import { Box, DialogContent, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Button, DialogActions, Theme, makeStyles, createStyles } from '@material-ui/core';
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from "@date-io/date-fns";
import { useDispatch } from 'react-redux';
import { setFormCurso } from '../../../../store/actions/curso_actions';
import { RootState, useStateSelector } from '../../../../store/reducers';
import { cargarProfesores } from '../../../../store/actions/profesor';
import { IProfesor } from '../../../../models/profesor_model';
import { Curso } from '../../../../models/curso_model';
import esl from 'date-fns/locale/es';

type FormStepTwoProps = {
  handleBack: () => void;
  handleClose: () => void;
  handleSave: (data: any) => void;
  curso: Curso | null;
}


type FormCourseData = {
  fechaInicio: Date;
  fechaFin: Date;
  profesorId: string;
  jefeCursoId: string;
}



const FormStepTwo: React.FC<FormStepTwoProps> = ({
  handleBack,
  handleClose,
  handleSave,
  curso
}) => {
  const { handleSubmit, control, setValue } = useForm<FormCourseData>({
    shouldUnregister: false
  });
  const { profesors } = useStateSelector((state: RootState) => state.profesorState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( cargarProfesores() );
    if (curso) {
      setTimeout(() => {
        setValue("fechaInicio", curso.fechaInicio);
        setValue("fechaFin", curso.fechaFin);
        setValue("profesorId", curso.profesorId);
        setValue("jefeCursoId", curso.jefeCursoId ? curso.jefeCursoId : '');
      }, 300);
    }
  }, [dispatch, curso, setValue])
  const classes = useStyles();
  const onSubmit = (data: any) => {
    console.log('Enviando data de paso 2 para curso ::', data);
    dispatch( setFormCurso(data) );
    setTimeout(() => {
      handleSave(data);
    }, 1000);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Box display='flex' justifyContent='center' mb={2}>
          <Box className={classes.activeStep}></Box>
          <Box className={classes.activeStep}></Box>
          {/* <Box className={classes.inactiveStep}></Box> */}
        </Box>
        <Typography variant='body2'>
          Por favor, completa los siguientes campos y luego presiona continuar.
        </Typography>
        <Box my={3}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                1. Selección de la fecha de inicio:
              </Typography>
              <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                <Controller
                  name="fechaInicio"
                  control={control}
                  defaultValue={null}
                  render={({ field: { ref, ...rest } }) => (
                    <KeyboardDatePicker
                      fullWidth
                      inputVariant="filled"
                      required
                      label="-Selecciona una fecha-"
                      autoOk
                      openTo="month"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      {...rest}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                2. Selección de la fecha de fin:
              </Typography>
              <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                <Controller
                  name="fechaFin"
                  control={control}
                  defaultValue={null}
                  render={({ field: { ref, ...rest } }) => (
                    <KeyboardDatePicker
                      fullWidth
                      inputVariant="filled"
                      required
                      label="-Selecciona una fecha-"
                      autoOk
                      openTo="month"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      {...rest}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                3. Asignar profesor/asesor:
              </Typography>
              <Controller
                name="profesorId"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <FormControl variant="filled" className={classes.formControl} required>
                    <InputLabel>-Selecciona un profesor/asesor-</InputLabel>
                    <Select {...field}>
                      <MenuItem value={''}>
                        NINGUNO
                      </MenuItem>
                      { profesors.map((profesor: IProfesor) => (
                        <MenuItem value={profesor.id} key={profesor.id}>
                          {profesor.nombre}
                        </MenuItem>
                      )) }
                    </Select>
                  </FormControl>
                )}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                3. Asignar jefe de curso:
              </Typography>
              <Controller
                name="jefeCursoId"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel>-Selecciona un profesor/jefe de curso-</InputLabel>
                    <Select {...field}>
                      <MenuItem value={''}>
                        NINGUNO
                      </MenuItem>
                      { profesors.map((profesor: IProfesor) => (
                        <MenuItem value={profesor.id} key={profesor.id}>
                          {profesor.nombre}
                        </MenuItem>
                      )) }
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Box>
        {/* <InputLabel id="label" className={classes.label}>1. Selección del nivel:</InputLabel>
        <Select labelId="label" id="select" value="20">
          <MenuItem value="10">Ten</MenuItem>
          <MenuItem value="20">Twenty</MenuItem>
        </Select> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBack} className={classes.btnBack}>
          Atrás
        </Button>
        <Button onClick={handleClose} className={classes.btnError}>
          Cancelar
        </Button>
        <Button type="submit" className={classes.btnSave}>
          Continuar
        </Button>
      </DialogActions>
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    btnBack: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.grey[400],
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
    stepRoot: {
      display: 'flex',
      justifyContent: 'center',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: theme.palette.primary.main
    },
    activeStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%'
    },
    inactiveStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.grey[400],
      borderRadius: '50%'
    },
    label: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black
    },
  })
);

export default FormStepTwo;
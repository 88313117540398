import React, { useEffect, useState } from 'react'

import { Box, Typography, TextField, makeStyles, createStyles, Theme, ListSubheader, Tooltip, } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderGroupParams } from '@material-ui/lab';
import { Translate } from 'react-redux-i18n';

// type FilterOption = {
//     value: string | number;
//     label: string;
//     caption?: string;
// };

// type Props = {
//     name: string;
//     label: string;
//     value?: string;
//     options: FilterOption[];
//     onChange: (name: string, value: string | number) => void;
// };

const ActivitySelect = (props:any): any => {
  const classes = useStyles();
    const [skill, setSkill] = useState<any | null>(null);

    useEffect(() => {
      setSkill(props.skill);
    },[props.skill]);
    
    const renderGroup = (params: AutocompleteRenderGroupParams) => [
      <ListSubheader
        key={params.key}
        style={{ backgroundColor: '#DFD2F0', textTransform: 'uppercase', color: '#000000'}}
        component="div"
      >
        <strong>{params.group}</strong>
      </ListSubheader>,
      params.children
    ];

    return (
      <Autocomplete
      options={props.availableSkills}
      autoHighlight
      autoComplete
      classes={{popper: classes.popper}}
      getOptionSelected={(option:any, value:any) => {
     
        //if(value)
          return value.id === option.id
        //else
          //return option[0];
      }}
      getOptionLabel={(option:any) => `${option.nombre}`}
      groupBy={(option:any) => option.categoria}
      style={{ width:'100%', paddingTop: 8 }}
      renderGroup={renderGroup}
      renderOption={(option:any) => {
        //console.log('option es ', option)
        return (
        <React.Fragment>
            <Box ml={2} style={{color:'#383838'}} >
                <Typography variant="body2">{option.nombre}</Typography>
            </Box>
          
        </React.Fragment>
      )}}
      renderInput={(params:any) => (
        <Tooltip arrow title={
            <Typography>
              <Translate value={params.inputProps.value}/>
            </Typography>}
          >
        <TextField
          {...params}
          label={<Translate value="kinder.selecciona"/>}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            //autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        /></Tooltip>
      )}
      value={skill || null}
      onChange={(event: any, newValue: any | null) => {
        //field.onChange(newValue.id)
        newValue ? props.handleUpdate(newValue.id) : props.handleUpdate('');
        
        setSkill(newValue)
      }}
      
    />
    );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    morningSelect: {
      backgroundColor: '#FFE8DF'
    },
    otherSelect: {
      backgroundColor: '#FFF5E9'
    },
    popper: {
      width: '650px !important'
    }
  })
);

export default ActivitySelect;
import React, { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, LinearProgress, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useIsMounted } from 'hooks/useIsMounted';
import { obtenerParametricas } from 'repositories/parametrica_repository';
import { I18n } from 'react-redux-i18n';

export type SelectOption = {
    value: string | number;
    label: string;
    caption?: string;
};

type ParametricaOption = {
    id: string;
    codigo: string;
    grupo: string;
    nombre: string;
    descripcion: string;
};

type ActionSearchProps = {
    onSearchClick: (idParametrica: string) => Promise<void>;
};

export const Buscador = (props: ActionSearchProps): ReactElement => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState<boolean>(false);
    const isMounted = useIsMounted();

    const [estudianteOptions, setParametricaOptions] = React.useState<SelectOption[]>([]);
    const [_val, setValue] = React.useState('');

    const cargarOpciones = async () => {
        const parametricasResponse: any = await obtenerParametricas('MOTIVO_SOLICITUD_INFORMACION_ALTA_MEDICA');
        const listado = parametricasResponse as ParametricaOption[];
        const estudianteOptions: SelectOption[] = (listado || []).map((item) => ({
            value: item.codigo || '',
            label: `${item.nombre}`,
        }));
        setParametricaOptions(estudianteOptions);
    };
    const cargarOpcionesCallback = React.useCallback(cargarOpciones, []);

    React.useEffect(() => {
        cargarOpcionesCallback();
    }, [cargarOpcionesCallback]);

    const handleOnChange = async (value: any) => {
        if (loading) return;
        setValue(value);
        setLoading(true);
        await props.onSearchClick(value);
        if (!isMounted()) return;
        setLoading(false);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: SelectOption | null) => {
        const newValue = value?.value || '';
        handleOnChange(newValue);
    };

    return (
        <Box className={classes.root}>
            <Box width="100%" maxWidth="500px" mb={1} position="relative">
                <LinearProgress className={classes.loader} color="secondary" variant="indeterminate" hidden={!loading} />
                <Autocomplete
                    renderOption={(option) => (
                        <Box>
                            <Typography variant="body2">{option.label}</Typography>
                            {option.caption && <Typography variant="caption">{option.caption}</Typography>}
                        </Box>
                    )}
                    options={estudianteOptions}
                    getOptionLabel={(option: SelectOption) => {
                        return option.caption ? `${option.label} - ${option.caption}` : `${option.label}`;
                    }}
                    getOptionSelected={(option, value) => value.value === option.value}
                    value={estudianteOptions.find((opt) => opt.value === _val) || null}
                    // disabled={loading}
                    noOptionsText={` >>> ${I18n.t('reporte.sinOpciones')} <<<`}
                    onChange={handleChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="buscador"
                            required
                            label={loading ? `${I18n.t('reporte.cargando')}...` : `${I18n.t('form.seleccione')}`}
                            variant="filled"
                        />
                    )}
                />
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '350px',
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        height: '4px',
        background: '#e0e0e0',
        zIndex: 9,
    },
}));

import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, makeStyles, Paper, Button, Box, Typography, Backdrop, CircularProgress } from "@material-ui/core";
import { I18n, Localize, Translate } from "react-redux-i18n";
import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from "react-router-dom";
import * as qs from 'query-string';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import MainLayout from "components/layout/MainLayout";
import { getKinderAssistance, updateAssistance } from "../../store/actions/asistencia_kinder";
import { RootState, useStateSelector } from "store/reducers";
import { Asistencia, DetallesLicencia, DetallesLicenciaParcial } from "models/asistencia_kinder_model";
import Presente from '../../assets/img/icons/asistencia_presente.svg';
import FaltaConLicencia from '../../assets/img/icons/falta_con_licencia.svg';
import FaltaSinLicencia from '../../assets/img/icons/falta_sin_licencia.svg';
import RelojActivo from '../../assets/img/icons/reloj_activo.svg';
import RelojInactivo from '../../assets/img/icons/reloj_inactivo.svg';
import ModalAssistance from "./components/ModalAssistance";
import ModalLicenseDetails from "./components/ModalLicenseDetails";
import moment from "moment";
import message from "plugins/utils/message";
import theme from "plugins/theme";

interface Column {
  id: 'idEstudiante' | 'estadoAsistencia' | 'retraso';
  label: string;
  minWidth?: number;
  align?: 'center' | 'left' | 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'idEstudiante', label: 'Estudiante', minWidth: 170, align: 'center' },
  { id: 'estadoAsistencia', label: 'Asistencia', minWidth: 170, align: 'center' },
  { id: 'retraso', label: 'Retraso', minWidth: 170, align: 'center' }
];

const createTableData = (
  idEstudiante: string,
  apellido: string,
  nombre: string,
  estadoAsistencia: any,
  foto: string,
  retrasoEntrada?: number,
  retrasoSalida?: number,
  detallesLicencia?: DetallesLicencia,
  detallesLicenciaParcial?: DetallesLicenciaParcial): Asistencia => {
  let studentRow: Asistencia = {
    idEstudiante,
    apellido,
    nombre,
    estadoAsistencia,
    foto,
    retrasoEntrada,
    retrasoSalida,
    detallesLicencia,
    detallesLicenciaParcial
  };

  return studentRow;
}

const KinderAttendance: React.FC<any> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { asistenciaKinder } = useStateSelector((state: RootState) => state.asistenciaKinderState);
  const [rows, setRows] = React.useState<[]>([]);
  const [modalAssistanceForm, setModalAssistanceForm] = React.useState<boolean>(false);
  const [modalLicense, setModalLicense] = React.useState<boolean>(false);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [displayLateAttendance, setDisplayLateAttendance] = React.useState<boolean>(false);
  const [selectedStudentId, setSelectedStudentId] = React.useState<string>('');
  const [presentCount, setPresentCount] = React.useState<Number>(0);
  const [licenseCount, setLicenseCount] = React.useState<Number>(0);
  const [noLicenseCount, setNoLicenseCount] = React.useState<Number>(0);
  const [licenseDetails, setLicenseDetails] = React.useState<any>();
  const [partialLicenseDetails, setPartialLicenseDetails] = React.useState<any>();
  const [selectedDate, setSelectedDate] = React.useState<moment.Moment | undefined>(undefined);
  const [isPartial, setIsPartial] = React.useState<boolean>(false);
  const [inStudentDelay, setInStudentDelay] = React.useState<number>(0);
  const [outStudentDelay, setOutStudentDelay] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    const parsedAttendanceDate = qs.parse(window.location.search);
    const dateStr: any = parsedAttendanceDate.fecha ? parsedAttendanceDate.fecha : '';
    const tempDate = moment(dateStr, 'YYYY-MM-DD');
    handleSetSelectedDate(tempDate);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      dispatch(getKinderAssistance(selectedDate));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000)

    }
  }, [dispatch, selectedDate]);

  useEffect(() => {
    let studentsRowData = asistenciaKinder.map((item: any) => {

      let rowData: Asistencia = createTableData(item.idEstudiante, item.apellido, item.nombre, item.estadoAsistencia, item.foto, item.retrasoEntrada, item.retrasoSalida, item.detallesLicencia, item.detallesLicenciaParcial)

      return rowData;
    });

    handleSetRows(studentsRowData);
    handleAttendanceCount();

  }, [asistenciaKinder]);

  const handleSetSelectedDate = (date: moment.Moment) => {
    setSelectedDate(date);
  }

  const handleSetIsPartial = (partial: boolean) => {
    setIsPartial(partial);
  }

  const handleAttendanceCount = () => {
    let presentCount = asistenciaKinder.filter((item) => item.estadoAsistencia === 'PRESENTE').length -
      asistenciaKinder.filter((item) => item.estadoAsistencia === 'PRESENTE' && item.detallesLicenciaParcial !== undefined).length;
    let licenseCount = asistenciaKinder.filter((item) => item.estadoAsistencia === 'FALTA_CON_LICENCIA').length +
      asistenciaKinder.filter((item) => item.estadoAsistencia === 'PRESENTE' && item.detallesLicenciaParcial !== undefined).length;
    let noLicenseCount = asistenciaKinder.filter((item) => item.estadoAsistencia === 'FALTA_SIN_LICENCIA').length;
    setPresentCount(presentCount);
    setLicenseCount(licenseCount);
    setNoLicenseCount(noLicenseCount);
  }

  const handleSetRows = (arr: any) => {
    setRows(arr);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSetLicenseDetails = (detallesLicencia: DetallesLicencia) => {
    setLicenseDetails(detallesLicencia);
  }

  const handleSetPartialLicenseDetails = (detallesLicencia: DetallesLicenciaParcial) => {
    setPartialLicenseDetails(detallesLicencia);
  }

  const handleSetModalLicense = (val: boolean) => {
    setModalLicense(true);
  }

  const renderStudentCell = (studentId: string, nombre: string, apellido: string, estadoAsistencia: string, foto: string, detallesAsistencia: any, detallesLicenciaParcial: any) => {
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <Avatar style={{ marginRight: '5px' }} src={`${foto}`} alt="logo" />
          {apellido} {nombre}
        </Box>
        {estadoAsistencia === 'FALTA_CON_LICENCIA' ?
          <Box display="flex" mt={2} style={{ color: '#99BA82' }}>
            Tiene licencia
          </Box>
          : null
        }
        {estadoAsistencia === 'PRESENTE' && detallesLicenciaParcial !== undefined ?
          <Box display="flex" mt={2} style={{ color: '#99BA82' }}>
            Tiene licencia parcial
          </Box>
          : null
        }
      </Box>
    );
  }

  const renderAssistanceCell = (studentId: string, estadoAsistencia: string, detallesLicenciaParcial: any) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        {estadoAsistencia === 'PRESENTE' && detallesLicenciaParcial === undefined ?
          <Button startIcon={<img style={{ marginTop: '0px' }} src={Presente} alt="presente icon" />}>
          </Button> : null
        }
        {estadoAsistencia === 'FALTA_SIN_LICENCIA' ?
          <Button startIcon={<img style={{ marginTop: '0px' }} src={FaltaSinLicencia} alt="falta sin licencia icon" />}>
          </Button> : null
        }
        {estadoAsistencia === 'FALTA_CON_LICENCIA' || (estadoAsistencia === 'PRESENTE' && detallesLicenciaParcial !== undefined) ?
          <Button startIcon={<img style={{ marginTop: '0px' }} src={FaltaConLicencia} alt="falta con licencia icon" />}>
          </Button> : null
        }
      </Box>
    );
  }

  const renderLateAttendanceCell = (studentId: string, retrasoEntrada: number, retrasoSalida: number) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        {retrasoEntrada > 0 || retrasoSalida > 0 ?
          <React.Fragment>
            <Button startIcon={<img style={{ marginTop: '0px' }} src={RelojActivo} alt="reloj activo icon" />}>
            </Button>
            <div style={{ display: 'inline-block' }}>
              {`Entrada: ${retrasoEntrada} minutos`}
              <br />
              {`Salida: ${retrasoSalida} minutos`}
            </div>
          </React.Fragment>
          :
          <Button startIcon={<img style={{ marginTop: '0px' }} src={RelojInactivo} alt="reloj inactivo icon" />}>
          </Button>
        }
      </Box>
    );
  }

  const handleClose = () => {
    setModalAssistanceForm(false);
    setDisplayLateAttendance(false);
  };

  const handleCloseLicense = () => {
    setModalLicense(false);
  };

  const handleDisplayLateAttendance = (val: boolean) => {
    setDisplayLateAttendance(val);
  }

  const handleSetModal = (idEstudiante: string, retrasoEntrada: number, retrasoSalida: number) => {
    setModalAssistanceForm(true)
    handleSetSelectedStudentId(idEstudiante);
    handleSetInStudentDelay(retrasoEntrada);
    handleSetOutStudentDelay(retrasoSalida);

  }

  const handleSetSelectedStudentId = (idStudent: string) => {
    setSelectedStudentId(idStudent);
  }

  const handleSetInStudentDelay = (time: number) => {
    setInStudentDelay(time);
  }

  const handleSetOutStudentDelay = (time: number) => {
    setOutStudentDelay(time);
  }

  const saveAbsent = () => {
    const student = asistenciaKinder.find(el => el.idEstudiante === selectedStudentId);
    const index = asistenciaKinder.findIndex(el => el.idEstudiante === selectedStudentId);
    const studentAttendanceUpdate = { ...student, 'estadoAsistencia': 'FALTA_SIN_LICENCIA' }
    const attendanceUpdate: any = [...asistenciaKinder.slice(0, index), studentAttendanceUpdate, ...asistenciaKinder.slice(index + 1)];

    console.log('Estado de asistencia :: ', attendanceUpdate);
    if (selectedDate)
      dispatch(updateAssistance(attendanceUpdate, selectedDate.format('YYYY-MM-DD')));

    message.success(I18n.t(`kinder.asistenciaRegistrada`));

    setTimeout(() => {
      dispatch(getKinderAssistance(selectedDate));
    }, 1000)

    handleClose();

  }

  const savePresent = () => {
    const student = asistenciaKinder.find(el => el.idEstudiante === selectedStudentId);
    const index = asistenciaKinder.findIndex(el => el.idEstudiante === selectedStudentId);
    const studentAttendanceUpdate = { ...student, 'estadoAsistencia': 'PRESENTE' }
    const attendanceUpdate: any = [...asistenciaKinder.slice(0, index), studentAttendanceUpdate, ...asistenciaKinder.slice(index + 1)];

    console.log('Estado de asistencia :: ', attendanceUpdate);

    if (selectedDate)
      dispatch(updateAssistance(attendanceUpdate, selectedDate.format('YYYY-MM-DD')));

    message.success(I18n.t(`kinder.asistenciaRegistrada`));

    setTimeout(() => {
      dispatch(getKinderAssistance(selectedDate));
    }, 1000)

    handleClose();
  }

  const saveDelay = (inHours: number, inMinutes: number, outHours: number, outMinutes: number) => {
    const student = asistenciaKinder.find(el => el.idEstudiante === selectedStudentId);
    const index = asistenciaKinder.findIndex(el => el.idEstudiante === selectedStudentId);
    const studentAttendanceUpdate = { ...student, 'estadoAsistencia': 'PRESENTE', 'retrasoEntrada': (inHours * 60) + inMinutes, 'retrasoSalida': (outHours * 60) + outMinutes }
    const attendanceUpdate: any = [...asistenciaKinder.slice(0, index), studentAttendanceUpdate, ...asistenciaKinder.slice(index + 1)];

    console.log('Estado de asistencia :: ', attendanceUpdate);
    if (selectedDate)
      dispatch(updateAssistance(attendanceUpdate, selectedDate.format('YYYY-MM-DD')));


    message.success(I18n.t(`kinder.asistenciaRegistrada`));

    setTimeout(() => {
      dispatch(getKinderAssistance(selectedDate));
    }, 1000)

    handleClose();
  }

  return (
    <MainLayout>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box mb={3}>
        <Button
          variant="text"
          startIcon={<ArrowBackIosIcon />}
          onClick={() => history.goBack()}>
          <Translate value="kinder.volverMenu" />
        </Button>
      </Box>

      <Paper className={classes.root} style={{ marginLeft: '30px', marginRight: '30px' }}>
        <Paper>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="h5">
                      <strong>
                        <Translate value="kinder.fecha" />:&nbsp;
                      </strong>
                      <Localize value={selectedDate ? selectedDate : moment()} dateFormat='date.long' />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Button disabled startIcon={<img style={{ marginTop: '0px' }} src={Presente} alt="presente icon" />}> </Button>
                      <Translate value="kinder.estudiantesPresentes" />: {presentCount}
                    </Box>
                    <Box>
                      <Button disabled startIcon={<img style={{ marginTop: '0px' }} src={FaltaSinLicencia} alt="falta sin licencia icon" />}></Button>
                      <Translate value="kinder.estudiantesFaltaSinLicencia" />: {noLicenseCount}
                    </Box>
                    <Box>
                      <Button disabled startIcon={<img style={{ marginTop: '0px' }} src={FaltaConLicencia} alt="falta con licencia icon" />}></Button>
                      <Translate value="kinder.estudiantesFaltaConLicencia" />: {licenseCount}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Translate value={`kinder.col${column.label}`} />

                    </TableCell>

                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index} className={row['estadoAsistencia'] === 'FALTA_CON_LICENCIA' || (row['estadoAsistencia'] === 'PRESENTE' && row['detallesLicenciaParcial'] !== undefined) ? classes.greenRow : ''}
                      onClick={() => {
                        if (row['estadoAsistencia'] === 'FALTA_CON_LICENCIA') {

                          handleSetIsPartial(false);
                          handleSetLicenseDetails(row['detallesLicencia']);
                          handleSetModalLicense(true);

                        }
                        else if (row['estadoAsistencia'] === 'PRESENTE' && row['detallesLicenciaParcial'] !== undefined) {

                          handleSetIsPartial(true);
                          handleSetPartialLicenseDetails(row['detallesLicenciaParcial']);
                          handleSetModalLicense(true);

                        }
                        else
                          handleSetModal(row['idEstudiante'], row['retrasoEntrada'], row['retrasoSalida'])
                      }}>
                      {columns.map((column) => {

                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === 'idEstudiante' ? renderStudentCell(row[column.id], row['nombre'], row['apellido'], row['estadoAsistencia'], row['foto'], row['detallesLicencia'], row['detallesLicenciaParcial']) : null}
                            {column.id === 'estadoAsistencia' ? renderAssistanceCell(row[column.id], row['estadoAsistencia'], row['detallesLicenciaParcial']) : null}
                            {column.id === 'retraso' ? renderLateAttendanceCell(row[column.id], row['retrasoEntrada'], row['retrasoSalida']) : null}
                            {/*column.id==='asistencia' ? <> {value} </>: ''}
                            {column.id==='retraso' ? <> {value} </>: ''} */}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 50, 100, 250]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={<Translate value="tabla.filasPorPagina" />}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${I18n.t('tabla.de')} ${count !== -1 ? count : `${I18n.t('tabla.masDe')} ${to}`}`}
          />
        </Paper>
      </Paper>
      <ModalAssistance
        handleClose={handleClose}
        isModalOpen={modalAssistanceForm}
        lateAttendance={displayLateAttendance}
        setDisplayLateAttendance={handleDisplayLateAttendance}
        handleAbsentWithoutLicense={saveAbsent}
        handlePresent={savePresent}
        handleSaveDelay={saveDelay}
        inStudentDelay={inStudentDelay}
        outStudentDelay={outStudentDelay}
      />
      <ModalLicenseDetails
        handleClose={handleCloseLicense}
        isModalOpen={modalLicense}
        licenseDetails={licenseDetails}
        partialLicenseDetails={partialLicenseDetails}
        isPartial={isPartial}
      />
    </MainLayout>
  );
};

const useStyles = makeStyles({
  root: {
    //width: "100%",
    position: 'relative',
    minWidth: '600px',
  },
  container: {
    // maxHeight: 440,
    // maxHeight: 'calc(100vh - 350px)',
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'left',
    height: 0,
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'height .5s, marginTop .5s',
    background: '#eee',
    paddingLeft: '16px',
  },
  filterBoxOpen: {
    overflowX: 'auto',
    height: '64px',
  },
  btnAction: {
    margin: "0 3px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  formControl: {
    width: "160px",
    padding: "0 0 0 20px",
  },
  input: {
    margin: 0,
  },
  filterItem: {
    minWidth: '190px',
    margin: '0 16px',
  },
  resaltado: {
    backgroundColor: '#ffcccc',
  },
  itemContainer: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 4px 0 0',
  },
  chip: {
    zIndex: 2,
  },
  greenRow: {
    backgroundColor: '#d9e5d0',
    //opacity: '0.25'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

export default KinderAttendance;
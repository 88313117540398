import { Redirect, Route, Switch } from "react-router-dom"
import LoginScreen from "../views/auth/Login"

const AuthRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path="/auth/login"
        component={ LoginScreen }
      />
      <Redirect to="/auth/login" />
    </Switch>
  )
}

export default AuthRouter

import http from './http';

export const allProfesorsRepository = async (isKinder?:boolean) => {
  try {
    let filters = [];

    if (isKinder) {
      filters.push(`kinder=true`)
    }

    return await http.get(`/profesor${filters.length ? ('?' + filters.join('&')) : ''}`);
  } catch (error) {
    console.error('Error > ',error);
    return error;
  }
}

export const createProfesorRepository = async (data: any) => {
  try {
    return await http.post(`/profesor/`, data);
  } catch (error) {
    console.error('Ocurrio un error al guardar profesor :: ', error);
    return error;
  }
}
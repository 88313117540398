import { getSkills, saveKinderSkills } from '../../repositories/competencias_repository';
import { SKILLS_TYPES } from "store/types";
import { SkillsType } from "models/competencias_model";

export const getKinderSkills = () => {
  return async (dispatch: any) => {
    const skills: any = await getSkills();
    dispatch( setSkills(skills || []) );
  }
};

export const setSkills = (skills:SkillsType) => ({
  type: SKILLS_TYPES.GET_KINDER_SKILLS,
  payload: [...skills]
});

export const saveSkills = (data:any) => {
  return async (dispatch: any) => {
    await saveKinderSkills(data);
  }
}
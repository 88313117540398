import { TextField, Box, DialogContent, Grid, Typography, Button, DialogActions, Theme, makeStyles, createStyles } from '@material-ui/core';
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import { getAllData } from '../../../../../repositories/base_repository';
import Util from '../../../../../plugins/utils';
import { RootState, useStateSelector } from 'store/reducers';
import swal from 'sweetalert';
import { I18n, Translate } from 'react-redux-i18n';

type StepPadreProps = {
  handleBack: (data: unknown) => void;
  handleClose: () => void;
  handleSave: (data: unknown) => void;
}

type StepPadreData = {
  id: string;
  email: string;
  password: string;
  codigoPadre: string;
  nombres: string;
  primerApellido: string;
  segundoApellido: string;
  nombreCorto: string;
  celular: string;
  direccion: string;
  ci: string;
  ciudad: string;
  usuarioId: string;
  estado?: string;

  padre2_id: string;
  padre2_email: string;
  padre2_password: string;
  padre2_codigoPadre: string;
  padre2_nombres: string;
  padre2_primerApellido: string;
  padre2_segundoApellido: string;
  padre2_nombreCorto: string;
  padre2_celular: string;
  padre2_direccion: string;
  padre2_ci: string;
  padre2_ciudad: string;
  padre2_usuarioId: string;
  padre2_estado?: string;
  // Kinder contact
  familiar_nombre?: string;
  familiar_parentesco?: string;
  familiar_contacto?: string;
}

export const StepPadre: React.FC<StepPadreProps> = ({
  handleBack,
  handleClose,
  handleSave,
}) => {
  const { formEstudiante } = useStateSelector((state: RootState) => state.estudianteState);
  const { handleSubmit, control, setValue, getValues } = useForm<StepPadreData>({
    shouldUnregister: false
  });
  const classes = useStyles();
  const [padres, setPadres] = useState([]);
  const [padre, setPadre] = useState<any | null>(null);
  const [padre2, setPadre2] = useState<any | null>(null);

  const getAllPadres = async () => {
    const _padres: any = await getAllData('padre');
    setPadres(_padres);
    if (formEstudiante?.padre?.id) {
      const padre = _padres.find((p: any) => (formEstudiante.padre.id === p.id));
      handleChangePadre(null, padre);
    }
    if (formEstudiante?.padre2?.id) {
      const padre2 = _padres.find((p: any) => (formEstudiante.padre2.id === p.id));
      handleChangePadre2(null, padre2);
    }
  }

  useEffect(() => {
    if (formEstudiante?.padre) {
      setValue('id', formEstudiante.padre.id);
      setValue('email', formEstudiante.padre.email);
      setValue('password', formEstudiante.padre.password)
      setValue('codigoPadre', formEstudiante.padre.codigoPadre);
      setValue('nombres', formEstudiante.padre.nombres);
      setValue('primerApellido', formEstudiante.padre.primerApellido);
      setValue('segundoApellido', formEstudiante.padre.segundoApellido);
      setValue('nombreCorto', formEstudiante.padre.nombreCorto);
      setValue('celular', formEstudiante.padre.celular);
      setValue('direccion', formEstudiante.padre.direccion);
      setValue('ci', formEstudiante.padre.ci);
      setValue('ciudad', formEstudiante.padre.ciudad);
      setValue("estado", formEstudiante.padre.estado);
    }
    if (formEstudiante?.padre2) {
      setValue('padre2_id', formEstudiante.padre2.id);
      setValue('padre2_email', formEstudiante.padre2.email);
      setValue('padre2_password', formEstudiante.padre2.password)
      setValue('padre2_codigoPadre', formEstudiante.padre2.codigoPadre);
      setValue('padre2_nombres', formEstudiante.padre2.nombres);
      setValue('padre2_primerApellido', formEstudiante.padre2.primerApellido);
      setValue('padre2_segundoApellido', formEstudiante.padre2.segundoApellido);
      setValue('padre2_nombreCorto', formEstudiante.padre2.nombreCorto);
      setValue('padre2_celular', formEstudiante.padre2.celular);
      setValue('padre2_direccion', formEstudiante.padre2.direccion);
      setValue('padre2_ci', formEstudiante.padre2.ci);
      setValue('padre2_ciudad', formEstudiante.padre2.ciudad);
      setValue('padre2_estado', formEstudiante.padre2.estado);
    }

    if (formEstudiante?.familiar) {
      setValue('familiar_nombre', formEstudiante.familiar.nombre);
      setValue('familiar_parentesco', formEstudiante.familiar.parentesco);
      setValue('familiar_contacto', formEstudiante.familiar.contacto);
    }

    getAllPadres(); // eslint-disable-next-line
  }, [formEstudiante, setValue]);

  const onSubmit = async (data: any) => {
    if (data.padre2_email) {
      if (!data.padre2_id && !data.padre2_password) {
        return mensajeError('Se requiere la contraseña para el tutor 2')
      }
      if (!data.padre2_codigoPadre) {
        return mensajeError('Se requiere el código de padre para el tutor 2')
      }
      if (!data.padre2_codigoPadre) {
        return mensajeError('Se requiere el nombre para el tutor 2')
      }
      if (!data.padre2_codigoPadre) {
        return mensajeError('Se requiere el primer apellido para el tutor 2')
      }
    }
    if (data.email && data.padre2_email && data.email.trim() === data.padre2_email.trim()) {
      return mensajeError('Los correos electrónicos del Tutor 1 y Tutor 2 deben ser diferentes')
    }

    if (data.familiar_nombre && (!data.familiar_parentesco || !data.familiar_contacto)) {
      return mensajeError('Todos los campos de familar 3 deben estar llenos si desea guardar la información')
    }

    if (data.familiar_parentesco && (!data.familiar_nombre || !data.familiar_contacto)) {
      return mensajeError('Todos los campos de familar 3 deben estar llenos si desea guardar la información')
    }

    if (data.familiar_contacto && (!data.familiar_nombre || !data.familiar_parentesco)) {
      return mensajeError('Todos los campos de familar 3 deben estar llenos si desea guardar la información')
    }

    handleSave(data);
  }

  const mensajeError = (msg: string) => {
    swal({
      title: "¡Error!",
      text: msg,
      icon: "error",
      buttons: {
        confirm: {
          text: "Aceptar",
        },
      },
    });
  }

  const handleChangePadre = async (event: any, newValue: any | null) => {
    setPadre(newValue);
    setValue('id', newValue?.id);
    if (newValue && newValue.usuario) {
      setValue('email', newValue.usuario.email)
      setValue('password', newValue.usuario.password)
      setValue('codigoPadre', newValue.codigoPadre)
      setValue('nombres', newValue.usuario.nombres)
      setValue('primerApellido', newValue.usuario.primerApellido)
      setValue('segundoApellido', newValue.usuario.segundoApellido)
      setValue('nombreCorto', newValue.usuario.nombreCorto)
      setValue('celular', newValue.usuario.celular)
      setValue('direccion', newValue.usuario.direccion)
      setValue('ci', newValue.usuario.ci)
      setValue('ciudad', newValue.usuario.ciudad)
      setValue('estado', newValue.usuario.estado);
    }
  }

  const handleChangePadre2 = async (event: any, newValue: any | null) => {
    setPadre2(newValue);
    setValue('padre2_id', newValue?.id);
    if (newValue && newValue.usuario) {
      setValue('padre2_email', newValue.usuario.email)
      setValue('padre2_password', newValue.usuario.password)
      setValue('padre2_codigoPadre', newValue.codigoPadre)
      setValue('padre2_nombres', newValue.usuario.nombres)
      setValue('padre2_primerApellido', newValue.usuario.primerApellido)
      setValue('padre2_segundoApellido', newValue.usuario.segundoApellido)
      setValue('padre2_nombreCorto', newValue.usuario.nombreCorto)
      setValue('padre2_celular', newValue.usuario.celular)
      setValue('padre2_direccion', newValue.usuario.direccion)
      setValue('padre2_ci', newValue.usuario.ci)
      setValue('padre2_ciudad', newValue.usuario.ciudad)
      setValue('padre2_estado', newValue.usuario.estado);
    }
  }

  const clickBack = async () => {
    const currentValues = getValues();
    if (currentValues) {
      return handleBack(currentValues);
    }
    handleBack(null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Box display='flex' justifyContent='center' mb={2}>
          <Box className={classes.activeStep}></Box>
          <Box className={classes.activeStep}></Box>
          <Box className={classes.inactiveStep}></Box>
        </Box>
        <Typography variant='h6' style={{ fontWeight: 'bold' }}>
          <Translate value="datosDelFamiliar" /> 1
        </Typography>
        <Box mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Autocomplete
                options={padres}
                autoHighlight
                getOptionLabel={(option) => option.usuario ? `${Util.buildDisplayName(option.usuario)}` : ''}
                renderOption={(option) => (
                  <>
                    {option.usuario ? `${Util.buildDisplayName(option.usuario)}` : ''}
                  </>
                )}
                getOptionSelected={(option, value) => { return true }}
                value={padre}
                onChange={handleChangePadre}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={I18n.t('form.seleccionaUnFamiliar')}
                    variant="filled"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} autoComplete={'new-password'} required className={classes.formControl} variant='filled' label="Correo electrónico" />}
              />
            </Grid>
            {!padre && (
              <Grid item xs={12} sm={4}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} autoComplete={'new-password'} disabled={!!padre} className={classes.formControl} required variant='filled' label="Contraseña" type="password" />}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <Controller
                name="codigoPadre"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} required className={classes.formControl} variant='filled' label="Código de padre" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="nombres"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} required className={classes.formControl} variant='filled' label="Nombre(s)" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="primerApellido"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} required className={classes.formControl} variant='filled' label="Primer apellido" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="segundoApellido"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Segundo apellido" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="nombreCorto"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Nombre corto" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="celular"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Celular" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="direccion"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Dirección" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="ci"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Cédula de identidad" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="ciudad"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Ciudad" />}
              />
            </Grid>
          </Grid>
        </Box>
        <Typography variant='h6' style={{ fontWeight: 'bold' }}>
          <Translate value="datosDelFamiliar" /> 2
        </Typography>
        <Box mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Autocomplete
                options={padres}
                autoHighlight
                getOptionLabel={(option) => option.usuario ? `${Util.buildDisplayName(option.usuario)}` : ''}
                renderOption={(option) => (
                  <>
                    {option.usuario ? `${Util.buildDisplayName(option.usuario)}` : ''}
                  </>
                )}
                getOptionSelected={(option, value) => { return true }}
                value={padre2}
                onChange={handleChangePadre2}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={I18n.t('form.seleccionaUnFamiliar')}
                    variant="filled"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="padre2_email"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} autoComplete={'new-password'} required={false} className={classes.formControl} variant='filled' label="Correo electrónico *" />}
              />
            </Grid>
            {!padre2 && (
              <Grid item xs={12} sm={4}>
                <Controller
                  name="padre2_password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} autoComplete={'new-password'} disabled={!!padre2} className={classes.formControl} required={false} variant='filled' label="Contraseña *" type="password" />}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <Controller
                name="padre2_codigoPadre"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} required={false} className={classes.formControl} variant='filled' label="Código de padre *" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="padre2_nombres"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} required={false} className={classes.formControl} variant='filled' label="Nombre(s) *" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="padre2_primerApellido"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} required={false} className={classes.formControl} variant='filled' label="Primer apellido *" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="padre2_segundoApellido"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Segundo apellido" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="padre2_nombreCorto"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Nombre corto" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="padre2_celular"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Celular" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="padre2_direccion"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Dirección" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="padre2_ci"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Cédula de identidad" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="padre2_ciudad"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Ciudad" />}
              />
            </Grid>
          </Grid>
        </Box>
        <Typography variant='h6' style={{ fontWeight: 'bold' }}>
          <Translate value="datosDelFamiliar" /> 3
        </Typography>
        <Box mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Controller
                name="familiar_nombre"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} required={false} className={classes.formControl} variant='filled' label="Nombre completo" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="familiar_parentesco"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} required={false} className={classes.formControl} variant='filled' label="Parentesco" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="familiar_contacto"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Teléfono - celular" />}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={clickBack} className={classes.btnBack}>
          Atrás
        </Button>
        <Button onClick={handleClose} className={classes.btnError}>
          Cancelar
        </Button>
        <Button type="submit" className={classes.btnSave}>
          Aceptar y Continuar
        </Button>
      </DialogActions>
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
    stepRoot: {
      display: 'flex',
      justifyContent: 'center',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: theme.palette.primary.main
    },
    activeStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%'
    },
    inactiveStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.grey[400],
      borderRadius: '50%'
    },
    label: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black
    },
    btnBack: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.grey[400],
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  })
);
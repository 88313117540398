import { TextField, Box, DialogContent, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Button, DialogActions, Theme, makeStyles, createStyles, IconButton, useTheme } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {Close as CloseIcon, Delete as DeleteIcon, AddCircle as AddIcon} from '@material-ui/icons'
import DateFnsUtils from "@date-io/date-fns";
import React, { useEffect } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux';
import { Curso } from '../../../../models/curso_model';
import { setFormCurso } from '../../../../store/actions/curso_actions';
import esl from 'date-fns/locale/es';
import { IProfesor } from 'models/profesor_model';
import { RootState, useStateSelector } from 'store/reducers';
import { cargarProfesores } from 'store/actions/profesor';
import { HorarioType } from 'models/materia_model';
// import { useStateSelector } from '../../../../store/reducers';

type FormStepOneProps = {
  handleClose: () => void;
  handleSave: () => void;
  handleSaveKinder: (data: any) => void;
  curso: Curso | null;
}

type FormStepOneData = {
  gestion: number;
  codigoCurso: string;
  nivel: string;
  grado: string;
  turno: string;
  paralelo: string;
  // Kinder props
  fechaInicio: Date;
  fechaFin: Date;
  profesorId: string;
  jefeCursoId?: string;
  horarios: HorarioType[]
}

const FormStepOne: React.FC<FormStepOneProps> = ({
  handleClose,
  handleSave,
  handleSaveKinder,
  curso
}) => {
  const { handleSubmit, control, setValue, watch } = useForm<FormStepOneData>({
    shouldUnregister: false
  });
  const classes = useStyles();
  const { profesors } = useStateSelector((state: RootState) => state.profesorState);
  const dispatch = useDispatch();
  const theme = useTheme();
  const currentLevel = watch("nivel", '');
  // const { stepForm } = useStateSelector((state: RootState) => state.cursoState);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'horarios',
  });

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => console.log(value, name, type));
    return () => subscription.unsubscribe();
  }, [watch]); 

  useEffect(() => {
    if(currentLevel === 'KINDER')
      dispatch( cargarProfesores(true) );
    else
      dispatch( cargarProfesores() );

    if (curso) {
      setTimeout(() => {
        setValue("grado", curso.grado.toString());
        setValue("nivel", curso.nivel);
        setValue("gestion", curso.gestion);
        setValue("codigoCurso", curso.codigoCurso);
        setValue("paralelo", curso.paralelo);
        setValue("turno", curso.turno);
        setValue("fechaInicio", curso.fechaInicio);
        setValue("fechaFin", curso.fechaFin);
        setValue("profesorId", curso.profesorId);
        setValue("jefeCursoId", curso.jefeCursoId);
        setValue('horarios', (curso.horarios || []).map(h => ({ ...h })));
        
      }, 300);
    }
  }, [dispatch, curso, setValue]);

  useEffect(() => {

    if(currentLevel === 'KINDER')
      dispatch( cargarProfesores(true) );

  }, [currentLevel]);

  const onSubmit = async (data: any) => {
    console.log('Enviando data de paso 1 para curso ::', data);
    await dispatch( setFormCurso(data) );

    if(currentLevel === 'KINDER')
      handleSaveKinder(data);
    else
      handleSave();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Box display='flex' justifyContent='center' mb={2}>
          <Box className={classes.activeStep}></Box>
          <Box className={classes.inactiveStep}></Box>
          {/* <Box className={classes.inactiveStep}></Box> */}
        </Box>
        <Typography variant='body2'>
          Por favor, completa los siguientes campos y luego presiona continuar.
        </Typography>
        <Box my={3}>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                1. Selección del nivel:
              </Typography>
              <Controller
                name="nivel"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel>-Selecciona un nivel-</InputLabel>
                    <Select {...field} required>
                      <MenuItem value={"KINDER"}>Kinder</MenuItem>
                      <MenuItem value={"PRIMARIA"}>Primaria</MenuItem>
                      <MenuItem value={"SECUNDARIA"}>Secundaria</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                2. Selección del grado:
              </Typography>
              <Controller
                name="grado"
                control={control}
                defaultValue=""
                render={({ field }) =>
                  <TextField
                    type='number'
                    required
                    inputProps={{step: 1, min: 0, max: 6}}
                    {...field}
                    className={classes.formControl}
                    variant='filled'
                    label="-Seleccione un grado-"
                  />
                }
              />
              {/* <Controller
                name="grado"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel>-Selecciona un grado-</InputLabel>
                    <Select {...field}>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                  </FormControl>
                )}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                3. Selección del turno:
              </Typography>
              <Controller
                name="turno"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel>-Selecciona un turno-</InputLabel>
                    <Select {...field} required>
                      <MenuItem value={"MAÑANA"}>Mañana</MenuItem>
                      <MenuItem value={"TARDE"}>Tarde</MenuItem>
                      <MenuItem value={"NOCHE"}>Noche</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                4. Selección del paralelo:
              </Typography>
              <Controller
                name="paralelo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    className={classes.formControl}
                    variant="filled"
                    label="-Escribe aquí el paralelo-"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                5. Gestión
              </Typography>
              <Controller
                name="gestion"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    type="number"
                    inputProps={{ step: 1, min: 2021, max: 2050 }}
                    {...field}
                    required
                    className={classes.formControl}
                    variant="filled"
                    label="-Selecciona la gestión-"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                6. Código del curso:
              </Typography>
              <Controller
                name="codigoCurso"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    className={classes.formControl}
                    required
                    variant="filled"
                    label="-Escribe aquí el código del curso-"
                  />
                )}
              />
            </Grid>
              { 
                currentLevel === 'KINDER' ? 
                <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <Typography gutterBottom style={{ fontWeight: "bold" }}>
                      7. Selección de la fecha de inicio:
                    </Typography>
                    <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                      <Controller
                        name="fechaInicio"
                        control={control}
                        defaultValue={null}
                        render={({ field: { ref, ...rest } }) => (
                          <KeyboardDatePicker
                            fullWidth
                            inputVariant="filled"
                            required
                            label="-Selecciona una fecha-"
                            autoOk
                            openTo="month"
                            views={["year", "month", "date"]}
                            format="dd/MM/yyyy"
                            {...rest}
                          />
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography gutterBottom style={{ fontWeight: "bold" }}>
                      8. Selección de la fecha de fin:
                    </Typography>
                    <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                      <Controller
                        name="fechaFin"
                        control={control}
                        defaultValue={null}
                        render={({ field: { ref, ...rest } }) => (
                          <KeyboardDatePicker
                            fullWidth
                            inputVariant="filled"
                            required
                            label="-Selecciona una fecha-"
                            autoOk
                            openTo="month"
                            views={["year", "month", "date"]}
                            format="dd/MM/yyyy"
                            {...rest}
                          />
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography gutterBottom style={{ fontWeight: "bold" }}>
                      9. Selecciona un profesor:
                    </Typography>
                    <Controller
                      name="jefeCursoId"
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel>-Selecciona un profesor/jefe de curso-</InputLabel>
                          <Select {...field}>
                            <MenuItem value={''}>
                              NINGUNO
                            </MenuItem>
                            { profesors.map((profesor: IProfesor) => (
                              <MenuItem value={profesor.id} key={profesor.id}>
                                {profesor.nombre}
                              </MenuItem>
                            )) }
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography gutterBottom style={{ fontWeight: "bold" }}>
                      10. Selecciona un profesor:
                    </Typography>
                    <Controller
                      name="profesorId"
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel>-Selecciona un segundo profesor</InputLabel>
                          <Select {...field}>
                            <MenuItem value={''}>
                              NINGUNO
                            </MenuItem>
                            { profesors.map((profesor: IProfesor) => (
                              <MenuItem value={profesor.id} key={profesor.id}>
                                {profesor.nombre}
                              </MenuItem>
                            )) }
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom style={{ fontWeight: "bold" }}>
                      11. Horario:
                    </Typography>
                  </Grid>
                    { fields.map((item, index) => {
                      return (
                        <React.Fragment key={item.id}>
                          <Grid item xs={12} sm={1}>
                            <IconButton size='small' onClick={() => remove(index)} style={{ color: theme.palette.common.black }}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            {/* <FormControl variant="filled" className={classes.formControl}>
                              <InputLabel>-Selecciona un día-</InputLabel>
                              <Select {...register(`horarios.${index}.dia` as const)}>
                                <MenuItem value={"LUNES"}>Lunes</MenuItem>
                                <MenuItem value={"MARTES"}>Martes</MenuItem>
                                <MenuItem value={"MIERCOLES"}>Miércoles</MenuItem>
                                <MenuItem value={"JUEVES"}>Jueves</MenuItem>
                                <MenuItem value={"VIERNES"}>Viernes</MenuItem>
                              </Select>
                            </FormControl> */}
                            <Controller
                              name={`horarios.${index}.dia` as const}
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <FormControl variant="filled" className={classes.formControl}>
                                  <InputLabel>-Selecciona un día- *</InputLabel>
                                  <Select {...field} required>
                                    <MenuItem value={"LUNES"}>Lunes</MenuItem>
                                    <MenuItem value={"MARTES"}>Martes</MenuItem>
                                    <MenuItem value={"MIERCOLES"}>Miércoles</MenuItem>
                                    <MenuItem value={"JUEVES"}>Jueves</MenuItem>
                                    <MenuItem value={"VIERNES"}>Viernes</MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                              <Controller
                                name={`horarios.${index}.horaInicio` as const}
                                control={control}
                                defaultValue={null}
                                render={({ field }) => (
                                  <KeyboardTimePicker
                                      label="Hora de inicio"
                                      fullWidth
                                      autoOk
                                      ampm
                                      required
                                      format="HH:mm"
                                      inputVariant="filled"
                                      {...field}
                                  />
                                )}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                              <Controller
                                name={`horarios.${index}.horaFin` as const}
                                control={control}
                                defaultValue={null}
                                render={({ field }) => (
                                  <KeyboardTimePicker
                                      label="Hora de finalización"
                                      fullWidth
                                      autoOk
                                      ampm
                                      required
                                      format="HH:mm"
                                      inputVariant="filled"
                                      {...field}
                                  />
                                )}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </React.Fragment>
                      )
                    } ) 
                    }
                    <Grid item xs={12}>
                      <IconButton size='small' onClick={() => append({
                        dia: '',
                        horaInicio: '',
                        horaFin: '',
                      })} style={{ color: theme.palette.success.main }}>
                        <AddIcon fontSize='large' />
                      </IconButton>
                    </Grid>
                </React.Fragment>
                : null
              }
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.btnError}>
          Cancelar
        </Button>
        <Button type="submit" className={classes.btnSave}>
          Continuar
        </Button>
      </DialogActions>
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
    stepRoot: {
      display: 'flex',
      justifyContent: 'center',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: theme.palette.primary.main
    },
    activeStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%'
    },
    inactiveStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.grey[400],
      borderRadius: '50%'
    },
    label: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black
    },
  })
);

export default FormStepOne;
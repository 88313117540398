import React, { ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Box, useTheme } from '@material-ui/core';
import { NewReleases as NewReleasesIcon } from '@material-ui/icons';
import { Translate } from 'react-redux-i18n';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type CConfirmProps = {
  openConfirm: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  message: string | ReactNode;
}

const CConfirm: React.FC<CConfirmProps> = ({
  children,
  openConfirm,
  handleCancel,
  handleConfirm,
  message,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={openConfirm}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCancel}
      aria-labelledby="cconfirm-slide-title"
      aria-describedby="cconfirm-slide-description"
    >
      <DialogTitle id="cconfirm-slide-title">
        <Box display='flex' alignItems='center'>
          <NewReleasesIcon style={{ marginRight: theme.spacing(1), color: theme.palette.warning.main }} />
          <Translate value="mensaje.confirmar" />
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="cconfirm-slide-description" style={{ color: theme.palette.common.black }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '16px' }}>
        <Button onClick={handleCancel} style={{ backgroundColor: theme.palette.grey[200] }}>
        <Translate value="form.cancelar" />
        </Button>
        <Button onClick={handleConfirm} variant='contained' color='primary'>
        <Translate value="mensaje.confirmar" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CConfirm;

import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { loginWithEmailAndPassword } from "../../../store/actions/auth_actions";
import AppLogo from '../../../assets/logo/colegio_aleman.png';
import { Translate, I18n, setLocale } from 'react-redux-i18n';
import { RootState, useStateSelector } from 'store/reducers';
import LanguageIcon from '@material-ui/icons/Language';
import packageJson from '../../../../package.json';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ForgotScreen from '../Forgot';

type FormLoginData = {
  email: string;
  password: string;
};

function Copyright() {
  return (
    <Box>
      <Typography variant="body2" color="textSecondary" align="center">
        {" © "}
        {new Date().getFullYear()}{" "}
        <Link color="inherit" href="https://material-ui.com/">
          Educonnect
        </Link>{" "}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <Translate value="version" /> {packageJson.version}
      </Typography>
    </Box>
  );
}

function buildUserLabel() {
  return I18n.t('correoElectronico');
}

function buildPassLabel() {
  return I18n.t('contrasenia');
}

const LoginScreen: React.FC = () => {
  const { locale } = useStateSelector((state: RootState) => state.i18n);
  const [userLabel, setUserLabel] = React.useState(buildUserLabel());
  const [passLabel, setPassLabel] = React.useState(buildPassLabel());
  const [verPaginaRecuperacion, setVerPaginaRecuperacion] = React.useState<boolean>(false);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [inputType, setInputType] = React.useState<string>('password');

  const { handleSubmit, control } = useForm<FormLoginData>({
    shouldUnregister: false,
  });
  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmit = (data: FormLoginData) => {
    dispatch( loginWithEmailAndPassword(data.email, data.password) );
  };

  const handleOnclick = (e: any) => {
    const newLocale = locale === 'es' ? 'de' : 'es';
    localStorage.setItem('locale', newLocale);
    dispatch(setLocale(newLocale));

    setUserLabel(I18n.t('correoElectronico'));
    setPassLabel(I18n.t('contrasenia'));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    setInputType(!showPassword ? 'text' : 'password');
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  if (verPaginaRecuperacion) {
    return <><ForgotScreen volver={setVerPaginaRecuperacion} /></>
  }

  return (
    <Container className={classes.root} component="main" maxWidth="xs">
      <CssBaseline />
      <Box display="flex" justifyContent="center" mb={4}>
        <img src={AppLogo} alt="app icon" width="100px" />
      </Box>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" align='center'>
          <Translate value="iniciarSesion" />
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} margin='normal' fullWidth variant='filled' label={userLabel} required />}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                margin='normal'
                fullWidth
                variant='filled'
                label={passLabel}
                type={inputType}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            <Translate value="iniciarSesion" />
          </Button>
          <Grid container>
            <Grid item xs style={{ textAlign: 'center' }}>
              <Link variant="body2" className={classes.link} onClick={() => setVerPaginaRecuperacion(true)}>
                ¿Olvidaste tu contraseña?
              </Link>
            </Grid>
            {/* <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> */}
          </Grid>
        </form>
      </div>
      <Box mt={2}>
        <Copyright />
      </Box>
      <Button
        className={classes.languageBtn}
        startIcon={<LanguageIcon />}
        onClick={handleOnclick}
      >
        {locale}
      </Button>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: '#c6ba8e69',
    padding: theme.spacing(5, 5, 2, 5),
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // background: '#c6ba8e',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#9a851e',
    color: '#fff',
  },
  link: {
    color: theme.palette.common.black
  },
  languageBtn: {
    position: 'absolute',
    top: 50,
    right: 50,
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 10,
    },
  },
}));

export default LoginScreen;

import http from "./http";
import { IUser } from "../models/user_model";

export const getUsers = async (nombreFilter?: string, emailFilter?: string) => {
  try {
    let filters = []
    if (nombreFilter) {
      filters.push(`nombre=${nombreFilter}`)
    }
    if (emailFilter) {
      filters.push(`email=${emailFilter}`)
    }
    const items = await http.get(`/usuario/${filters.length ? ('?' + filters.join('&')) : ''}`);
    return (items as []).filter((item: any) => item.rol !== 'PADRE')
  } catch (error) {
    console.error("[get_usuarios]  Error -> ", error);
    return [];
  }
};

export const createUser = async (data: IUser) => {
  try {
    return await http.post(`/usuario/`, data);
  } catch (error) {
    console.error("Ocurrio un error al guardar usuario :: ", error);
    return null;
  }
};

export const editUser = async (data: any, id: string) => {
  try {
    return await http.put(`/usuario/${id}`, data);
  } catch (error) {
    console.error("Ocurrio un error al editar usuario :: ", error);
    return null;
  }
};

export const deleteUser = async (id: string) => {
  try {
    return await http.delete(`/usuario/${id}`);
  } catch (error) {
    console.error("Error al eliminar usuario :: ", error);
    return null;
  }
};

export const changePassword = async (newPass: string) => {
  try {
    await http.post('/usuario/cambiarPass', { password: newPass });
    return true;
  } catch (error) {
    console.error("Error al cambiar la contraseña :: ", error);
    return false;
  }
};

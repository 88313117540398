import { TextField, Box, DialogContent, Grid, Typography, FormControl, Button, DialogActions, Theme, makeStyles, createStyles } from '@material-ui/core';
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { Translate } from "react-redux-i18n";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { setCircleOfColorsForm, setCourseTopicForm } from '../../../../store/actions/libro_de_curso';
import message from 'plugins/utils/message';
import moment from 'moment';

const FORMAT = "dd/MM/yyyy";

type FormNewTopicProps = {
  formType: string | null;
  handleClose: () => void;
  handleSave: (data: any) => void;
  handleSaveCircleOfColors: (data: any) => void;
  topicValue: string;
  topicStart: string;
  topicEnd: string;
  colorValue: string;
  colorStart: string;
  colorEnd: string;
}

type FormNewTopicData = {
  tema: string;
  fechaInicio: Date | null;
  fechaFin: Date | null;
}

const FormNewTopic: React.FC<FormNewTopicProps> = ({
  formType,
  handleClose,
  handleSave,
  handleSaveCircleOfColors,
  topicValue,
  topicStart,
  topicEnd,
  colorValue,
  colorStart,
  colorEnd
}) => {
  const { handleSubmit, control, setValue } = useForm<FormNewTopicData>({
    shouldUnregister: false
  });
  
console.log('colorStart')
console.log(formType)
console.log(colorStart)
console.log(colorEnd)
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if(formType === 'topic') {
      if (topicValue) 
        setValue("tema", topicValue);
      else
        setValue("tema", '');

      if (topicStart) {
        let dateStart = new Date(topicStart.replaceAll('-', '/')); 
        setValue("fechaInicio", dateStart);
      }
      else
        setValue("fechaInicio", null);
      
      if (topicEnd) {
        let dateEnd = new Date(topicEnd.replaceAll('-', '/')); 
        setValue("fechaFin", dateEnd);
      }
      else
        setValue("fechaFin", null);
    }
    else {
      if (colorValue) 
        setValue("tema", colorValue);
      else
        setValue("tema", '');
    
      if (colorStart) {
        let dateStart = new Date(colorStart.replaceAll('-', '/')); 
        setValue("fechaInicio", dateStart);
      }
      else
        setValue("fechaInicio", null);
      
      if (colorEnd) {
        let dateEnd = new Date(colorEnd.replaceAll('-', '/')); 
        setValue("fechaFin", dateEnd);
      }
      else
        setValue("fechaFin", null);
    }
    
  }, [ formType, topicValue, topicStart, topicEnd, colorValue, colorStart, colorEnd, setValue]);

  const onSubmit = (data: any) => {
    
    let currentForm = formType === 'topic' ?  'tema' : 'círculo de colores';

    if(!data.fechaInicio || !data.fechaFin) {
      message.error(`No se seleccionó un rango de fechas para el ${currentForm} a crear`);
      return;
    }

    let tmpStart = moment(data.fechaInicio, 'YYYY-MM-DD');
    let tmpEnd = moment(data.fechaFin, 'YYYY-MM-DD');

    if(tmpStart.isAfter(tmpEnd)) {
      message.error(`La fecha de finalización no puede ser anterior a la fecha de inicio, verifique e intente nuevamente`);
      return;
    }

    if(formType === 'topic') {
      console.log('Topic data ::', data);
      dispatch( setCourseTopicForm(data) );
      setTimeout(() => {
        handleSave(data);
      }, 1000);
    }
    else {
      console.log('Colors data ::', data);
      dispatch( setCircleOfColorsForm(data) );
      setTimeout(() => {
        handleSaveCircleOfColors(data);
      }, 1000);
    } 
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Box my={3}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}>
              <Typography gutterBottom style={{  }}/>
              {formType === 'topic'  ? <Translate value="kinder.tituloTema" /> : <Translate value="kinder.tituloCirculo" />}
              <Controller
                name="tema"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    className={classes.formControl}
                    variant="filled"
                    label={formType === 'topic' ? <Translate value="kinder.introduceTituloTema" /> : <Translate value="kinder.introduceTituloCirculo" />}
                  />
                )}
              />
            </Grid>
            <Grid container item xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <Typography gutterBottom style={{  }}>
                  <Translate value="kinder.temaFechaInicioFin" />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl} variant="standard">
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <Controller
                      name="fechaInicio"
                      control={control}
                      defaultValue={null}
                      render={({ field: { ref, ...rest } }) => (
                        <KeyboardDatePicker
                          variant="inline"
                          className={classes.input}
                          id="filtro-desde"
                          label={<Translate value="tabla.desde"/>}
                          autoOk
                          format={FORMAT}
                          inputVariant="standard"
                          {...rest}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <Controller
                    name="fechaFin"
                    control={control}
                    defaultValue={null}
                    render={({ field: { ref, ...rest } }) => (
                      <KeyboardDatePicker
                        variant="inline"
                        className={classes.input}
                        id="filtro-hasta"
                        label={<Translate value="tabla.hasta"/>}
                        autoOk
                        format={FORMAT}
                        inputVariant="standard"
                        {...rest}
                      />
                    )}
                  />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonGroup fullWidth={true} variant="text" aria-label="text primary button group">
          <Button onClick={handleClose}>
            <Translate value="kinder.cancelar" />
          </Button>
          <Button type="submit">
            <Translate value="kinder.guardar" />
          </Button>
        </ButtonGroup>
      </DialogActions>
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
    stepRoot: {
      display: 'flex',
      justifyContent: 'center',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: theme.palette.primary.main
    },
    activeStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%'
    },
    inactiveStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.grey[400],
      borderRadius: '50%'
    },
    label: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black
    },
    input: {
      margin: 0,
    },
    toogleButton: {
      width: 200,
      maxWidth: 200
    }
  })
);

export default FormNewTopic;
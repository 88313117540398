import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, /*IconButton*/ TextField } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Fragment, useState } from "react";
import { Evento } from "../../../../models/evento_model";
import { Controller, useForm } from "react-hook-form";
import datetime from "../../../../plugins/datetime";
import theme from "../../../../plugins/theme";
import moment from 'moment';
import { Translate, I18n } from "react-redux-i18n";

type FormEventoProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: any) => void;
  handleDelete: (data: any) => void;
  evento: Evento | null;
}

const ModalCalendar: React.FC<FormEventoProps> = ({
  isModalOpen,
  handleSave,
  handleClose,
  handleDelete,
  evento
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(!!evento?.dia);
  const [disabled, setDisabled] = useState(!!evento?.id);
  const [checkedFeriado, setCheckedFeriado] = useState(evento?.tipo === 'FERIADO');
  const [checkedInterno, setCheckedInterno] = useState(evento?.tipo === 'INTERNO');

  const { handleSubmit, control, setValue } = useForm<Evento>({
    shouldUnregister: false
  });

  const handleEdit = () => {
    setDisabled(false);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleChangeFeriado = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedFeriado(event.target.checked);
    if (event.target.checked) {
      setChecked(true);
    }
    if (checkedInterno) {
      setCheckedInterno(false);
    }
  };

  const handleChangeInterno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedInterno(event.target.checked);
    if (checkedFeriado) {
      setCheckedFeriado(false);
    }
  };

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    evento && evento.fecha && evento.fecha instanceof Date ? evento.fecha : moment(`${`${evento?.fecha}`.split('T')[0]}`, 'YYYY-MM-DD').toDate()
  );

  const [selectedTime, setSelectedTime] = useState<Date | null>(
    new Date(evento?.horaInicio ? `2000-01-01T${evento?.horaInicio}:00` : new Date().toString())
  );

  const [selectedDateFinal, setSelectedDateFinal] = useState<Date | null>(
    evento && evento.fechaFinal && evento.fechaFinal instanceof Date ? evento.fechaFinal : moment(`${`${evento?.fechaFinal}`.split('T')[0]}`, 'YYYY-MM-DD').toDate()
  );

  const [selectedTimeFinal, setSelectedTimeFinal] = useState<Date | null>(
    new Date(evento?.horaFin ? `2000-01-01T${evento?.horaFin}:00` : new Date().toString())
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (date: Date | null) => {
    setSelectedTime(date);
  };

  const handleDateChangeFinal = (date: Date | null) => {
    setSelectedDateFinal(date);
  };

  const handleTimeChangeFinal = (date: Date | null) => {
    setSelectedTimeFinal(date);
  };

  if (evento?.id) {
    setValue("titulo", evento.titulo)
    setValue("descripcion", evento.descripcion)
  }

  const onSubmit = async (data: any) => {
    const horaInicio = checked ? '00:00' : datetime.format(selectedTime, 'HH:mm')
    const horaFin = checked ? '23:59' : datetime.format(selectedTimeFinal, 'HH:mm')
    const tipoEvento = checkedFeriado ? 'FERIADO' : checkedInterno ? 'INTERNO' : 'NORMAL';
    const item = {
      id: evento?.id,
      titulo: data.titulo,
      descripcion: data.descripcion,
      gestion: new Date().getFullYear(),
      fecha: datetime.format(selectedDate, 'YYYY-MM-dd'),
      fechaFinal: datetime.format(selectedDateFinal, 'YYYY-MM-dd'),
      horaInicio,
      horaFin,
      aula: '',
      dia: checked,
      tipo: tipoEvento,
      estado: 'ACTIVO',
    }
    handleSave(item);
  }

  const onDelete = () => {
    handleDelete(evento?.id)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Grid container justify="space-between">
            <Controller
              name="titulo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.titulo}
                  required
                  disabled={disabled}
                  fullWidth
                  label={I18n.t('form.escribaElTituloDelEvento')} />
              )}
            />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Controller
            name="descripcion"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                multiline
                disabled={disabled}
                rows={4}
                variant="outlined"
                placeholder={I18n.t('form.escribaLaDecripcionDelEvento')} />
            )}
          />
          <FormControlLabel
            value="start"
            control={
              <Checkbox
                checked={checked}
                color="primary"
                disabled={disabled || checkedFeriado}
                onChange={handleChange} />
            }
            label={I18n.t('calendario.todoElDia')}
            labelPlacement="start"
          />
          <FormControlLabel
            value="start2"
            control={
              <Checkbox
                checked={checkedFeriado}
                color="primary"
                disabled={disabled}
                onChange={handleChangeFeriado} />
            }
            label={I18n.t('calendario.feriado')}
            labelPlacement="start"
          />
          <FormControlLabel
            value="start"
            control={
              <Checkbox
                checked={checkedInterno}
                color="primary"
                disabled={disabled}
                onChange={handleChangeInterno} />
            }
            label={I18n.t('calendario.eventoInterno')}
            labelPlacement="start"
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <div className={classes.label}><Translate value="calendario.comienza" />:</div>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                autoOk
                value={selectedDate}
                disabled={disabled}
                onChange={handleDateChange}
              />
              {!checked && (
                <KeyboardTimePicker
                  margin="normal"
                  variant="inline"
                  autoOk
                  required
                  format="HH:mm"
                  value={selectedTime}
                  disabled={disabled || checked}
                  onChange={handleTimeChange}
                />
              )}
            </Grid>
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <div className={classes.label}><Translate value="calendario.termina" />:</div>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                autoOk
                disabled={disabled}
                value={selectedDateFinal}
                onChange={handleDateChangeFinal}
              />
              {!checked && (
                <KeyboardTimePicker
                  margin="normal"
                  variant="inline"
                  autoOk
                  required
                  format="HH:mm"
                  value={selectedTimeFinal}
                  disabled={disabled || checked}
                  hidden={checked}
                  onChange={handleTimeChangeFinal}
                />
              )}
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          {(evento?.id && disabled) && (
            <Fragment>
              <IconButton size='small' onClick={handleEdit}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.9898 18.9533C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533H19.9898ZM16.0299 3.69906L17.5049 4.87078C18.1097 5.34377 18.513 5.96726 18.6509 6.62299C18.8101 7.3443 18.6403 8.0527 18.1628 8.66544L9.3764 20.0279C8.97316 20.5439 8.37891 20.8341 7.74222 20.8449L4.24039 20.8879C4.04939 20.8879 3.89021 20.7589 3.84777 20.5761L3.0519 17.1255C2.91395 16.4912 3.0519 15.8355 3.45514 15.3303L9.68413 7.26797C9.79025 7.13898 9.98126 7.11855 10.1086 7.21422L12.7297 9.29967C12.8994 9.43942 13.1329 9.51467 13.377 9.48242C13.8969 9.41792 14.2471 8.94493 14.1941 8.43969C14.1622 8.1817 14.0349 7.96671 13.8651 7.80546C13.812 7.76246 11.3183 5.76301 11.3183 5.76301C11.1591 5.63401 11.1273 5.39752 11.2546 5.23735L12.2415 3.95706C13.1541 2.78534 14.7459 2.67784 16.0299 3.69906Z" fill={theme.palette.warning.main} />
                </svg>
              </IconButton>
              <IconButton size='small' onClick={onDelete}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.9391 8.69713C19.1384 8.69713 19.3193 8.78413 19.4623 8.93113C19.5955 9.08813 19.6626 9.28313 19.6432 9.48913C19.6432 9.55712 19.1102 16.2971 18.8058 19.134C18.6152 20.875 17.4929 21.932 15.8094 21.961C14.5149 21.99 13.2496 22 12.0038 22C10.6811 22 9.38763 21.99 8.13206 21.961C6.50498 21.922 5.38168 20.846 5.20079 19.134C4.88763 16.2871 4.36439 9.55712 4.35467 9.48913C4.34494 9.28313 4.41108 9.08813 4.54529 8.93113C4.67756 8.78413 4.86818 8.69713 5.06852 8.69713H18.9391ZM14.0647 2C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647Z" fill={theme.palette.error.main} />
                </svg>
              </IconButton>
            </Fragment>
          )}
          {(evento?.id && !disabled) && (
            <Button onClick={() => (setDisabled(true))}>
              <Translate value="kinder.cancelar" />
            </Button>
          )}
          {(!evento?.id || (evento?.id && !disabled)) && (
            <Button type="submit" className={classes.btnSave}>
              <Translate value="form.guardar" />
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titulo: {
      fontSize: '20px'
    },
    label: {
      padding: '20px 8px 0 0',
      fontSize: '16px'
    },
    btnSave: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
  }),
);

export default ModalCalendar
import { Materia, HorarioType } from "../../models/materia_model";
import { createAllMaterias, createMateria, deleteMateria, editMateria, editHorario, getMaterias } from "../../repositories/materia_repository";
import { MATERIA_TYPES } from "../types";


export const cargarMaterias = (cursoId: string, materiaFilter?: string, siglaFilter?: string) => {
  return async (dispatch: any) => {
    const materias: any = await getMaterias(cursoId, materiaFilter, siglaFilter);
    // console.log('materias :: ', materias)

    dispatch(setMaterias(materias || []));
  }
}

export const agregarMateria = (cursoId: string, data: Materia) => {
  return async (dispatch: any) => {
    await createMateria(cursoId, data);
  }
}

export const agregarTodosMaterias = (cursoId: string, data: Materia[]) => {
  return async (dispatch: any) => {
    await createAllMaterias(cursoId, data);
  }
}

export const editarMateria = (cursoId: string, data: Materia, materiaId: string) => {
  return async (dispatch: any) => {
    await editMateria(cursoId, data, materiaId);
  }
}

export const editarHorario = (cursoId: string, materiaId: string, fechaCambio: string, horarios: HorarioType[]) => {
  return async (dispatch: any) => {
    await editHorario(cursoId, materiaId, fechaCambio, horarios);
  }
}

export const eliminarMateria = (cursoId: string, id: string) => {
  return async (dispatch: any) => {
    await deleteMateria(cursoId, id);
  }
}

export const changeMateria = (materia: Materia | null) => ({
  type: MATERIA_TYPES.SET_MATERIA,
  payload: materia
})

export const setMaterias = (materias: Materia[]) => ({
  type: MATERIA_TYPES.CARGAR_MATERIAS,
  payload: [...materias]
})
import { Tab, createStyles, Tabs, Theme, withStyles } from "@material-ui/core";


export const CTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
}))(Tabs);

interface StyledTabProps {
  label: string;
}

export const CTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[300],
      borderTopRightRadius: 3,
      borderTopLeftRadius: 3,
      textTransform: 'none',
      // minWidth: 72,
      fontSize: 18,
      fontWeight: theme.typography.fontWeightRegular as any,
      marginRight: theme.spacing(1),
      '&:hover': {
        color: theme.palette.common.black,
        opacity: 1,
      },
      '&$selected': {
        backgroundColor: theme.palette.primary.light,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: theme.palette.common.black,
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <Tab disableRipple  {...props} />);
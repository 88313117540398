import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Theme, Typography, useTheme } from '@material-ui/core';
import {Close as CloseIcon, Delete as DeleteIcon, AddCircle as AddIcon} from '@material-ui/icons'
import { KeyboardTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import React, { useEffect } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Materia, HorarioType } from '../../../../../models/materia_model';
import { IProfesor } from '../../../../../models/profesor_model';
import { cargarProfesores } from '../../../../../store/actions/profesor';
import { RootState, useStateSelector } from '../../../../../store/reducers';
import DateFnsUtils from "@date-io/date-fns";
import esl from 'date-fns/locale/es';
import moment from 'moment';

export type FormHorarioData = {
  fechaCambio: string,
  horarios: HorarioType[],
  nombre?: string,
  sigla?: string,
  horarioActual?: HorarioType[],
  profesorId?: string,
};

type FormHorarioProps = {
  cursoId: string;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: FormHorarioData) => void;
  materia: Materia | null
}

const FormHorario: React.FC<FormHorarioProps> = ({
  cursoId,
  isModalOpen,
  handleClose,
  handleSave,
  materia
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control, setValue } = useForm<FormHorarioData>({
    shouldUnregister: false,
    defaultValues: {
      fechaCambio: moment().toISOString(),
      horarios: [{ dia: '', horaInicio: '', horaFin: ''}],
      horarioActual: [{ dia: '', horaInicio: '', horaFin: ''}],
    },
  });

  const horarioActualItem = useFieldArray({
    control,
    name: 'horarioActual',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'horarios',
  });

  const { profesors } = useStateSelector((state: RootState) => state.profesorState)

  useEffect(() => {
    dispatch( cargarProfesores() );
    setTimeout(() => {
      if (materia) {
        // setValue('fechaCambio', moment().format('DD/MM/YYYY'));
        setValue('horarios', (materia.horarios || []).map(h => ({ ...h })));
        setValue('nombre', materia.nombre);
        setValue('sigla', materia.sigla);
        setValue('horarioActual', materia.horarios);
        setValue('profesorId', materia.profesorId);
      }
    }, 200);
  }, [dispatch, materia, setValue])

  const saveData = (data: FormHorarioData) => {
    handleSave({
      fechaCambio: data.fechaCambio,
      horarios: data.horarios,
    });
  }

  const onSubmit = (data: FormHorarioData) => {
    return saveData(data);
  }

  return (
    <>
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      fullWidth
      maxWidth="md"
      disableBackdropClick
    >
      <DialogTitle>
        <Grid container justify="space-between">
          Reasignar horario
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                1. Nombre de la materia:
              </Typography>
              <Controller
                name="nombre"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    variant='filled'
                    fullWidth
                    disabled
                    label='Nombre de la materia'
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                2. Sigla de la materia:
              </Typography>
              <Controller
                name="sigla"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    variant='filled'
                    fullWidth
                    disabled
                    label='Sigla de la materia'
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                3. Profesor:
              </Typography>
              <Controller
                name="profesorId"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel>Profesor de la materia</InputLabel>
                    <Select {...field} disabled>
                      { profesors.length > 0 && profesors.map((profesor: IProfesor) => (
                        <MenuItem value={profesor.id} key={profesor.id}>
                          {profesor.nombre}
                        </MenuItem>
                      )) }
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                4. Horario actual:
              </Typography>
            </Grid>

            { horarioActualItem.fields.map((item: any, index) => {
              return (
                <React.Fragment key={item.id}>
                  <Grid item xs={12} sm={1}>
                    {/* <IconButton size='small' onClick={() => remove(index)} style={{ color: theme.palette.common.black }}>
                      <DeleteIcon />
                    </IconButton> */}
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <Controller
                      name={`horarioActual.${index}.dia` as const}
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel>Dia</InputLabel>
                          <Select {...field} disabled>
                            <MenuItem value={"LUNES"}>Lunes</MenuItem>
                            <MenuItem value={"MARTES"}>Martes</MenuItem>
                            <MenuItem value={"MIERCOLES"}>Miércoles</MenuItem>
                            <MenuItem value={"JUEVES"}>Jueves</MenuItem>
                            <MenuItem value={"VIERNES"}>Viernes</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                      <Controller
                        name={`horarioActual.${index}.horaInicio` as const}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <KeyboardTimePicker
                              label="Hora de inicio"
                              fullWidth
                              autoOk
                              ampm
                              disabled
                              format="HH:mm"
                              inputVariant="filled"
                              {...field}
                          />
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                      <Controller
                        name={`horarioActual.${index}.horaFin` as const}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <KeyboardTimePicker
                              label="Hora de finalización"
                              fullWidth
                              autoOk
                              ampm
                              disabled
                              format="HH:mm"
                              inputVariant="filled"
                              {...field}
                          />
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </React.Fragment>
              )
            } ) }

            <Grid item xs={12}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                5. Nuevo Horario:
              </Typography>
            </Grid>
            { fields.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <Grid item xs={12} sm={1}>
                    <IconButton size='small' onClick={() => remove(index)} style={{ color: theme.palette.common.black }}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    {/* <FormControl variant="filled" className={classes.formControl}>
                      <InputLabel>-Selecciona un día-</InputLabel>
                      <Select {...register(`horarios.${index}.dia` as const)}>
                        <MenuItem value={"LUNES"}>Lunes</MenuItem>
                        <MenuItem value={"MARTES"}>Martes</MenuItem>
                        <MenuItem value={"MIERCOLES"}>Miércoles</MenuItem>
                        <MenuItem value={"JUEVES"}>Jueves</MenuItem>
                        <MenuItem value={"VIERNES"}>Viernes</MenuItem>
                      </Select>
                    </FormControl> */}
                    <Controller
                      name={`horarios.${index}.dia` as const}
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel>-Selecciona un día- *</InputLabel>
                          <Select {...field} required>
                            <MenuItem value={"LUNES"}>Lunes</MenuItem>
                            <MenuItem value={"MARTES"}>Martes</MenuItem>
                            <MenuItem value={"MIERCOLES"}>Miércoles</MenuItem>
                            <MenuItem value={"JUEVES"}>Jueves</MenuItem>
                            <MenuItem value={"VIERNES"}>Viernes</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                      <Controller
                        name={`horarios.${index}.horaInicio` as const}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <KeyboardTimePicker
                              label="Hora de inicio"
                              fullWidth
                              autoOk
                              ampm
                              required
                              format="HH:mm"
                              inputVariant="filled"
                              {...field}
                          />
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                      <Controller
                        name={`horarios.${index}.horaFin` as const}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <KeyboardTimePicker
                              label="Hora de finalización"
                              fullWidth
                              autoOk
                              ampm
                              required
                              format="HH:mm"
                              inputVariant="filled"
                              {...field}
                          />
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </React.Fragment>
              )
            } ) }
            <Grid item xs={12}>
              <IconButton size='small' onClick={() => append({
                dia: '',
                horaInicio: '',
                horaFin: '',
              })} style={{ color: theme.palette.success.main }}>
                <AddIcon fontSize='large' />
              </IconButton>
            </Grid>

            
            <Grid item xs={12}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                6. Fecha en la que entrará en vigencia el nuevo horario:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                <Controller
                  name={`fechaCambio`}
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <KeyboardDatePicker
                      label="Fecha de cambio"
                      variant="inline"
                      format="dd/MM/yyyy"
                      required
                      inputVariant="filled"
                      disablePast
                      autoOk
                      {...field}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.btnError} fullWidth>
            Cancelar
          </Button>
          <Button type="submit" className={classes.btnSave} fullWidth>
            Aceptar y Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      backgroundColor: theme.palette.success.main,
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      width: "100%",
    },
  })
);

export default FormHorario;

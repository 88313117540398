import { Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import FormAttendance from "../FormAttendance";
import FormLateAttendace from '../FormLateAttendance';

type FormCourseProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  lateAttendance: boolean;
  setDisplayLateAttendance: (val:boolean) => void;
  handleAbsentWithoutLicense: () => void;
  handlePresent: () => void;
  handleSaveDelay: (inHours:number, inMinutes:number, outHours:number, outMinutes:number) => void;
  inStudentDelay: number;
  outStudentDelay: number;
}

const ModalAssistance: React.FC<FormCourseProps> = ({
  isModalOpen,
  handleClose,
  lateAttendance,
  setDisplayLateAttendance,
  handleAbsentWithoutLicense,
  handlePresent,
  handleSaveDelay,
  inStudentDelay,
  outStudentDelay
}) => {
  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      fullWidth
      maxWidth="md"
      style={{borderRadius: '6% 6% 6% 6%'}}
    >
      <DialogTitle>
        <Grid container justify="space-between">
          Asistencia
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      {
        !lateAttendance ? 
        <FormAttendance handleClose={handleClose} setDisplayLateAttendance={setDisplayLateAttendance} handleAbsentWithoutLicense={handleAbsentWithoutLicense} handlePresent={handlePresent} /> : 
        <FormLateAttendace inStudentDelay={inStudentDelay} outStudentDelay={outStudentDelay} handleClose={handleClose} handleSaveDelay={handleSaveDelay}/>
      }
    </Dialog>
  );

}

export default ModalAssistance;
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Theme, Typography, useTheme } from '@material-ui/core';
import {Close as CloseIcon, Delete as DeleteIcon, AddCircle as AddIcon} from '@material-ui/icons'
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Materia, HorarioType } from '../../../../../models/materia_model';
import { IProfesor } from '../../../../../models/profesor_model';
import { cargarProfesores } from '../../../../../store/actions/profesor';
import { RootState, useStateSelector } from '../../../../../store/reducers';
import DateFnsUtils from "@date-io/date-fns";
import esl from 'date-fns/locale/es';

type FormMateriaData = {
  nombre: string,
  sigla: string,
  profesorId: string,
  horarios: HorarioType[],
}

type FormMateriaProps = {
  cursoId: string;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: Materia) => void;
  materia: Materia | null
}

const FormMateria: React.FC<FormMateriaProps> = ({
  cursoId,
  isModalOpen,
  handleClose,
  handleSave,
  materia
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [horarioActual, setHorarioActual] = React.useState<any[]>([]);
  const [dataToSave, setDataToSave] = React.useState<any>(null);

  const { handleSubmit, control, setValue } = useForm<FormMateriaData>({
    shouldUnregister: false,
    defaultValues: {
      horarios: [{ dia: '', horaInicio: '', horaFin: ''}]
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'horarios',
  })

  const { profesors } = useStateSelector((state: RootState) => state.profesorState)

  useEffect(() => {
    dispatch( cargarProfesores() );
    setTimeout(() => {
      if (materia) {
        setValue('nombre', materia.nombre);
        setValue('sigla', materia.sigla);
        setValue('profesorId', materia.profesorId);
        setValue('horarios', materia.horarios);
        setHorarioActual(materia.horarios);
      }
    }, 200);
  }, [dispatch, materia, setValue])

  const saveData = (data: FormMateriaData) => {
    handleSave({
      sigla: data.sigla,
      nombre: data.nombre,
      cursoId: cursoId,
      profesorId: data.profesorId,
      horarios: data.horarios,
    });
  }

  const onSubmit = (data: FormMateriaData) => {
    if (horarioActual.length === 0) {
      return saveData(data);
    }
    const valorActual = JSON.stringify(horarioActual.map((h: any) => h.dia).sort());
    const nuevoValor = JSON.stringify(data.horarios.map((h: any) => h.dia).sort());
    if (valorActual !== nuevoValor) {
      setDataToSave(data);
    }
    if (valorActual === nuevoValor) {
      saveData(data);
    }
  }

  const handleCloseConfirm = (result: boolean) => {
    if (result) saveData(dataToSave)
    setDataToSave(null);
  }

  return (
    <>
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      fullWidth
      maxWidth="md"
      disableBackdropClick
    >
      <DialogTitle>
        <Grid container justify="space-between">
          { materia ? 'Editar materia' : 'Agregar materia'}
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                1. Nombre de la materia:
              </Typography>
              <Controller
                name="nombre"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    variant='filled'
                    fullWidth
                    required
                    label='Escribe aquí la materia...'
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                2. Sigla de la materia:
              </Typography>
              <Controller
                name="sigla"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    variant='filled'
                    fullWidth
                    required
                    label='Escribe aquí la sigla...'
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                3. Profesor de la materia:
              </Typography>
              <Controller
                name="profesorId"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel>-Selecciona un profesor- *</InputLabel>
                    <Select {...field} required>
                      <MenuItem value={''}>
                        NINGUNO
                      </MenuItem>
                      { profesors.length > 0 && profesors.map((profesor: IProfesor) => (
                        <MenuItem value={profesor.id} key={profesor.id}>
                          {profesor.nombre}
                        </MenuItem>
                      )) }
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom style={{ fontWeight: "bold" }}>
                4. Horario:
              </Typography>
            </Grid>
            { fields.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <Grid item xs={12} sm={1}>
                    <IconButton size='small' onClick={() => remove(index)} style={{ color: theme.palette.common.black }}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    {/* <FormControl variant="filled" className={classes.formControl}>
                      <InputLabel>-Selecciona un día-</InputLabel>
                      <Select {...register(`horarios.${index}.dia` as const)}>
                        <MenuItem value={"LUNES"}>Lunes</MenuItem>
                        <MenuItem value={"MARTES"}>Martes</MenuItem>
                        <MenuItem value={"MIERCOLES"}>Miércoles</MenuItem>
                        <MenuItem value={"JUEVES"}>Jueves</MenuItem>
                        <MenuItem value={"VIERNES"}>Viernes</MenuItem>
                      </Select>
                    </FormControl> */}
                    <Controller
                      name={`horarios.${index}.dia` as const}
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel>-Selecciona un día- *</InputLabel>
                          <Select {...field} required>
                            <MenuItem value={"LUNES"}>Lunes</MenuItem>
                            <MenuItem value={"MARTES"}>Martes</MenuItem>
                            <MenuItem value={"MIERCOLES"}>Miércoles</MenuItem>
                            <MenuItem value={"JUEVES"}>Jueves</MenuItem>
                            <MenuItem value={"VIERNES"}>Viernes</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                      <Controller
                        name={`horarios.${index}.horaInicio` as const}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <KeyboardTimePicker
                              label="Hora de inicio"
                              fullWidth
                              autoOk
                              ampm
                              required
                              format="HH:mm"
                              inputVariant="filled"
                              {...field}
                          />
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                      <Controller
                        name={`horarios.${index}.horaFin` as const}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <KeyboardTimePicker
                              label="Hora de finalización"
                              fullWidth
                              autoOk
                              ampm
                              required
                              format="HH:mm"
                              inputVariant="filled"
                              {...field}
                          />
                        )}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </React.Fragment>
              )
            } ) }
            <Grid item xs={12}>
              <IconButton size='small' onClick={() => append({
                dia: '',
                horaInicio: '',
                horaFin: '',
              })} style={{ color: theme.palette.success.main }}>
                <AddIcon fontSize='large' />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.btnError} fullWidth>
            Cancelar
          </Button>
          <Button type="submit" className={classes.btnSave} fullWidth>
            Aceptar y Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
    <Dialog
        open={!!dataToSave}
        onClose={handleCloseConfirm}
        aria-labelledby="draggable-dialog-title"
        disableBackdropClick
      >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <WarningIcon /> Confirmar cambios
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Se detectó un cambio en las fechas
        </Typography>
        <Typography variant="body1" gutterBottom>
          Al realizar este cambio se eliminarán de forma permanente los registros (Ej: tareas, anotaciones y asistencias) de aquellas fechas que ya no pertenezcan a este nuevo horario.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ¿Desea continuar?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseConfirm(false)} className={classes.btnError} fullWidth> No </Button>
        <Button onClick={() => handleCloseConfirm(true)} className={classes.btnSave} fullWidth> Si </Button>
      </DialogActions>
    </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      backgroundColor: theme.palette.success.main,
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      width: "100%",
    },
  })
);

export default FormMateria;


import {
  Box,
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Theme,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from "@material-ui/core";
import { Delete as DeleteIcon } from '@material-ui/icons'
import React, { ReactElement, useEffect } from "react";
import { useIsMounted } from "hooks/useIsMounted";
import { Controller, useForm } from "react-hook-form";
import message from "plugins/utils/message";
import { buscarAnotaciones, guardarAnotaciones } from "repositories/clase_repository";
import { I18n, Translate } from 'react-redux-i18n';
import { EstudianteAsistencia } from "./StepAsistencia";
import { getEstudiantesParaAsistencia } from "repositories/estudiante_repository";
import { Autocomplete } from "@material-ui/lab";

type FormData = {
  id?: string;
  categoria: string;
  nombreEstudiante: string;
  estudianteId: string;
  descripcion: string;
};

async function getAnotaciones(claseId: string) {
  const anotacionesEncontradas: any = await buscarAnotaciones(claseId);
  return anotacionesEncontradas;
}

type StepAsistenciaProps = {
  cursoId: string;
  claseId: string;
  materiaId: string;
  onGuardarClick: () => void;
};

const StepAnotacion = ({ cursoId, claseId, materiaId, onGuardarClick }: StepAsistenciaProps): ReactElement => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const isMounted = useIsMounted();

  const [anotar, setAnotar] = React.useState<boolean>(false);

  const [estudiantes, setEstudiantes] = React.useState<EstudianteAsistencia[]>([]);
  const [estudiante, setEstudiante] = React.useState<EstudianteAsistencia | null>(null);

  const [categorias, setCategorias] = React.useState<string[]>([]);
  const [categoria, setCategoria] = React.useState<string | null>(null);

  const [anotaciones, setAnotaciones] = React.useState<FormData[]>([]);
  const { setValue, control, handleSubmit } = useForm<FormData>({ shouldUnregister: false });

  const limpiarFormulario = () => {
      setValue("id", '');
      setValue("categoria", '');
      setValue("descripcion", '');
      setValue("nombreEstudiante", '');
      setValue("estudianteId", '');
      setEstudiante(null);
  };

  const cargarAnotacionList = async () => {
    setLoading(true);
    const result: FormData[] = await getAnotaciones(claseId);
    if (isMounted()) setLoading(false);
    setAnotaciones(result);

    const estudiantes: EstudianteAsistencia[] = await getEstudiantesParaAsistencia(cursoId, claseId);
    setEstudiantes(estudiantes);

    setCategorias([
      'Aplicación',
      'Atención',
      'Conducta',
      'Orden',
    ]);

    limpiarFormulario();
  };
  const cargadoInicial = async () => {
    cargarAnotacionList();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, []);

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback]);

  const handleAnotarClick = async (formValues: FormData) => {
    const data: FormData = {
      id: formValues.id,
      categoria: formValues.categoria,
      descripcion: formValues.descripcion,
      estudianteId: estudiante?.estudiante.id || '',
      nombreEstudiante: estudiante?.estudiante.nombre || '',
    };
    const nuevaAnotacionList = [...anotaciones];
    nuevaAnotacionList.push(data);
    setAnotaciones(nuevaAnotacionList);
    limpiarFormulario();
    setAnotar(false);
  };

  const removerItem = (position: number) => {
    const nuevaAnotacionList = [...anotaciones.filter((item, index) => (index !== position))];
    setAnotaciones(nuevaAnotacionList);
  };

  const handleGuardarClick = async() => {
    await guardarAnotaciones(claseId, anotaciones);
    message.success(I18n.t('anotacionesGuardadasConExito'));
    onGuardarClick();
  }

  const textoTraducido: any = {
    Atención: I18n.t('categoriaAnotacion.atencion'),
    Conducta: I18n.t('categoriaAnotacion.conducta'),
    Orden: I18n.t('categoriaAnotacion.orden'),
    'Sin Tarea': I18n.t('categoriaAnotacion.sinTarea'),
    Aplicación: I18n.t('categoriaAnotacion.aplicacion'),
    Otro: I18n.t('categoriaAnotacion.otro'),
  }

  return (
    <>
      {loading && <Box position="absolute" left="0" right="0">
        <LinearProgress variant="indeterminate" color="secondary"/>
      </Box>}
      <Box className={classes.root}>
        {!anotar && <Box>
        <List dense className={classes.anotacionList}>
          {anotaciones.map((anotacion, index) => {
            return (
              <React.Fragment key={index}>
                <ListItem alignItems="center">
                <ListItemText
                  primary={<strong>{anotacion.nombreEstudiante}</strong>}
                  secondary={
                    <React.Fragment>
                      <Typography
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {textoTraducido[anotacion.categoria]}
                      </Typography><br />
                      <Typography
                        variant="caption"
                        className={classes.inline}
                      >
                        {anotacion.descripcion}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="comments" onClick={() => removerItem(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="fullWidth" component="li" />
              </React.Fragment>
            )
          })}
        </List>
        </Box>}

      <form onSubmit={handleSubmit(handleAnotarClick)}>
        <Box>
          {anotar && <Box>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                  <Translate value="form.seleccioneEstudiante" />
              </Typography>
            </Box>

            <Box mb={3}>
                <Autocomplete
                  options={estudiantes}
                  autoHighlight
                  getOptionSelected={(option, value) => value.estudiante.id === option.estudiante.id}
                  getOptionLabel={(option) => `${option.estudiante.nombre}`}
                  renderOption={(option) => (
                    <React.Fragment>
                        <Box>
                            <Typography variant="body2"><strong>{option.estudiante.nombre}</strong></Typography>
                        </Box>
                    </React.Fragment>
                  )}
                  value={estudiante}
                  onChange={(event: any, newValue: EstudianteAsistencia | null) => {
                    setEstudiante(newValue);
                    setValue("estudianteId", newValue?.estudiante?.id || '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={I18n.t('estudiante')}
                      variant="filled"
                      required
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                  <Translate value="form.seleccioneCategoria" />
              </Typography>
            </Box>

            <Box mb={3}>
                <Autocomplete
                  options={categorias}
                  autoHighlight
                  getOptionSelected={(option, value) => value === option}
                  getOptionLabel={(option) => `${textoTraducido[option]}`}
                  renderOption={(option) => (
                    <React.Fragment>
                        <Box>
                            <Typography variant="body2"><strong>{textoTraducido[option]}</strong></Typography>
                        </Box>
                    </React.Fragment>
                  )}
                  value={categoria}
                  onChange={(event: any, newValue: string | null) => {
                    setCategoria(newValue);
                    setValue("categoria", newValue || '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={I18n.t('categoria')}
                      variant="filled"
                      required
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
            </Box>
          </Grid>
          </Grid>

            <Box>
              <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                  <Translate value="form.descripcion" />
              </Typography>
            </Box>

            <Box mb={3}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="descripcion"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="textarea"
                      fullWidth
                      multiline
                      disabled={loading}
                      rows={4}
                      rowsMax={8}
                      variant="outlined"
                      required
                      hiddenLabel
                    />
                  )}
                />
              </Grid>
            </Box>
          </Box>}

          <Box display="flex" alignContent="center" justifyContent="center">
            {anotar && <Button type="submit" disabled={loading} className={classes.btnSaveForm} fullWidth>
              <Typography variant="body2" noWrap><Translate value="form.anotar" /></Typography>
            </Button>}
            {!anotar && <Button type="button" disabled={loading} onClick={() => setAnotar(true)} className={classes.btnCancel} fullWidth>
              <Typography variant="body2" noWrap><Translate value="form.adicionar" /></Typography>
            </Button>}
            {!anotar && <Button type="button" disabled={loading} onClick={handleGuardarClick} className={classes.btnSaveForm} fullWidth>
              <Typography variant="body2" noWrap><Translate value="form.guardar" /></Typography>
            </Button>}
          </Box>

        </Box>
      </form>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(3),
  },
  btnSaveForm: {
    margin: theme.spacing(4, 'auto'),
    maxWidth: '150px',
    maxHeight: '50px',
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  anotacionList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  btnCancel: {
    margin: theme.spacing(4, 'auto'),
    maxWidth: '150px',
    maxHeight: '50px',
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

export default StepAnotacion;
